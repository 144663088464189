import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/components/legal.scss';
import logoImage from '../logo.svg';

const TermsOfService: React.FC = () => {
  return (
    <div className="legal-wrapper">
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/">
            <img src={logoImage} alt="QuickCruit Logo" className="logo" />
          </Link>
        </div>
      </nav>
      <div className="legal-container">
        <h1>Terms of Service</h1>
        <p><em>Last Updated: 12/21/2024</em></p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          Welcome to QuickCruit Inc. (“<strong>QuickCruit</strong>,” “<strong>we</strong>,” “<strong>us</strong>,”
          or “<strong>our</strong>”). By accessing or using our website, platform, mobile applications, 
          browser extensions, or any related services (collectively, the “<strong>Services</strong>”), 
          you agree to be bound by these Terms of Service (“<strong>Terms</strong>”) and our 
          accompanying <a href="/privacy">Privacy Policy</a>. If you do not agree with these Terms, 
          please discontinue use of our Services immediately.
        </p>

        <h2>2. Company Information & Contact</h2>
        <p>
          <strong>Company Name:</strong> QuickCruit Inc.<br />
          <strong>Email:</strong> <a href="mailto:contact@quickcruit.ai">contact@quickcruit.ai</a><br />
          <strong>Phone:</strong> (505) 862-6771
        </p>
        <p>
          We may provide additional contact methods on our website. For support or inquiries, please 
          use the contact details above.
        </p>

        <h2>3. Eligibility</h2>
        <p>
          By using our Services, you represent and warrant that you are at least 18 years of age and 
          capable of entering into a legally binding agreement. If you do not meet this requirement, 
          you may not access or use the Services.
        </p>

        <h2>4. Description of Services</h2>
        <p>
          QuickCruit is an AI-driven career enablement platform designed to streamline and enrich 
          the job-seeking process. Our Services may include:
        </p>
        <ul>
          <li><strong>AI Résumé Generation:</strong> Automated, role-specific résumé creation optimized for ATS compatibility.</li>
          <li><strong>AI Cover Letter Generation:</strong> Personalized cover letters aligned with target roles and industries.</li>
          <li><strong>AI Mock Interviews:</strong> Interactive, AI-driven practice interviews with instant feedback and improvement suggestions.</li>
          <li><strong>QuickCruit Autofill Extension:</strong> A browser extension that simplifies job applications by autofilling forms accurately, even those with complex fields.</li>
          <li><strong>AI Career Coaching:</strong> Tailored career guidance and actionable insights designed to help users set goals and improve their professional profiles.</li>
          <li><strong>Application Tracking & Portfolio Tools:</strong> Utilities that help you monitor job applications and showcase your professional work.</li>
        </ul>
        <p>
          Some features may be offered exclusively on certain subscription plans. We continually refine 
          and update the Services to provide better functionality, accuracy, and user experience.
        </p>

        <h2>5. User Accounts & Security</h2>
        <p>
          To access certain features, you must create an account by providing your first name, last name, 
          email address, and a secure password. You agree to:
        </p>
        <ul>
          <li>Provide accurate, current, and complete account information.</li>
          <li>Maintain the security of your login credentials and promptly notify us of any unauthorized use.</li>
          <li>Accept responsibility for all activities that occur under your account.</li>
        </ul>
        <p>
          Excessive or improper use of the Services—such as repeated, automated requests designed 
          to overload our systems or exploit AI functionalities—may result in temporary or permanent 
          account suspension. We aim to maintain a stable, user-focused environment and appreciate 
          responsible, fair use.
        </p>

        <h2>6. Acceptable Use & Prohibited Conduct</h2>
        <p>
          By using the Services, you agree not to:
        </p>
        <ul>
          <li>Engage in illegal, fraudulent, or defamatory activities.</li>
          <li>Harass, threaten, or intimidate other users or QuickCruit personnel.</li>
          <li>Upload or transmit viruses, malware, or any harmful code intended to disrupt the Services.</li>
          <li>Reverse-engineer, disassemble, or otherwise attempt to derive the source code or underlying algorithms.</li>
          <li>Exploit AI features in a manner that detracts from normal use, including excessive automated requests or unnecessary repetitive queries.</li>
        </ul>
        <p>
          We reserve the right to investigate, suspend, or terminate accounts that violate these Terms 
          or harm the integrity of our Services.
        </p>

        <h2>7. User Content & Intellectual Property</h2>
        <p><strong>User Content:</strong> Any résumés, cover letters, interview responses, or other materials you generate 
          or upload through our Services remain your property. By using our Services, you grant QuickCruit a 
          non-exclusive, worldwide, royalty-free license to process, display, and host this content solely 
          for the purpose of providing the Services to you.</p>
        <p><strong>QuickCruit Intellectual Property:</strong> All intellectual property related to the Services, 
          including our software, AI models, trademarks, logos, and branding, remain the exclusive property 
          of QuickCruit and its licensors. You may not reproduce, distribute, or use any QuickCruit 
          intellectual property without prior written consent.</p>

        <h2>8. Subscription Plans & Payment</h2>
        <ul>
          <li><strong>Free Plan:</strong> Basic AI features (limited), application tracker, portfolio tool, 
              autofill extension, and AI résumé builder.</li>
          <li><strong>Coaching Plan ($9.99/month):</strong> Includes all Free features plus AI Career Coaching.</li>
          <li><strong>Pro Plan ($34.99/month, Student Rate: $29.99/month):</strong> Includes all Free features plus 
              customizable portfolios, unlimited AI résumé tailoring, unlimited AI cover letter generation, 
              unlimited QuickCruit AI usage, AI mock interviews, and AI Career Coaching (launching Feb). 
              Students may require third-party verification (e.g., via UniDays) to access discounted pricing.</li>
        </ul>
        <p>
          Payments are billed monthly unless otherwise stated. All transactions are processed securely 
          through our chosen payment providers. Refunds may be issued at our discretion and in accordance 
          with applicable laws.
        </p>

        <h2>9. Privacy & Data Use</h2>
        <p>
          We respect your privacy. Your use of our Services is governed by our 
          <a href="/privacy-policy">Privacy Policy</a>. We do not sell personal data to third parties. Any data 
          collected may be used to improve our Services. Training and algorithmic improvements are performed 
          on anonymized, aggregated data, ensuring no personally identifiable information is disclosed.
        </p>

        <h2>10. Security</h2>
        <p>
          We employ robust security measures, including JWT-based authentication, bcrypt hashing for passwords, 
          rate limiting, input validation, and more, to protect user data. While we continually enhance these measures, 
          no system is entirely immune to breach. You acknowledge and agree that QuickCruit will not be liable for 
          unauthorized access or malicious activities by third parties.
        </p>

        <h2>11. Modifications to Terms & Services</h2>
        <p>
          We may update these Terms or modify our Services at any time. We will notify users of significant changes 
          via email or in-app notifications. Continued use after changes become effective constitutes your acceptance 
          of the updated Terms.
        </p>

        <h2>12. Disclaimer of Warranties</h2>
        <p>
          The Services are provided on an “as is” and “as available” basis without warranties of any kind, express 
          or implied. We do not guarantee that the Services will meet your requirements, achieve intended results, 
          or be uninterrupted or error-free.
        </p>

        <h2>13. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, QuickCruit shall not be liable for indirect, consequential, 
          incidental, special, or punitive damages, or any loss of profits or revenues. In no event will 
          QuickCruit’s total liability exceed the amounts paid by you to QuickCruit in the twelve (12) months 
          preceding the claim.
        </p>

        <h2>14. Governing Law & Dispute Resolution</h2>
        <p>
          These Terms and your use of the Services are governed by the laws of the United States. Any dispute, claim, 
          or controversy arising out of or relating to these Terms or the Services will be resolved through binding 
          arbitration, unless otherwise required by law.
        </p>

        <h2>15. Contact & Notices</h2>
        <p>
          For questions, comments, or concerns about these Terms, please contact us at 
          <a href="mailto:contact@quickcruit.ai"> contact@quickcruit.ai</a>.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
