export const addUtmParameters = (url: string) => {
  try {
    const urlObj = new URL(url);
    
    // Create new URL and preserve all existing query parameters
    const newUrl = new URL(url);
    
    // Add our UTM parameter
    newUrl.searchParams.append('utm_source', 'quickcruit.ai');
    
    return newUrl.toString();
  } catch (error) {
    console.error('Invalid URL:', error);
    return url;
  }
}; 