import config from './config';

// Aws url
export const mainUrl = "https://mh56kpdmum.us-west-2.awsapprunner.com/v1/"


// Comment out other URLs for reference
// export const mainUrl = "http://localhost:3000/v1/"
// export const mainUrl = "https://quickcruit-backend.onrender.com/v1/"

export const googleClientId = "980669967404-b70f46r87di1u1o4us4oqb7ksq4aubm7.apps.googleusercontent.com"

export const clientSecret = "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"

export const stripPublishingKey = "pk_test_51QasCGJpfY3E2gpyD0DuNSlamZ5rYnxWke8TfcVUAxRLtybMfV5xWODxQCeMMQbHDla4PMarqHBUTkbvLfwUEzS600uMHv0pdT"

export const stripeApiKey ="sk_test_51QasCGJpfY3E2gpyP2rlNGG1gJy7A6srrszXwDcO8eCFt6GT0YuSS3XisDTdQb4nyl1fpp1VVIVo13O0p3IcMUTq00evpbS8CN"

export const locationOptions = [
      { value: 'san_francisco_bay_area', label: '🌉 San Francisco Bay Area' },
      { value: 'greater_seattle_area', label: '🌲 Greater Seattle Area' },
      { value: 'new_york_metropolitan_area', label: '🗽 New York Metropolitan Area' },
      { value: 'greater_los_angeles_area', label: '🌴 Greater Los Angeles Area' },
      { value: 'greater_salt_lake_city_area', label: '⛷️ Salt Lake City Metropolitan Area' },
      { value: 'boston_metropolitan_area', label: '🏙️ Boston Metropolitan Area' },
      { value: 'chicago_metropolitan_area', label: '🌬️ Chicago Metropolitan Area' },
      { value: 'washington_dc_metro_area', label: '🏛️ Washington D.C. Metro Area' },
      { value: 'austin_metropolitan_area', label: '🎸 Austin Metropolitan Area' },
      { value: 'dallas_fort_worth_metroplex', label: '🤠 Dallas-Fort Worth Metroplex' },
      { value: 'atlanta_metropolitan_area', label: '🍑 Atlanta Metropolitan Area' },
      { value: 'denver_metropolitan_area', label: '🏔️ Denver Metropolitan Area' },
      { value: 'phoenix_metropolitan_area', label: '🌵 Phoenix Metropolitan Area' },
      { value: 'toronto_metropolitan_area', label: '🍁 Toronto Metropolitan Area' },
      { value: 'open_to_all', label: '🌍 Open to All Locations' }
];

export const industryOptions = [
      { value: 'Software Engineering', label: '💻 Software Engineering' },
      { value: 'Data Science', label: '📊 Data Science' },
      { value: 'Cybersecurity & IT', label: '🔒 Cybersecurity & IT' },
      { value: 'Product', label: '🛠️ Product' },
      { value: 'Marketing', label: '📣 Marketing' },
      { value: 'Sales', label: '💼 Sales' },
      { value: 'Finance', label: '💰 Finance' },  
      { value: 'Consulting', label: '🧑‍💼 Consulting' },
      { value: 'Logistics & Operations', label: '📦 Logistics & Operations' },
      { value: 'UI/UX & Design', label: '🎨 UI/UX & Design' },
      { value: 'Mechanical Engineering', label: '⚙️ Mechanical Engineering' },
      { value: 'Electrical Engineering', label: '🔌 Electrical Engineering' },
      { value: 'Civil Engineering', label: '🏗️ Civil Engineering' },
      { value: 'Chemical Engineering', label: '⚗️ Chemical Engineering' },
      { value: 'Aerospace Engineering', label: '✈️ Aerospace Engineering' },
      { value: 'Robotics & Automation', label: '🤖 Robotics & Automation' },
      { value: 'Biomedical Engineering', label: '🧬 Biomedical Engineering' },
      { value: 'Accounting', label: '📈 Accounting' }
];

export enum JobButtonContext {
  APPLICATION_TRACKER = 'APPLICATION_TRACKER',
  MAIN = 'MAIN',
  BRING_YOUR_JOB = 'BRING_YOUR_JOB'
}

export const JOB_BUTTON_TEXT = {
  [JobButtonContext.APPLICATION_TRACKER]: 'View Details',
  [JobButtonContext.MAIN]: 'Apply',
  [JobButtonContext.BRING_YOUR_JOB]: 'Apply'
};

export const getJobButtonText = (context: JobButtonContext): string => {
  return JOB_BUTTON_TEXT[context];
};

export const experienceLevelOptions = [
  { value: 'ENTRY_LEVEL', label: 'Entry Level' },
  { value: 'MID_CAREER', label: 'Mid Career' },
  { value: 'SENIOR_LEVEL', label: 'Senior Level' },
  { value: 'EXPERT_LEVEL', label: 'Expert Level' }
];

export const EXPERIENCE_LEVELS = [
  'ENTRY_LEVEL',
  'MID_CAREER',
  'SENIOR_LEVEL',
  'EXPERT_LEVEL'
] as const;

export type ExperienceLevel = typeof EXPERIENCE_LEVELS[number];

export const normalizeExperienceLevel = (level: string): ExperienceLevel => {
  if (!level) return 'ENTRY_LEVEL';
  
  // Remove any prefix if it exists and trim
  let normalized = level.replace(/^Experience Level:\s*/i, '').trim();
  
  // If there's a split level (e.g., "Mid Career / Senior Level"), take the first one
  if (normalized.includes('/')) {
    normalized = normalized.split('/')[0].trim();
  }
  
  // Simple text-based matching
  if (normalized.toLowerCase().includes('entry') || 
      normalized.toLowerCase().includes('early') || 
      normalized.toLowerCase().includes('internship')) return 'ENTRY_LEVEL';
  if (normalized.toLowerCase().includes('mid')) return 'MID_CAREER';
  if (normalized.toLowerCase().includes('senior')) return 'SENIOR_LEVEL';
  if (normalized.toLowerCase().includes('expert')) return 'EXPERT_LEVEL';
  
  return 'ENTRY_LEVEL'; // Default to entry level if no match
};

// For backward compatibility with components still using job types
export const STANDARD_JOB_TYPES = EXPERIENCE_LEVELS;
export const normalizeJobTypeForFilter = normalizeExperienceLevel;
