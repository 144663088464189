import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FaAngleDown, FaAngleUp, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import jobTitles from '../jobs_titles.json';
import Sidebar from './Sidebar';
import '../styles/components/jobSearch.scss';
import { fetchJobs } from '../jobService';
import ReactPaginate from 'react-paginate';
import JobTile from './JobTile';
import JobDetailsModal, { JobData } from './JobDetailsModal';
import { JobButtonContext, normalizeJobTypeForFilter, STANDARD_JOB_TYPES } from '../constant';

// Define types for better type safety
interface JobHub {
  value: string;
  label: string;
}

interface JobType {
  value: string;
  label: string;
}

interface Job {
  _id: string;
  title: string;
  company: string;
  location: string;
  jobType: string;
  description: string;
  requirements: string[];
  skills: string[];
  industry?: string;
  [key: string]: any;
}

const DevTestJobSearch: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedJobTypes, setSelectedJobTypes] = useState<string[]>([]);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [jobResults, setJobResults] = useState<Job[]>([]);
  const [originalMatchedJobs, setOriginalMatchedJobs] = useState<Job[]>([]);
  const [error, setError] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState<{ [key: string]: boolean }>({});
  const [scrolled, setScrolled] = useState(false);
  const dropdownRefs = useRef<{ [key: string]: any }>({});
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();
  const [selection, setSelection] = useState<JobData | null>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [oneJobPerCompany, setOneJobPerCompany] = useState(true);
  const itemsPerPage = 54;
  const [availableJobHubs] = useState<JobHub[]>([
    { value: 'remote', label: 'Remote' },
    { value: 'hybrid', label: 'Hybrid' },
    { value: 'onsite', label: 'On-site' }
  ]);
  const [availableJobTypes] = useState<JobType[]>(
    STANDARD_JOB_TYPES.map(type => ({
      value: type.toLowerCase().replace('-', '_'),
      label: type
    }))
  );
  const [availableCategories] = useState<JobType[]>(
    Object.keys(jobTitles).map(key => ({ value: key, label: key }))
  );
  const [availableCompanies, setAvailableCompanies] = useState<JobType[]>([]);
  const [loading, setLoading] = useState(false);

  // Get current page from URL
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = localStorage.getItem('user');
        if (userData) {
          const user = JSON.parse(userData);
          if (user._id) {
            setUser(user);
          } else {
            navigate('/auth/login');
          }
        } else {
          navigate('/auth/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to fetch user data. Please check your permissions.');
      }
    };

    fetchUserData();
  }, [navigate]);

  const loadJobs = async () => {
    try {
      setLoading(true);
      const response = await fetchJobs({
        page: currentPage,
        locations: selectedLocations,
        jobTypes: selectedJobTypes,
        industries: selectedIndustries,
        companies: selectedCompanies
      });

      // Jobs are already sorted by company name from the backend
      const jobs = Array.isArray(response) ? response : response.jobs || [];
      
      // Get unique companies from all jobs for the dropdown
      const uniqueCompanies = Array.from(new Set(jobs.map((job: Job) => job.company))) as string[];
      
      // Create company options for the dropdown
      const companyOptions = uniqueCompanies.map(company => ({
        value: company,
        label: company
      }));

      setJobResults(jobs);
      setAvailableCompanies(companyOptions);
      setTotalResults(response.totalJobs || jobs.length);
      setTotalPages(response.totalPages || Math.ceil(jobs.length / 54));
      
    } catch (error) {
      console.error('Error loading jobs:', error);
      setJobResults([]);
      setTotalResults(0);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadJobs();
  }, [currentPage, selectedLocations, selectedJobTypes, selectedIndustries, selectedCompanies]); // Reload when filters change

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRefs.current) return;
      
      const clickedOutside = Object.values(dropdownRefs.current).every(
        (ref) => !ref?.contains(event.target as Node)
      );
      
      if (clickedOutside) {
        setDropdownOpen({});
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string[]>>,
    key: string
  ) => {
    const { value, checked } = event.target;
    setter((prev) => {
      const updated = checked ? [...prev, value] : prev.filter((option) => option !== value);
      // Reset to page 1 when filters change
      searchParams.set('page', '1');
      setSearchParams(searchParams);
      return updated;
    });
  };

  const clearFilters = () => {
    setSelectedLocations([]);
    setSelectedJobTypes([]);
    setSelectedIndustries([]);
    setSelectedCompanies([]);
    setSearchParams({ page: '1' });
  };

  const toggleDropdown = (key: string) => {
    setDropdownOpen((prev) => ({
      ...Object.keys(prev).reduce((acc, k) => ({ ...acc, [k]: false }), {}),
      [key]: !prev[key],
    }));
  };

  const renderCheckboxList = (
    options: { value: string; label: string }[],
    selected: string[],
    setter: React.Dispatch<React.SetStateAction<string[]>>,
    placeholder: string,
    key: string,
    jobs: Job[]
  ) => {
    // Calculate counts for each option
    const optionCounts = options.reduce((acc, option) => {
      const count = jobs.filter(job => {
        switch (key) {
          case 'locations':
            const normalizedJobLocation = job.location?.toLowerCase().trim();
            const normalizedFilter = option.value.toLowerCase().replace(/_/g, ' ').trim();
            return normalizedJobLocation?.includes(normalizedFilter);
          case 'jobTypes':
            const normalizedJobType = normalizeJobTypeForFilter(job.jobType);
            const normalizedFilter2 = option.value.toLowerCase().replace(/_/g, ' ').trim();
            return normalizedJobType.toLowerCase() === normalizedFilter2;
          case 'industries':
            const normalizedJobTitle = job.title?.toLowerCase().trim();
            const normalizedFilter3 = option.value.toLowerCase().trim();
            return normalizedJobTitle?.includes(normalizedFilter3);
          case 'companies':
            return job.company === option.value;
          default:
            return false;
        }
      }).length;
      return { ...acc, [option.value]: count };
    }, {} as { [key: string]: number });

    return (
      <div 
        className={`dropdown ${selected.length > 0 ? 'active' : ''}`}
        ref={(el) => (dropdownRefs.current[key] = el)}
      >
        <button 
          type="button" 
          className="dropdown-button" 
          onClick={() => toggleDropdown(key)}
        >
          <span className="dropdown-label">
            {placeholder}
            {selected.length > 0 && (
              <span className="selected-count">
                ({selected.length})
              </span>
            )}
          </span>
          {dropdownOpen[key] ? <FaAngleUp /> : <FaAngleDown />}
        </button>
        
        {dropdownOpen[key] && (
          <div className="dropdown-content open">
            {options
              .filter(option => optionCounts[option.value] > 0 || selected.includes(option.value))
              .map((option) => (
                <div
                  key={option.value}
                  className="checkbox-item"
                  onClick={() => {
                    const newChecked = !selected.includes(option.value);
                    handleCheckboxChange(
                      {
                        target: { 
                          value: option.value, 
                          checked: newChecked 
                        }
                      } as React.ChangeEvent<HTMLInputElement>,
                      setter,
                      key
                    );
                  }}
                >
                  <div className="checkbox-label">
                    <input
                      type="checkbox"
                      value={option.value}
                      checked={selected.includes(option.value)}
                      onChange={(e) => handleCheckboxChange(e, setter, key)}
                    />
                    <label>{option.label}</label>
                  </div>
                  <span className="option-count">{optionCounts[option.value]}</span>
                </div>
              ))}
          </div>
        )}
      </div>
    );
  };

  const handlePageClick = (data: { selected: number }) => {
    const newPage = data.selected + 1;
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  return (
    <div className="job-search-container">
      <Sidebar />
      <div className="job-search">
        <div className={`filter-container ${scrolled ? 'scrolled' : ''}`}>
          <div className="filter-content">
            {loading ? (
              <>
                <div className="filter-skeleton">
                  <div className="filter-button-skeleton skeleton"></div>
                  <div className="filter-button-skeleton skeleton"></div>
                  <div className="clear-filters-skeleton skeleton"></div>
                </div>
              </>
            ) : (
              <>
                <span className="total-count">
                  {totalResults} Companies Found
                </span>
                {renderCheckboxList(
                  availableJobHubs,
                  selectedLocations,
                  setSelectedLocations,
                  'Location',
                  'locations',
                  jobResults
                )}
                {renderCheckboxList(
                  availableCompanies,
                  selectedCompanies,
                  setSelectedCompanies,
                  'Company',
                  'companies',
                  jobResults
                )}
                {(selectedLocations.length > 0 || selectedCompanies.length > 0) && (
                  <button 
                    onClick={clearFilters} 
                    className="clear-filters"
                  >
                    Clear Filters
                  </button>
                )}
              </>
            )}
          </div>
        </div>

        <div className="job-results job-tiles-container">
          {loading ? (
            <div className="skeleton-grid">
              {[...Array(itemsPerPage)].map((_, index) => (
                <div key={index} className="job-tile skeleton">
                  <div className="job-header-skeleton">
                    <div className="company-logo-skeleton skeleton"></div>
                    <div className="job-info-skeleton">
                      <div className="job-title-skeleton skeleton"></div>
                      <div className="company-name-skeleton skeleton"></div>
                    </div>
                  </div>
                  <div className="job-details-skeleton">
                    <div className="detail-item-skeleton skeleton"></div>
                    <div className="detail-item-skeleton skeleton"></div>
                  </div>
                  <div className="job-description-skeleton">
                    <div className="description-line-skeleton skeleton"></div>
                    <div className="description-line-skeleton skeleton"></div>
                    <div className="description-line-skeleton skeleton" style={{ width: '75%' }}></div>
                  </div>
                  <div className="job-footer-skeleton">
                    <div className="tag-skeleton skeleton"></div>
                    <div className="tag-skeleton skeleton"></div>
                  </div>
                </div>
              ))}
            </div>
          ) : jobResults.length > 0 ? (
            jobResults.map((job, index) => (
              <JobTile
                key={`${job._id}-${index}`}
                job={job}
                userId={user?._id}
                handleSelection={setSelection}
                context={JobButtonContext.MAIN}
              />
            ))
          ) : (
            <p>No jobs found matching the selected filters.</p>
          )}
        </div>

        {totalResults > itemsPerPage && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={<FaAngleLeft />}
              nextLabel={<FaAngleRight />}
              pageCount={totalPages}
              onPageChange={handlePageClick}
              forcePage={currentPage - 1}
              containerClassName="pagination"
              activeClassName="active"
              previousClassName="prev"
              nextClassName="next"
              disabledClassName="disabled"
              pageRangeDisplayed={5}
              marginPagesDisplayed={1}
              pageLinkClassName="page-link"
            />
          </div>
        )}
      </div>

      {selection && (
        <JobDetailsModal
          job={selection}
          isOpen={Boolean(selection._id)}
          onClose={() => setSelection(null)}
        />
      )}
    </div>
  );
};

export default DevTestJobSearch;