/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../styles/components/jobDetailsModal.scss";
import aiIcon from "../../assets/ai-blue.svg";
import JobDescriptionIcon from "../../assets/job-description.svg";
import { useApplicationModal } from '../../context/ApplicationModalContext';
import axiosClient from '../../axios-client';
import { blobStorageService } from '../../services/blobStorage.service';

export interface JobData {
  _id: string;
  title: string;
  original_title?: string;
  company: string;
  location: string;
  location_hub?: string;
  summary?: string;
  qualifications?: string[];
  preferredQualifications?: string[];
  responsibilities?: string[];
  environment?: string;
  skills?: string | string[];
  salary?: string;
  experience_level?: string;
  jobType?: string;
  tailored_flag?: string;
  days_to_live?: any;
  job_url?: string;
  status?: 'applied' | 'interviewing' | 'offer';
  appliedDate?: string;
  lastStatusUpdate?: string;
}

interface JobDetailsModalProps {
  job: JobData;
  isOpen: boolean;
  onClose: () => void;
  onStatusChange?: (newStatus: 'applied' | 'interviewing' | 'offer') => void;
}

const JobDetailsModal: React.FC<JobDetailsModalProps> = ({
  job: initialJob,
  isOpen,
  onClose,
  onStatusChange,
}) => {
  const [selectedSection, setSelectedSection] = useState("job-details");
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0);
  const { setModalData } = useApplicationModal();
  const [currentStatus, setCurrentStatus] = useState<'applied' | 'interviewing' | 'offer'>(initialJob.status || 'applied');
  const [hasAnimated, setHasAnimated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [job, setJob] = useState<JobData>(initialJob);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [logoVariant, setLogoVariant] = useState<'horizontal' | 'square' | 'vertical' | null>(null);

  useEffect(() => {
    if (isOpen && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [isOpen]);

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [isOpen]);

  // Add effect to fetch full job details
  useEffect(() => {
    const fetchJobDetails = async () => {
      if (isOpen && initialJob._id) {
        try {
          setIsLoading(true);
          const response = await axiosClient.get(`/jobs/${initialJob._id}`);
          setJob(response.data);
        } catch (err) {
          console.error('Error fetching job details:', err);
          setError('Failed to load job details');
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchJobDetails();
  }, [isOpen, initialJob._id]);

  // Add cleanup function
  const cleanupJobData = () => {
    setHasAnimated(false);
    setError(null);
  };

  // Update the close handler
  const handleClose = () => {
    cleanupJobData();
    onClose();
  };

  useEffect(() => {
    const loadLogo = async () => {
      if (job.company) {
        const logo = await blobStorageService.getBestLogoVariant(job.company);
        if (logo) {
          setLogoUrl(logo.url);
          setLogoVariant(logo.variant);
        }
      }
    };
    loadLogo();
  }, [job.company]);

  const getJobTypeClass = (jobType: string) => {
    switch (jobType.toLowerCase()) {
      case "full time":
        return "full-time";
      case "part time":
        return "part-time";
      case "internship":
        return "internship";
      case "contract":
        return "contract";
      default:
        return "";
    }
  };

  const formatJobType = (jobType: string) => {
    switch (jobType.toLowerCase()) {
      case "full time":
        return "FULL-TIME";
      case "part time":
        return "PART-TIME";
      default:
        return jobType.toUpperCase();
    }
  };

  const getPostedDate = (daysToLive: number | undefined) => {
    console.log("days_to_live value:", daysToLive);
    if (daysToLive === undefined || daysToLive === null) {
      return "Recently Posted";
    }

    const totalDays = 15;
    const daysAgo = totalDays - daysToLive;

    switch (daysAgo) {
      case 0:
        return "Today";
      case 1:
        return "Yesterday";
      default:
        return `${daysAgo} days ago`;
    }
  };

  if (!isOpen) return null;

  const tabsConfig = [
    {
      id: "job-details",
      label: "Job Details",
      icon: (
        <img
          src={JobDescriptionIcon}
          alt="Job Description Icon"
          width="16"
          height="16"
          className="tab-icon"
        />
      ),
    }
  ];

  const renderContent = () => {
    const jobToDisplay = job;

    return (
      <div className="content-container">
        <div className="left-column">
          <h3>About the Job:</h3>
          <p>{jobToDisplay.summary || "No description provided."}</p>
          <h3>Requirements:</h3>
          {jobToDisplay.qualifications && jobToDisplay.qualifications.length > 0 ? (
            <ul>
              {jobToDisplay.qualifications.map((req, index) => (
                <li key={index}>{req}</li>
              ))}
            </ul>
          ) : (
            <p>No requirements specified.</p>
          )}
          <h3>Responsibilities:</h3>
          {jobToDisplay.responsibilities && jobToDisplay.responsibilities.length > 0 ? (
            <ul>
              {jobToDisplay.responsibilities.map((resp, index) => (
                <li key={index}>{resp}</li>
              ))}
            </ul>
          ) : (
            <p>No responsibilities specified.</p>
          )}
          <h3>Skills:</h3>
          <div className="skills">
            {jobToDisplay.skills ? (
              Array.isArray(jobToDisplay.skills) ? (
                jobToDisplay.skills.map((skill, index) => (
                  <span key={index} className="skill-badge">
                    {skill.replace(/^•\s*/, "").trim()}
                  </span>
                ))
              ) : (
                jobToDisplay.skills.split(",").map((skill, index) => (
                  <span key={index} className="skill-badge">
                    {skill.trim()}
                  </span>
                ))
              )
            ) : (
              <p>No skills specified.</p>
            )}
          </div>
        </div>
        <div className="right-column">
          <div className="job-info-box">
            <h4>Posted</h4>
            <p>{getPostedDate(jobToDisplay.days_to_live?.$numberInt)}</p>
            <h4>Salary Range</h4>
            <p>{jobToDisplay.salary || "Not available"}</p>
            <h4>Experience Level</h4>
            <p>{jobToDisplay.experience_level || "Not specified"}</p>
            <h4>Job Environment</h4>
            <p>{jobToDisplay.environment || "Not specified"}</p>
            <h4 className="about-company">About the Company</h4>
            <p className="about-company-text">
              {jobToDisplay.company} is a leader in its industry providing
              innovative solutions and services.
            </p>
          </div>
        </div>
      </div>
    );
  };

  const handleTabChange = (tabId: string) => {
    setSelectedSection(tabId);
  };

  return (
    <div className={`modal-backdrop ${isOpen ? "show" : ""}`}>
      <div className={`modal-content ${isOpen ? "show" : ""}`}>
        <div className="sticky-container">
          <div className="modal-header">
            <div className="tabs">
              {tabsConfig.map((tab) => (
                <div
                  key={tab.id}
                  className={`tab ${selectedSection === tab.id ? "selected" : ""}`}
                  onClick={() => handleTabChange(tab.id)}
                >
                  <span className="tab-icon">{tab.icon}</span>
                  <span>{tab.label}</span>
                </div>
              ))}
            </div>
            <button className="close-button" onClick={handleClose}>
              ×
            </button>
          </div>
          {selectedSection === "job-details" && (
            <>
              <div className={`header-section ${hasAnimated ? 'animate' : ''} ${selectedSection === "job-details" ? 'visible' : ''}`}>
                <div className="job-title-section">
                  <div className="top-row">
                    <div className={`company-logo-wrapper ${logoVariant === 'horizontal' ? 'horizontal-logo-container' : ''}`}>
                      {logoUrl ? (
                        <img
                          src={logoUrl}
                          alt={`${job.company} logo`}
                          className={`company-logo ${logoVariant === 'horizontal' ? 'horizontal-logo' : ''}`}
                        />
                      ) : (
                        <div className="logo-placeholder" />
                      )}
                    </div>
                    {job.jobType && (
                      <span
                        className={`job-type-tag ${getJobTypeClass(job.jobType)}`}
                      >
                        {formatJobType(job.jobType)}
                      </span>
                    )}
                  </div>
                  <div className="bottom-row">
                    <div className="job-title">
                      <h2>{job.title}</h2>
                      <p className="company-location">
                        {job.company} • {job.location}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="button-group">
                  <button
                    className="tailor-button"
                  >
                    Mock Interview
                  </button>
                </div>
              </div>
              <hr className="separator-line" />
            </>
          )}
        </div>
        <div className="modal-body">
          {isLoading ? (
            <div className="loading-overlay">
              <div className="loading-spinner"></div>
              <p>Loading job details...</p>
            </div>
          ) : error ? (
            <div className="error-message">
              <p>{error}</p>
              <button onClick={() => setError(null)}>Dismiss</button>
            </div>
          ) : (
            renderContent()
          )}
        </div>
      </div>
    </div>
  );
};

export default JobDetailsModal; 