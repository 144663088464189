import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import '../../styles/components/landing-pages/notFound.scss';

const NotFound = () => {
  return (
    <>
      <Navbar />
      <div className="not-found-container">
        <div className="not-found-content">
          <h1>404</h1>
          <h2>Page Not Found</h2>
          <p>The page you're looking for doesn't exist or has been moved.</p>
          <div className="actions">
            <Link to="/" className="primary-button">
              Return Home
            </Link>
            <Link to="/jobs" className="secondary-button">
              Browse Jobs
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound; 