import { useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const html = document.documentElement;
    const body = document.body;

    // Store original scroll behavior
    const originalHtmlScroll = html.style.scrollBehavior;
    const originalBodyScroll = body.style.scrollBehavior;

    // Force immediate scroll position
    html.style.scrollBehavior = 'auto';
    body.style.scrollBehavior = 'auto';
    
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
    
    // Reset scroll behavior
    html.style.scrollBehavior = originalHtmlScroll;
    body.style.scrollBehavior = originalBodyScroll;

    return () => {
      // Cleanup function to reset scroll behavior on unmount
      html.style.scrollBehavior = originalHtmlScroll;
      body.style.scrollBehavior = originalBodyScroll;
    };
  }, [pathname]);

  return null;
};

export default ScrollToTop;