import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { auth, db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import JobTile from "./JobTile";
import {
  FaAngleDown,
  FaAngleUp,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import jobTitles from "../jobs_titles.json";
import Sidebar from "./Sidebar";
import "../styles/components/jobSearch.scss";
import { fetchJobs, fetchMatchedJobs } from "../jobService";
import ReactPaginate from "react-paginate";
import { User } from "firebase/auth";
import JobDetailsModal, { JobData } from "./JobDetailsModal";
import {
  JobButtonContext,
  getJobButtonText,
  normalizeExperienceLevel,
  experienceLevelOptions,
} from "../constant";
import { AiOutlineClose, AiOutlineFilter } from "react-icons/ai";
import { getApplications } from '../services/applicationService';

// Define types for better type safety
interface JobHub {
  value: string;
  label: string;
}

interface ExperienceLevel {
  value: string;
  label: string;
}

interface Job {
  _id: string;
  title: string;
  company: string;
  location: string;
  experience_level: string;
  description: string;
  requirements: string[];
  skills: string[];
  matchScore?: number;
  industry?: string;
  jobType?: string;
  [key: string]: any;
}

interface PaginatedJobResponse {
  results: Job[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
}

const defaultPreferences = {
  locations: ["open_to_all"],
  experienceLevel: ["ENTRY_LEVEL"],
  industries: Object.keys(jobTitles).map((key) => ({ value: key, label: key })),
};

const FilterDropdown: React.FC<{
  options: any[];
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
  title: string;
  type: string;
  loading: boolean;
}> = ({ options, selectedValues, setSelectedValues, title, type, loading }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    if (loading) return;
    setIsOpen(!isOpen);
  };

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  return (
    <div 
      className={`dropdown ${isOpen ? 'active' : ''}`} 
      onClick={(e) => e.stopPropagation()}
    >
      <button
        className="dropdown-button"
        onClick={handleToggle}
        disabled={loading}
        style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
      >
        {title}
        {selectedValues.length > 0 && (
          <span className="selected-count">{selectedValues.length}</span>
        )}
        {isOpen ? <FaAngleUp /> : <FaAngleDown />}
      </button>
      {!loading && isOpen && (
        <div className={`dropdown-content ${isOpen ? 'open' : ''}`}>
          {options.map((option) => (
            <div key={option.value} className="checkbox-item">
              <div className="checkbox-label">
                <input
                  type="checkbox"
                  checked={selectedValues.includes(option.value)}
                  onChange={() => {
                    const newValues = selectedValues.includes(option.value)
                      ? selectedValues.filter((v) => v !== option.value)
                      : [...selectedValues, option.value];
                    setSelectedValues(newValues);
                  }}
                  id={`${type}-${option.value}`}
                />
                <label htmlFor={`${type}-${option.value}`}>{option.label}</label>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const JobSearch: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedExperienceLevels, setSelectedExperienceLevels] = useState<string[]>([]);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [jobResults, setJobResults] = useState<Job[]>([]);
  const [error, setError] = useState("");
  const [preferencesSaved, setPreferencesSaved] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [scrolled, setScrolled] = useState(false);
  const dropdownRefs = useRef<{ [key: string]: any }>({});
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();
  const [selection, setSelection] = useState<JobData | null>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const itemsPerPage = 54; // Display 54 items per page
  const fetchLimit = 10000; // Fetch up to 10000 jobs at once
  const [availableJobHubs, setAvailableJobHubs] = useState<JobHub[]>([]);
  const [availableExperienceLevels, setAvailableExperienceLevels] = useState<ExperienceLevel[]>(experienceLevelOptions);
  const [availableCategories, setAvailableCategories] = useState<JobHub[]>([]);
  const [loading, setLoading] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isModalClosing, setIsModalClosing] = useState(false);
  const [tempSelectedLocations, setTempSelectedLocations] = useState<string[]>([]);
  const [tempSelectedExperienceLevels, setTempSelectedExperienceLevels] = useState<string[]>([]);
  const [tempSelectedIndustries, setTempSelectedIndustries] = useState<string[]>([]);
  const [originalMatchedJobs, setOriginalMatchedJobs] = useState<Job[]>([]);
  const [appliedJobs, setAppliedJobs] = useState<Set<string>>(new Set());

  // Get current page and filters from URL
  const currentPage = parseInt(searchParams.get("page") || "1", 10);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = localStorage.getItem("user");
        if (userData) {
          const user = JSON.parse(userData);
          if (user._id) {
            setUser(user);
          } else {
            navigate("/auth/login");
          }
        } else {
          navigate("/auth/login");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to fetch user data. Please check your permissions.");
      }
    };

    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    const loadJobs = async () => {
      setLoading(true);
      try {
        const filters = {
          page: 1, // Always fetch first page
          limit: fetchLimit, // Fetch all jobs at once
        };

        // Try to get matched jobs first
        const result = await fetchMatchedJobs(filters);

        if (!result || !result.jobs || result.jobs.length === 0) {
          const allJobsResponse = await fetchJobs(filters);
          setOriginalMatchedJobs(allJobsResponse.jobs as Job[]);
          setTotalResults(allJobsResponse.totalJobs);
          setTotalPages(Math.ceil(allJobsResponse.totalJobs / itemsPerPage));
          
          // Paginate the jobs for display
          const startIndex = (currentPage - 1) * itemsPerPage;
          const endIndex = startIndex + itemsPerPage;
          setJobResults(allJobsResponse.jobs.slice(startIndex, endIndex) as Job[]);
          
          // Set available filters from all jobs
          const uniqueLocations = Array.from(
            new Set(allJobsResponse.jobs.map((job: any) => job.location))
          )
            .filter(Boolean)
            .map((location: any) => ({
              value: location.toLowerCase().replace(/\s+/g, "_"),
              label: location,
            }));
          setAvailableJobHubs([
            ...uniqueLocations,
            { value: "open_to_all", label: "Open to All Locations" },
          ]);

          const uniqueCategory = Array.from(
            new Set(allJobsResponse.jobs.map((job: any) => job.title))
          )
            .filter(Boolean)
            .map((type: any) => ({
              value: type.toLowerCase(),
              label: type,
            }));
          setAvailableCategories(uniqueCategory);
        } else {
          setOriginalMatchedJobs(result.jobs as Job[]);
          setTotalResults(result.totalJobs);
          setTotalPages(Math.ceil(result.totalJobs / itemsPerPage));
          
          // Paginate the jobs for display
          const startIndex = (currentPage - 1) * itemsPerPage;
          const endIndex = startIndex + itemsPerPage;
          setJobResults(result.jobs.slice(startIndex, endIndex) as Job[]);
          
          // Set available filters from all jobs
          const uniqueLocations = Array.from(
            new Set(result.jobs.map((job: any) => job.location))
          )
            .filter(Boolean)
            .map((location: any) => ({
              value: location.toLowerCase().replace(/\s+/g, "_"),
              label: location,
            }));
          setAvailableJobHubs([
            ...uniqueLocations,
            { value: "open_to_all", label: "Open to All Locations" },
          ]);

          const uniqueCategory = Array.from(
            new Set(result.jobs.map((job: any) => job.title))
          )
            .filter(Boolean)
            .map((type: any) => ({
              value: type.toLowerCase(),
              label: type,
            }));
          setAvailableCategories(uniqueCategory);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error loading jobs:", error);
        setError("Failed to load jobs. Please try again later.");
        setLoading(false);
      }
    };

    loadJobs();
  }, []); // Remove currentPage from dependencies since we'll handle pagination locally

  // Update displayed jobs when page changes
  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setJobResults(originalMatchedJobs.slice(startIndex, endIndex));
  }, [currentPage, originalMatchedJobs]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRefs.current) return;

      const clickedOutside = Object.values(dropdownRefs.current).every(
        (ref) => !ref?.contains(event.target as Node)
      );

      if (clickedOutside) {
        setDropdownOpen({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string[]>>,
    key: string
  ) => {
    const { value, checked } = event.target;
    setter((prev) => {
      const updated = checked
        ? [...prev, value]
        : prev.filter((option) => option !== value);
      // Reset to page 1 when filters change
      searchParams.set("page", "1");
      setSearchParams(searchParams);
      return updated;
    });
  };

  const updatePreferences = async (
    currentUser: User | null,
    key: string,
    updated: string[]
  ) => {
    if (!currentUser) return;

    try {
      const preferences = {
        ...defaultPreferences,
        [key]:
          updated.length > 0
            ? updated
            : defaultPreferences[key as keyof typeof defaultPreferences],
        locations: key === "locations" ? updated : selectedLocations,
        experienceLevel: key === "experienceLevel" ? updated[0] : selectedExperienceLevels[0],
        industries: key === "industries" ? updated : selectedIndustries,
      };

      await setDoc(
        doc(db, "users", currentUser.uid),
        { preferences },
        { merge: true }
      );

      setPreferencesSaved(true);
    } catch (error) {
      console.error("Error saving preferences:", error);
      setError("Failed to save preferences.");
    }
  };

  const applyFilters = useCallback(() => {
    let filteredJobs = [...originalMatchedJobs];
    
    if (selectedLocations.length > 0) {
      filteredJobs = filteredJobs.filter(job => 
        selectedLocations.includes(job.location?.toLowerCase().replace(/\s+/g, "_"))
      );
    }
    
    if (selectedExperienceLevels.length > 0) {
      filteredJobs = filteredJobs.filter(job => 
        selectedExperienceLevels.includes(normalizeExperienceLevel(job.experience_level))
      );
    }
    
    if (selectedIndustries.length > 0) {
      filteredJobs = filteredJobs.filter(job => 
        selectedIndustries.includes(job.title?.toLowerCase())
      );
    }
    
    setTotalResults(filteredJobs.length);
    setTotalPages(Math.ceil(filteredJobs.length / itemsPerPage));
    
    // Update current page results
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setJobResults(filteredJobs.slice(startIndex, endIndex));
  }, [originalMatchedJobs, selectedLocations, selectedExperienceLevels, selectedIndustries, currentPage]);

  useEffect(() => {
    applyFilters();
  }, [selectedLocations, selectedExperienceLevels, selectedIndustries, applyFilters]);

  const clearFilters = () => {
    setSelectedLocations([]);
    setSelectedExperienceLevels([]);
    setSelectedIndustries([]);
    setJobResults(originalMatchedJobs);
    setTotalResults(originalMatchedJobs.length);
    setTotalPages(Math.ceil(originalMatchedJobs.length / itemsPerPage));
    setSearchParams({ page: "1" });
  };

  const toggleDropdown = (key: string) => {
    setDropdownOpen((prev) => ({
      ...Object.keys(prev).reduce((acc, k) => ({ ...acc, [k]: false }), {}),
      [key]: !prev[key],
    }));
  };

  const handlePageClick = (data: { selected: number }) => {
    const newPage = data.selected + 1;
    searchParams.set("page", newPage.toString());
    setSearchParams(searchParams);
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  const handleSelection = useCallback((job: JobData) => {
    setSelection(job);
  }, []);

  // Remove client-side filtering since it's now handled by backend
  const currentJobs = jobResults;

  const handleOpenFilterModal = () => {
    setTempSelectedLocations([...selectedLocations]);
    setTempSelectedExperienceLevels([...selectedExperienceLevels]);
    setTempSelectedIndustries([...selectedIndustries]);
    setIsFilterModalOpen(true);
    setIsModalClosing(false);
    document.body.style.overflow = "hidden";
  };

  const handleCloseFilterModal = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsFilterModalOpen(false);
      setIsModalClosing(false);
      document.body.style.overflow = "unset";
    }, 300);
  };

  const handleApplyFilters = () => {
    setSelectedLocations(tempSelectedLocations);
    setSelectedExperienceLevels(tempSelectedExperienceLevels);
    setSelectedIndustries(tempSelectedIndustries);
    handleCloseFilterModal();
  };

  // Add initial fetch for applied jobs
  useEffect(() => {
    const fetchInitialAppliedJobs = async () => {
      try {
        const applications = await getApplications();
        const appliedJobIds = new Set(applications.map(app => app.jobId));
        setAppliedJobs(appliedJobIds);
      } catch (error) {
        console.error('Error fetching initial applied jobs:', error);
      }
    };

    fetchInitialAppliedJobs();
  }, []); // Run once on component mount

  // Add a function to refresh applied jobs
  const refreshAppliedJobs = async () => {
    try {
      const applications = await getApplications();
      const appliedJobIds = new Set(applications.map(app => app.jobId));
      setAppliedJobs(appliedJobIds);
    } catch (error) {
      console.error('Error fetching applied jobs:', error);
    }
  };

  // Add event listener for application updates
  useEffect(() => {
    const handleApplicationUpdate = () => {
      refreshAppliedJobs();
    };

    window.addEventListener('application-updated', handleApplicationUpdate);
    return () => {
      window.removeEventListener('application-updated', handleApplicationUpdate);
    };
  }, []);

  return (
    <div className="job-search-container">
      <Sidebar />
      <div className="job-search">
        <div className={`filter-container ${scrolled ? "scrolled" : ""}`}>
          <div className={`filter-content ${loading ? "filters-loading" : ""}`}>
            <button 
              className="filters-button" 
              onClick={handleOpenFilterModal}
              disabled={loading}
            >
              <AiOutlineFilter />
              Filters
            </button>
            <FilterDropdown
              options={loading ? [] : availableJobHubs}
              selectedValues={selectedLocations}
              setSelectedValues={setSelectedLocations}
              title="Location"
              type="locations"
              loading={loading}
            />
            <FilterDropdown
              options={loading ? [] : availableExperienceLevels}
              selectedValues={selectedExperienceLevels}
              setSelectedValues={setSelectedExperienceLevels}
              title="Experience Level"
              type="experienceLevel"
              loading={loading}
            />
            <FilterDropdown
              options={loading ? [] : availableCategories}
              selectedValues={selectedIndustries}
              setSelectedValues={setSelectedIndustries}
              title="Category"
              type="industries"
              loading={loading}
            />
            <span 
              className={`clear-filters ${loading ? "disabled" : ""}`} 
              onClick={loading ? undefined : clearFilters}
            >
              Clear Filters
            </span>
          </div>
        </div>

        <div className="job-results job-tiles-container">
          {loading ? (
            <>
              {[...Array(itemsPerPage)].map((_, index) => (
                <div key={index} className="job-tile skeleton" />
              ))}
            </>
          ) : currentJobs.length > 0 ? (
            currentJobs.map((job, index) => (
              <JobTile
                key={`${job._id}-${index}`}
                job={job}
                userId={user?._id}
                handleSelection={handleSelection}
                context={JobButtonContext.MAIN}
                isApplied={appliedJobs.has(job._id)}
                onApplicationUpdate={refreshAppliedJobs}
              />
            ))
          ) : (
            <p>No jobs found matching the selected filters.</p>
          )}
        </div>

        {totalResults > itemsPerPage && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={<FaAngleLeft />}
              nextLabel={<FaAngleRight />}
              pageCount={totalPages}
              onPageChange={handlePageClick}
              forcePage={currentPage - 1}
              containerClassName="pagination"
              activeClassName="active"
              previousClassName="prev"
              nextClassName="next"
              disabledClassName="disabled"
              pageRangeDisplayed={5}
              marginPagesDisplayed={1}
              pageLinkClassName="page-link"
            />
          </div>
        )}
      </div>

      {/* Filter Modal */}
      {isFilterModalOpen && (
        <div
          className={`filter-modal-overlay ${isFilterModalOpen ? "modal-open" : ""} ${
            isModalClosing ? "closing" : ""
          }`}
          onClick={handleCloseFilterModal}
        >
          <div className="filter-modal" onClick={(e) => e.stopPropagation()}>
            <button
              className="modal-close-button"
              onClick={handleCloseFilterModal}
              aria-label="Close modal"
            >
              <AiOutlineClose />
            </button>
            <h2>Filters</h2>
            
            <div className="filter-section">
              <span className="filter-label">Location</span>
              <FilterDropdown
                options={availableJobHubs}
                selectedValues={tempSelectedLocations}
                setSelectedValues={setTempSelectedLocations}
                title="Location"
                type="locations"
                loading={loading}
              />
            </div>

            <div className="filter-section">
              <span className="filter-label">Experience Level</span>
              <FilterDropdown
                options={availableExperienceLevels}
                selectedValues={tempSelectedExperienceLevels}
                setSelectedValues={setTempSelectedExperienceLevels}
                title="Experience Level"
                type="experienceLevel"
                loading={loading}
              />
            </div>

            <div className="filter-section">
              <span className="filter-label">Category</span>
              <FilterDropdown
                options={availableCategories}
                selectedValues={tempSelectedIndustries}
                setSelectedValues={setTempSelectedIndustries}
                title="Category"
                type="industries"
                loading={loading}
              />
            </div>

            <div className="modal-actions">
              <button className="cancel-button" onClick={handleCloseFilterModal}>
                Cancel
              </button>
              <button className="apply-button" onClick={handleApplyFilters}>
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      )}

      {selection && (
        <JobDetailsModal
          job={selection}
          isOpen={Boolean(selection._id)}
          onClose={() => setSelection(null)}
        />
      )}
    </div>
  );
};

export default JobSearch;

// In jobMatching.service.js
