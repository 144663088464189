import axios from '../axios-client';

interface CustomerData {
  email: string;
  name?: string;
}

interface PaymentMethodData {
  paymentMethodId: string;
}

export interface SubscriptionData {
  priceId: string;
  paymentMethodId?: string;
}

interface UpdateSubscriptionData {
  priceId?: string;
  paymentMethodId?: string;
}

export const stripeService = {
  // Customer management
  createCustomer: async (data: CustomerData) => {
    return axios.post('/stripe/customers', data);
  },

  // Payment methods
  listPaymentMethods: async () => {
    return axios.get('/stripe/payment-methods');
  },

  attachPaymentMethod: async (data: PaymentMethodData) => {
    return axios.post('/stripe/payment-methods', data);
  },

  // Subscriptions
  createSubscription: async (data: SubscriptionData) => {
    return axios.post('/stripe/subscriptions', data);
  },

  updateSubscription: async (subscriptionId: string, data: UpdateSubscriptionData) => {
    return axios.patch(`/stripe/subscriptions/${subscriptionId}`, data);
  },

  cancelSubscription: async (subscriptionId: string) => {
    return axios.delete(`/stripe/subscriptions/${subscriptionId}`);
  },

  // Customer portal
  createPortalSession: async () => {
    return axios.post('/stripe/portal-session');
  }
};
