import axiosClient from './axios-client';
import { AxiosError } from 'axios';

interface JobFilters {
  locations?: string[];
  jobTypes?: string[];
  industries?: string[];
  experienceLevel?: string;
  page?: number;
  limit?: number;
  companies?: string[];
  oneJobPerCompany?: boolean;
}

interface Job {
  _id: string;
  title: string;
  company: string;
  location: string;
  jobType: string;
  description: string;
  requirements: string[];
  skills: string[];
  matchScore?: number;
  [key: string]: any;
}

interface MatchedJobsResponse {
  jobs: Job[];
  totalJobs: number;
  currentPage: number;
  totalPages: number;
}

// Helper function to get user ID from localStorage
const getUserId = (): string | null => {
  const userData = localStorage.getItem('user');
  if (!userData) return null;
  try {
    const user = JSON.parse(userData);
    return user?._id || null;
  } catch (error) {
    console.error('Error parsing user data:', error);
    return null;
  }
};

// Get matched jobs using the suited-jobs endpoint
export const fetchMatchedJobs = async (filters: JobFilters): Promise<MatchedJobsResponse> => {
  const userId = getUserId();
  if (!userId) {
    return fetchJobs(filters);
  }

  try {
    const { page = 1 } = filters;  // Remove limit, only keep page for frontend display
    
    const response = await axiosClient.get('/jobs/matches', {
      params: {
        ...filters,
        userId,
        fields: 'title,company,location,jobType,skills,matchScore,createdAt',
        sort: '-matchScore'
      }
    });

    if (!response.data) {
      return fetchJobs(filters);
    }

    // Handle paginated response format
    if (response.data.jobs && Array.isArray(response.data.jobs)) {
      return {
        jobs: response.data.jobs,
        totalJobs: response.data.totalJobs || response.data.jobs.length,
        currentPage: page,
        totalPages: Math.ceil((response.data.totalJobs || response.data.jobs.length) / (filters.limit || response.data.jobs.length))
      };
    }

    // Handle array response format
    if (Array.isArray(response.data)) {
      return {
        jobs: response.data,
        totalJobs: response.data.length,
        currentPage: page,
        totalPages: 1  // All jobs are returned at once
      };
    }

    return fetchJobs(filters);
  } catch (error) {
    console.error('Error fetching matched jobs:', error);
    return fetchJobs(filters);
  }
};

// Get all jobs (fallback)
export const fetchJobs = async (filters: JobFilters = {}) => {
  try {
    const queryParams = new URLSearchParams();
    
    // Always set oneJobPerCompany=true for dev test endpoint
    queryParams.append('oneJobPerCompany', 'true');
    
    // Add other filters
    if (filters.page) {
      queryParams.append('page', filters.page.toString());
    }
    if (filters.locations?.length) {
      filters.locations.forEach(location => queryParams.append('locations', location));
    }
    if (filters.jobTypes?.length) {
      filters.jobTypes.forEach(type => queryParams.append('jobTypes', type));
    }
    if (filters.industries?.length) {
      filters.industries.forEach(industry => queryParams.append('industries', industry));
    }
    if (filters.companies?.length) {
      filters.companies.forEach(company => queryParams.append('companies', company));
    }

    const endpoint = `/dev-test/jobs?${queryParams.toString()}`;
    const response = await axiosClient.get(endpoint);
    
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

export const fetchJobById = async (jobId: string): Promise<Job> => {
  try {
    const response = await axiosClient.get(`/jobs/${jobId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching job by ID:', error);
    throw error;
  }
};
  