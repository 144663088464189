import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/components/bringYourJob/_bringYourJob.scss';
import JobForm from './JobForm';
import JobDetailsModal, { JobData } from './JobDetailsModal';
import Sidebar from '../Sidebar';
import { JobButtonContext } from '../../constant';

const BringYourJob: React.FC = () => {
  const [selection, setSelection] = useState<JobData | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Check for user authentication
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = localStorage.getItem('user');
        if (userData) {
          const user = JSON.parse(userData);
          if (user._id) {
            setUser(user);
          } else {
            navigate('/auth/login');
          }
        } else {
          navigate('/auth/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to fetch user data. Please check your permissions.');
      }
    };

    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    const handleSidebarChange = () => {
      const sidebar = document.querySelector('.sidebar');
      setIsExpanded(sidebar?.classList.contains('expanded') || false);
    };

    handleSidebarChange();

    const observer = new MutationObserver(handleSidebarChange);
    const sidebar = document.querySelector('.sidebar');
    
    if (sidebar) {
      observer.observe(sidebar, {
        attributes: true,
        attributeFilter: ['class']
      });
    }

    return () => observer.disconnect();
  }, []);

  const handleJobSubmit = (processedJob: JobData) => {
    setSelection(processedJob);
  };

  if (!user) {
    return (
      <div className="bring-your-job-container">
        <Sidebar />
        <div className="bring-your-job">
          <div className="loading-state">
            {error ? (
              <p className="error-message">{error}</p>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`bring-your-job-container ${isExpanded ? 'sidebar-expanded' : ''}`}>
      <Sidebar />
      <div className="bring-your-job">
        <div className="header-section">
          <h3>Bring Your Own Job</h3>
        </div>
        <JobForm onSubmit={handleJobSubmit} />
      </div>

      {selection && (
        <JobDetailsModal
          job={selection}
          isOpen={Boolean(selection._id)}
          onClose={() => setSelection(null)}
        />
      )}
    </div>
  );
};

export default BringYourJob; 