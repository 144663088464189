import React, { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";
import { Card, CardHeader, CardContent } from "./ui/Card";
import { Button } from "./ui/Button";
import { Mic, MicOff, Play, Square } from "lucide-react";

// Add interfaces for the data structures
interface Question {
  question: string;
}

interface Evaluation {
  feedback: string;
}

interface Summary {
  overallScore: number;
  generalFeedback: string;
  keyStrengths: string[];
}

const MockInterview = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState<Question | null>(null);
  const [evaluation, setEvaluation] = useState<Evaluation | null>(null);
  const [summary, setSummary] = useState<Summary | null>(null);
  const [status, setStatus] = useState<
    "idle" | "starting" | "interviewing" | "completed" | "error"
  >("idle");
  const [error, setError] = useState<string | null>(null);
  const [isPlayingQuestion, setIsPlayingQuestion] = useState(false);

  const socketRef = useRef<any>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const audioQueueRef = useRef<string[]>([]);
  const isPlayingRef = useRef<boolean>(false);
  const peerRef = useRef<RTCPeerConnection | null>(null);
  const dataChannelRef = useRef<RTCDataChannel | null>(null);

  useEffect(() => {
    // Initialize WebRTC connection
    initializeWebRTC();

    // Initialize Socket.IO connection
    socketRef.current = io("http://localhost:3000", {
      transports: ["websocket"],
    });

    socketRef.current.on("connect", () => {
      setIsConnected(true);
      setError(null);
    });

    socketRef.current.on("disconnect", () => {
      setIsConnected(false);
    });

    socketRef.current.on("error", (error: any) => {
      setError(error.message);
      setStatus("error");
    });

    // Handle interview events
    socketRef.current.on("interview_started", (data: any) => {
      setCurrentQuestion(data.question);
      setStatus("interviewing");
      playAudioQuestion();
    });

    socketRef.current.on("next_question", (data: any) => {
      setCurrentQuestion(data.question);
      setEvaluation(data.evaluation);
      playAudioQuestion();
    });

    socketRef.current.on("interview_completed", (data: any) => {
      setEvaluation(data.evaluation);
      setSummary(data.summary);
      setStatus("completed");
    });

    // Handle audio streaming
    socketRef.current.on("audio_chunk", (chunk: any) => {
      audioQueueRef.current.push(chunk.data as never);
      if (!isPlayingRef.current) {
        playNextChunk();
      }
    });

    socketRef.current.on("audio_complete", () => {
      setIsPlayingQuestion(false);
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      if (peerRef.current) {
        peerRef.current.close();
      }
      stopMediaStream();
    };
  }, []);

  const initializeWebRTC = async () => {
    try {
      peerRef.current = new RTCPeerConnection({
        iceServers: [
          {
            urls: "stun:stun.l.google.com:19302",
          },
        ],
      });

      // Handle ICE candidates
      peerRef.current.onicecandidate = (event) => {
        if (event.candidate) {
          socketRef.current.emit("ice-candidate", event.candidate);
        }
      };

      // Listen for ICE candidates from the server
      socketRef.current.on("ice-candidate", async (candidate: RTCIceCandidate) => {
        if (peerRef.current) {
          await peerRef.current.addIceCandidate(new RTCIceCandidate(candidate));
        }
      });

      // Handle offer/answer exchange
      socketRef.current.on("offer", async (offer: RTCSessionDescriptionInit) => {
        if (peerRef.current) {
          await peerRef.current.setRemoteDescription(new RTCSessionDescription(offer));
          const answer = await peerRef.current.createAnswer();
          await peerRef.current.setLocalDescription(answer);
          socketRef.current.emit("answer", answer);
        }
      });

      socketRef.current.on("answer", async (answer: RTCSessionDescriptionInit) => {
        if (peerRef.current) {
          await peerRef.current.setRemoteDescription(new RTCSessionDescription(answer));
        }
      });

      // Create data channel
      dataChannelRef.current = peerRef.current.createDataChannel("audioChannel");
      dataChannelRef.current.onmessage = (event) => {

      };
    } catch (error) {
      console.error("Error initializing WebRTC:", error);
      setError("Failed to initialize WebRTC connection");
    }
  };

  const stopMediaStream = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track: any) => track.stop());
    }
  };

  const playNextChunk = async () => {
    if (audioQueueRef.current.length === 0) {
      isPlayingRef.current = false;
      return;
    }

    isPlayingRef.current = true;
    const chunk = audioQueueRef.current.shift();

    try {
      await playAudioChunk(String(chunk));
      playNextChunk();
    } catch (error) {
      console.error("Error playing audio chunk:", error);
      isPlayingRef.current = false;
    }
  };

  const playAudioQuestion = () => {
    setIsPlayingQuestion(true);
    audioQueueRef.current = [];
    isPlayingRef.current = false;
  };

  const playAudioChunk = async (base64Data: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      try {
        const audioData = atob(base64Data);
        const audioArray = new Uint8Array(audioData.length);
        for (let i = 0; i < audioData.length; i++) {
          audioArray[i] = audioData.charCodeAt(i);
        }

        const blob = new Blob([audioArray], { type: "audio/mp3" });
        const audio = new Audio(URL.createObjectURL(blob));

        audio.onended = () => {
          resolve();
        };

        audio.onerror = (error) => {
          reject(error);
        };

        audio.play();
      } catch (error) {
        reject(error);
      }
    });
  };

  const startInterview = async () => {
    try {
      socketRef.current.emit("start_interview", {
        field: "Software Development", // You can make these configurable
        level: "Senior",
      });
      setStatus("starting");
    } catch (err) {
      setError("Failed to start interview");
      setStatus("error");
    }
  };

  const startRecording = async () => {
    try {
      streamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      // Add audio track to peer connection
      if (peerRef.current && streamRef.current) {
        streamRef.current.getAudioTracks().forEach((track) => {
          peerRef.current?.addTrack(track, streamRef.current!);
        });

        // Create and send offer
        const offer = await peerRef.current.createOffer();
        await peerRef.current.setLocalDescription(offer);
        socketRef.current.emit("offer", offer);
      }

      setIsRecording(true);
    } catch (err) {
      setError("Failed to start recording");
      console.error("Recording error:", err);
    }
  };

  const stopRecording = () => {
    if (streamRef.current && isRecording) {
      streamRef.current.getAudioTracks().forEach((track) => {
        track.stop();
      });
      setIsRecording(false);
    }
  };

  const renderStatus = () => {
    switch (status) {
      case "idle":
        return (
          <div className="text-center">
            <Button onClick={startInterview} className="mb-4">
              Start Interview
            </Button>
          </div>
        );
      case "interviewing":
        return (
          <div className="space-y-4">
            <div className="p-4 bg-gray-100 rounded-lg">
              <h3 className="font-medium mb-2">Current Question:</h3>
              <p>{currentQuestion?.question}</p>
              {isPlayingQuestion && (
                <div className="text-sm text-gray-500 mt-2">
                  Playing question...
                </div>
              )}
            </div>
            <div className="flex justify-center gap-4">
              <Button
                onClick={isRecording ? stopRecording : startRecording}
                className={isRecording ? "bg-red-500" : ""}
                disabled={isPlayingQuestion}
              >
                {isRecording ? (
                  <MicOff className="mr-2" />
                ) : (
                  <Mic className="mr-2" />
                )}
                {isRecording ? "Stop Recording" : "Start Recording"}
              </Button>
            </div>
          </div>
        );
      case "completed":
        return (
          <div className="space-y-4">
            <h3 className="font-medium">Interview Complete</h3>
            {summary && (
              <div className="p-4 bg-gray-100 rounded-lg">
                <h4 className="font-medium mb-2">Summary:</h4>
                <p>Overall Score: {summary.overallScore}/10</p>
                <p className="mt-2">{summary.generalFeedback}</p>
                <div className="mt-4">
                  <h5 className="font-medium">Key Strengths:</h5>
                  <ul className="list-disc pl-5">
                    {summary.keyStrengths.map((strength, i) => (
                      <li key={i}>{strength}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <h2 className="text-2xl font-bold text-center">AI Mock Interview</h2>
        {error && <div className="text-red-500 text-center mt-2">{error}</div>}
      </CardHeader>
      <CardContent>{renderStatus()}</CardContent>
    </Card>
  );
};

export default MockInterview;
