import React, { useEffect, useState, useRef, useCallback } from "react";
import "../../styles/components/landing-pages/jobPage.scss";
import Navbar from "./Navbar";
import ResumeHighlight from "../../animations/components/ResumeHighlight";
import feature2 from "../../assets/feature2.png";
import feature3 from "../../assets/feature3.png";
import feature4 from "../../assets/feature4.png";
import Footer from "./Footer";
import jobDescriptionIcon from "../../assets/cover-letter.svg";
import jobTile from "../../assets/job-tile.svg";
import { Link } from "react-router-dom";
import JobTile from "../../animations/components/JobTile";
import NewJobBenefits from "../../animations/components/NewJobBenefits";

const CoverLetterPage = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const demoSectionRef = useRef<HTMLDivElement | null>(null);
  const heroSectionRef = useRef<HTMLElement | null>(null);
  const [demoSectionDimensions, setDemoSectionDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [heroHeight, setHeroHeight] = useState(0);
  const NAVBAR_HEIGHT = 88;
  const [isCaptionVisible, setIsCaptionVisible] = useState(false);
  const [hasExpanded, setHasExpanded] = useState(false);
  const [activeGroup, setActiveGroup] = useState<'days5' | 'days10' | 'days15' | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activeFaqIndex, setActiveFaqIndex] = useState<number | null>(null);

  // Get dimensions after component mounts
  useEffect(() => {
    if (demoSectionRef.current) {
      const rect = demoSectionRef.current.getBoundingClientRect();
      setDemoSectionDimensions({
        width: rect.width,
        height: rect.height,
      });
    }
    if (heroSectionRef.current) {
      const rect = heroSectionRef.current.getBoundingClientRect();
      setHeroHeight(rect.height);
    }
  }, []);

  // Handle scroll
  useEffect(() => {
    const handleScroll = () => {
      if (demoSectionRef.current) {
        const rect = demoSectionRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        
        const startScroll = rect.top - viewportHeight * 1.8;
        const endScroll = rect.top + (viewportHeight * 0.8);
        const scrollRange = endScroll - startScroll;
        
        const currentScroll = window.scrollY - startScroll;
        const progress = Math.max(0, Math.min(1, currentScroll / scrollRange));
        
        setScrollProgress(progress);
        
        if (progress >= 1 && !hasExpanded) {
          setHasExpanded(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    requestAnimationFrame(handleScroll);
    
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasExpanded]);

  // Calculate transforms
  const progress = scrollProgress || 0;

  // For hero section
  const heroTranslateY = -progress * 100;
  const heroOpacity = Math.max(1 - progress * 2, 0);

  // For demo section
  const maxScaleX = Math.min(5, window.innerWidth / (180 * 2)); // Using fixed base width
  const maxScaleY = Math.min(5, (window.innerHeight - NAVBAR_HEIGHT) / (180 * 2));
  const maxScale = Math.min(5, maxScaleX, maxScaleY); // Limit max scale to 5x

  const demoScale = 1 + (progress * (maxScale - 1)); // This makes it scale up smoothly
  const deltaHeight = demoSectionDimensions.height * (demoScale - 1);
  const demoTranslateY = progress * -50;

  useEffect(() => {
    if (progress >= 1 && !isCaptionVisible) {
      setIsCaptionVisible(true);
    }
  }, [progress]);

  useEffect(() => {
    if (progress >= 1 && !hasExpanded) {
      setHasExpanded(true);
    }
  }, [progress]);

  const calculateProgress = useCallback(() => {
    if (!demoSectionRef.current) return 0;
    
    const rect = demoSectionRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight - NAVBAR_HEIGHT;
    const elementTop = rect.top - NAVBAR_HEIGHT;
    
    const progress = Math.max(0, Math.min(1, 1 - (elementTop / viewportHeight)));
    
    return progress;
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Set up intersection observer for feature animations
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Add animate class immediately
          entry.target.classList.add('animate');
          
          // Force a reflow
          if (entry.target instanceof HTMLElement) {
            void entry.target.offsetHeight;
          }
          
          if (entry.isIntersecting) {
            // Add visible class after a small delay to ensure animation triggers
            requestAnimationFrame(() => {
              entry.target.classList.add('visible');
            });
            observer.unobserve(entry.target); // Only animate once
          }
        });
      },
      {
        root: null,
        threshold: 0.2, // Start animation when 20% of element is visible
        rootMargin: '0px'
      }
    );

    // Observe all feature rows
    document.querySelectorAll('.feature-row').forEach((feature) => {
      observer.observe(feature);
    });

    return () => observer.disconnect();
  }, []);

  const handleFaqClick = (index: number) => {
    setActiveFaqIndex(activeFaqIndex === index ? null : index);
  };

  // Handle entrance animations for FAQs
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.target.classList.add('animate');
          
          if (entry.target instanceof HTMLElement) {
            void entry.target.offsetHeight;
          }
          
          if (entry.isIntersecting) {
            requestAnimationFrame(() => {
              entry.target.classList.add('visible');
            });
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        threshold: 0.2,
        rootMargin: '0px'
      }
    );

    document.querySelectorAll('.faq-item').forEach((item) => {
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <Navbar />
      <div className="job-page-container">
        <section
          ref={heroSectionRef}
          className="job-features-hero-section animate"
        >
          <div className="hero-content">
            <h1 className="hero-heading animate">
              Effortless Cover Letter Creation
            </h1>
            <p className="hero-subheading animate">
            Generate tailored, impactful cover letters in seconds with our AI-powered tool. Stand 
            out from the crowd and make a lasting first impression.
            </p>
            <Link to="/auth/signup" className="cta-btn animate">
              Get Started - It's Free
            </Link>
          </div>
          <div className="hero-image-container">
            <div className="resume-highlight-container">
              <ResumeHighlight />
            </div>
          </div>
        </section>

        <section className="job-demo-section" ref={demoSectionRef}>
          <div className="demo-container">
            <h2 className="demo-heading">AI-Powered Cover Letter Generation</h2>
            <div className="demo-animation-container">
              <img
                src={jobDescriptionIcon}
                alt="Cover Letter Demo"
                className={`demo-icon ${hasExpanded && !isMobile ? 'expanded' : ''}`}
                style={!hasExpanded && !isMobile ? { 
                  '--scale': `${1 + (progress * 5.67)}`
                } as React.CSSProperties : {}}
              />
            </div>
          </div>
        </section>

        <section className="job-features-section">
          <div className="feature-row">
            <div className="feature-content">
              <h2>Tailored Cover Letters</h2>
              <p>
              Our AI reviews the job description and your experience to craft a 
              personalized cover letter that emphasizes your most relevant skills and achievements.
              </p>
            </div>
            <div className="feature-image">
              <NewJobBenefits />
            </div>
          </div>

          <div className="feature-row">
            <div className="feature-image">
              <img src={feature2} alt="Templates feature" />
            </div>
            <div className="feature-content">
              <h2>Professional Templates</h2>
              <p>
              Select from a range of industry-specific templates designed to captivate 
              hiring managers and showcase your expertise.
              </p>
            </div>
          </div>

          <div className="feature-row">
            <div className="feature-content">
              <h2>Effortless Customization</h2>
              <p>
              Easily personalize your cover letter with our user-friendly editor. Add 
              your unique touch while preserving a professional and polished format.
              </p>
            </div>
            <div className="feature-image">
              <JobTile />
            </div>
          </div>

          <div className="feature-row">
            <div className="feature-image">
              <img src={feature4} alt="Multiple versions feature" />
            </div>
            <div className="feature-content">
              <h2>Effortless Cover Letter Management</h2>
              <p>
              Effortlessly manage multiple cover letter versions tailored to different job applications. 
              Stay organized and update your cover letters with ease, all in one convenient place.
              </p>
            </div>
          </div>
        </section>

        <section className="faq-section">
          <div className="faq-container">
            <h2 className="faq-heading">Frequently Asked Questions</h2>
            <div className="faq-grid">
              {[
                {
                  question: "How does the AI write cover letters?",
                  answer: "Our AI analyzes your resume and the job description to create personalized cover letters that highlight relevant experience and skills. It maintains a professional tone while showcasing your unique qualifications."
                },
                {
                  question: "Can I customize the generated cover letters?",
                  answer: "Absolutely! While our AI creates a strong foundation, you can easily edit and personalize any part of the cover letter to match your voice and add specific details about your experience."
                },
                {
                  question: "How long does it take to generate a cover letter?",
                  answer: "Our AI generates a professional cover letter in under 30 seconds. You can then review and customize it as needed, saving hours of writing time."
                },
                {
                  question: "Are the cover letters ATS-friendly?",
                  answer: "Yes! All generated cover letters are optimized for ATS systems while maintaining natural, engaging language that appeals to human readers."
                },
                {
                  question: "Do you offer different styles and formats?",
                  answer: "We provide multiple cover letter templates and tones to match different industries and company cultures, from traditional to modern and creative styles."
                },
                {
                  question: "Can I save multiple versions?",
                  answer: "Yes, save unlimited cover letter versions for different applications. Our platform keeps track of all versions, making it easy to reuse and modify them for future applications."
                }
              ].map((faq, index) => (
                <div 
                  key={index}
                  className={`faq-item ${activeFaqIndex === index ? 'active' : ''}`}
                >
                  <div 
                    className="faq-question"
                    onClick={() => handleFaqClick(index)}
                  >
                    <h3>{faq.question}</h3>
                    <span className="faq-icon">+</span>
                  </div>
                  <div className="faq-answer">
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default CoverLetterPage; 