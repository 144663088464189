import React, { useState, useEffect } from 'react';
import { FaChevronRight } from 'react-icons/fa6';
import '../styles/components/jobTile.scss';
import { useApplicationModal } from '../context/ApplicationModalContext';
import { JobButtonContext, getJobButtonText } from '../constant';
import { blobStorageService } from '../services/blobStorage.service';

interface JobTileProps {
  job: any;
  userId: string;
  handleSelection: (data: any) => void;
  context?: JobButtonContext;
  isApplied?: boolean;
  onApplicationUpdate?: () => void;
}

const JobTile: React.FC<JobTileProps> = ({ job, userId, handleSelection, context = JobButtonContext.MAIN, isApplied = false, onApplicationUpdate }) => {
  const { _id, title, company, location, salary, jobType, days_to_live } = job;
  const { setModalData } = useApplicationModal();
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [logoVariant, setLogoVariant] = useState<'horizontal' | 'square' | 'vertical' | null>(null);

  useEffect(() => {
    const loadLogo = async () => {
      if (company) {
        const logo = await blobStorageService.getBestLogoVariant(company);
        if (logo) {
          setLogoUrl(logo.url);
          setLogoVariant(logo.variant);
        }
      }
    };
    loadLogo();
  }, [company]);

  // Helper function to normalize job type strings
  const normalizeJobType = (jobType: string): string => {
    if (!jobType) return '';
    
    const cleanedType = jobType
      .replace(/^Job Type:\s*/i, '')
      .toLowerCase()
      .replace(/[-_]/g, ' ')
      .replace(/\s+/g, ' ')
      .trim();

    return cleanedType;
  };

  // Function to map job types to corresponding class names
  const getJobTypeClass = (jobType: string): string => {
    const normalizedType = normalizeJobType(jobType);
    
    switch (normalizedType) {
      case 'full time':
      case 'fulltime':
      case 'full-time':
        return 'full-time';
      case 'part time':
      case 'parttime':
      case 'part-time':
        return 'part-time';
      case 'internship':
      case 'intern':
        return 'internship';
      case 'contract':
      case 'contractor':
      case 'contractual':
        return 'contract';
      default:
        return normalizedType.replace(/\s+/g, '-');
    }
  };

  const formatJobType = (jobType: string): string => {
    const normalizedType = normalizeJobType(jobType);
    
    switch (normalizedType) {
      case 'full time':
      case 'fulltime':
      case 'full-time':
        return 'FULL-TIME';
      case 'part time':
      case 'parttime':
      case 'part-time':
        return 'PART-TIME';
      case 'internship':
      case 'intern':
        return 'INTERNSHIP';
      case 'contract':
      case 'contractor':
      case 'contractual':
        return 'CONTRACT';
      default:
        return jobType.toUpperCase().replace(/[-_]/g, ' ');
    }
  };

  const jobTypeClass = jobType ? getJobTypeClass(jobType) : '';

  const getStatusClass = (daysToLive: any): string => {
    const days = daysToLive?.$numberInt 
      ? parseInt(daysToLive.$numberInt) 
      : typeof daysToLive === 'number' 
        ? daysToLive 
        : 0;

    if (days >= 10) return 'recent';
    if (days >= 5) return 'moderate';
    return 'old';
  };

  return (
    <div 
      className="job-tile" 
      onClick={() => handleSelection(job)}
      style={{ cursor: 'pointer' }}
    >
      <div className={`status-dot ${getStatusClass(days_to_live)}`} />

      {jobType && <div className={`job-type-tag ${jobTypeClass}`}>{formatJobType(jobType)}</div>}

      <div className="job-info">
        <div className={`company-logo-container ${logoVariant === 'horizontal' ? 'horizontal-logo-container' : ''}`}>
          {logoUrl ? (
            <img
              src={logoUrl}
              alt={company || 'Company'}
              className={`company-logo ${logoVariant === 'horizontal' ? 'horizontal-logo' : ''}`}
            />
          ) : (
            <div className="logo-placeholder" />
          )}
        </div>

        <h3 className="job-title" title={title}>
          {title}
        </h3>
        <p className="company-location">
          {company} • {location}
        </p>

        <div className="salary-placeholder" />

        <div className="job-actions">
          <button 
            onClick={(e) => {
              e.stopPropagation();
              handleSelection(job);
            }} 
            className={`apply-button ${isApplied ? 'applied' : ''}`}
            title={isApplied ? 'Click to view application details' : 'Apply for this job'}
          >
            {isApplied ? 'Applied' : getJobButtonText(context)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobTile;
