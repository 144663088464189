import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import axiosClient from '../axios-client';
import '../styles/components/coverLetter.scss'; // Import the SCSS file
import { ResumeText } from './ResumeComponent';  // Import the shared interface
import { ReactComponent as AIIcon } from '../assets/ai-icon.svg'; // Update the import to use the correct SVG
import { mainUrl } from '../constant';
import axios from 'axios';
import { addUtmParameters } from '../utils/utm';
import { useApplicationModal } from '../context/ApplicationModalContext';
import ApplicationConfirmModal from './ApplicationConfirmModal';
import { motion, AnimatePresence } from 'framer-motion';
import { debounce } from 'lodash';

interface JobData {
  _id: string;
  title: string;
  original_title?: string;
  company: string;
  location: string;
  location_hub?: string;
  summary?: string;
  qualifications?: string[];
  preferredQualifications?: string[];
  responsibilities?: string[];
  environment?: string;
  skills?: string | string[]; // Allow skills to be a string or an array
  salary?: string;
  experience_level?: string;
  jobType?: string;
  tailored_flag?: string;
  days_to_live?: object;
  job_url?: string;
}

interface CoverLetterProps {
  job: JobData;
  resumeText: ResumeText;
  hasBeenTailored: boolean;
  setHasBeenTailored: (value: boolean) => void;
  cachedContent: string | null;
  setCachedContent: (content: string | null) => void;
  setShowCoverLetterTab?: (value: boolean) => void;
}

interface Selection {
  text: string;
  x: number;
  y: number;
}

const CoverLetter: React.FC<CoverLetterProps> = ({
  job,
  resumeText,
  hasBeenTailored,
  setHasBeenTailored,
  cachedContent,
  setCachedContent,
  setShowCoverLetterTab,
}) => {
  const [coverLetter, setCoverLetter] = useState(cachedContent ? JSON.parse(cachedContent) : { content: '' });
  const [loading, setLoading] = useState(!cachedContent);
  const [selection, setSelection] = useState<Selection | null>(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showButtons, setShowButtons] = useState(!!cachedContent);
  const [showAnimation, setShowAnimation] = useState(!cachedContent);
  const isGenerating = useRef(false);
  const generationAttempted = useRef(false);
  const { setModalData } = useApplicationModal();
  const [hasLeftForExternalSite, setHasLeftForExternalSite] = useState(false);

  // Initialize from cache when component mounts or cache changes
  useEffect(() => {
    if (cachedContent) {
      setCoverLetter(JSON.parse(cachedContent));
      setShowButtons(true);
      setLoading(false);
      setHasBeenTailored(true);
      setShowAnimation(false);
      generationAttempted.current = true;
    }
  }, [cachedContent, setHasBeenTailored]);

  // Add new paragraph reveal logic with proper spacing
  const paragraphs = useMemo(() => {
    if (!coverLetter.content) return [];
    return coverLetter.content.split(/\n\s*\n/).map((p: string) => p.trim()).filter(Boolean);
  }, [coverLetter.content]);

  const generateCoverLetter = useCallback(async () => {
    if (hasBeenTailored || isGenerating.current || cachedContent || generationAttempted.current) {
      return;
    }

    generationAttempted.current = true;
    isGenerating.current = true;
    setLoading(true);
    setShowAnimation(true);

    try {
      const description = `title: ${job.original_title || job.title} 
        responsibilities: ${job.responsibilities?.join(' ')} 
        qualification: ${job.qualifications?.join(' ')} 
        skills: ${Array.isArray(job.skills) ? job.skills.join(' ') : job.skills}`;

      const response = await axiosClient.post('builder/cover-letter', {
        jobDescription: description,
        userDetails: resumeText,
      }, {
        timeout: 60000
      });

      const data = response.data;
      
      if (!cachedContent) {
        setCoverLetter(data);
        setHasBeenTailored(true);
        setShowButtons(true);
        setCachedContent(JSON.stringify(data));
        setShowCoverLetterTab?.(true);
      }
    } catch (error) {
      console.error('Error fetching cover letter:', error);
      if (!cachedContent) {
        setCoverLetter({ 
          content: 'Failed to generate cover letter. The request timed out. Please try again.',
          signature: { closing: '', name: '' }
        });
      }
    } finally {
      isGenerating.current = false;
      setLoading(false);
      setTimeout(() => {
        setShowAnimation(false);
      }, 1000);
    }
  }, [job, resumeText, hasBeenTailored, cachedContent, setCachedContent, setHasBeenTailored]);

  // Debounced version of generateCoverLetter
  const debouncedGenerate = useMemo(
    () => debounce(generateCoverLetter, 1000, { leading: true, trailing: false }),
    [generateCoverLetter]
  );

  // Single useEffect to handle generation
  useEffect(() => {
    if (!cachedContent && !hasBeenTailored && job?._id && !generationAttempted.current) {
      setShowButtons(false);
      setLoading(true);
      debouncedGenerate();
    }

    return () => {
      debouncedGenerate.cancel();
      generationAttempted.current = false;
    };
  }, [job._id, hasBeenTailored, cachedContent, debouncedGenerate]);

  // Handle user edits
  const handleEdit = (newContent: string) => {
    const updatedCoverLetter = {
      ...coverLetter,
      content: newContent
    };
    setCoverLetter(updatedCoverLetter);
    setShowButtons(true);
    setCachedContent(JSON.stringify(updatedCoverLetter));
  };

  // Clean up function when component unmounts
  useEffect(() => {
    return () => {
      isGenerating.current = false;
    };
  }, []);

  const handleTextSelection = useCallback(() => {
    const selectedText = window.getSelection()?.toString().trim();
    if (selectedText) {
      const range = window.getSelection()?.getRangeAt(0);
      const rect = range?.getBoundingClientRect();
      setSelection({
        text: selectedText,
        x: rect?.left || 0,
        y: rect?.bottom || 0,
      });
    } else {
      setSelection(null);
      setShowSuggestions(false);
    }
  }, []);

  const handleRephrase = async () => {
    if (selection) {
      try {
        const response = await axiosClient.post('builder/rephrase', {
          text: selection.text,
          context: coverLetter.content
        });

        setSuggestions(response.data.suggestions || []);
        setShowSuggestions(true);
      } catch (error) {
        console.error('Error getting rephrasing suggestions:', error);
      }
    }
  };

  const handleDownload = async () => {
    const user = JSON.parse(String(localStorage.getItem("user")));
    if (coverLetter.content) {
      try {
        const response = await axiosClient.post('/pdf/generate-coverletter-pdf', { 'coverLetter': coverLetter.content });
        if (response.data && response.data.downloadUrl) {
          window.open(`${mainUrl}${response.data.downloadUrl}`, '_blank');

          const link = document.createElement('a');
          link.href = response.data.downloadUrl;
          link.download = `${user.firstName}_${user.lastName}_coverletter.pdf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          setShowButtons(true);
        } else {
          throw new Error('Download URL not received from server');
        }
      } catch (error: any) {
        console.error('Error generating PDF:', error);
        if (axios.isAxiosError(error) && error.response) {
          console.error('Error response:', error.response.data);
          alert(`Error: ${error.response.data.message || 'Unknown error'}`);
        } else {
          alert('An error occurred while generating the PDF. Please try again.');
        }
      }
    }
  }

  // Add effect to handle window focus
  useEffect(() => {
    const handleWindowFocus = () => {
      if (hasLeftForExternalSite) {
        setModalData({ 
          showModal: true, 
          jobTitle: job.title, 
          companyName: job.company,
          jobId: job._id
        });
        setHasLeftForExternalSite(false);
      }
    };

    window.addEventListener('focus', handleWindowFocus);
    return () => window.removeEventListener('focus', handleWindowFocus);
  }, [hasLeftForExternalSite, job.title, job.company, job._id, setModalData]);

  const handleApply = (e: React.MouseEvent) => {
    e.preventDefault();
    if (job.job_url) {
      setHasLeftForExternalSite(true);
      window.open(addUtmParameters(job.job_url), '_blank', 'noopener,noreferrer');
    }
  };

  const HoveringButton = () => (
    <button
      className="tailor-ai-button"
      style={{ position: 'absolute', left: `${selection?.x}px`, top: `${selection?.y}px` }}
      onClick={handleRephrase}
    >
      <AIIcon
        className="ai-icon"
        style={{ filter: 'invert(58%) sepia(7%) saturate(98%) hue-rotate(314deg) brightness(89%) contrast(86%)' }}
      />
      <span className="btn-text">Tailor with AI</span>
    </button>
  );

  const SuggestionsCard = () => (
    <div
      className="suggestions-card"
      style={{ position: 'absolute', left: `${selection?.x}px`, top: `${selection?.y ?? 0 + 30}px` }}
    >
      {suggestions.map((suggestion, index) => (
        <div key={index} className="suggestion">{suggestion}</div>
      ))}
    </div>
  );

  return (
    <div className="resume-container">
      <div className="left-container">
        <div className={`cover-letter-container ${loading ? 'loading' : ''}`}>
          <div className="cover-letter-content">
            <div className="content">
              {loading ? (
                <div className="loading-placeholder">
                  <div className="placeholder-line short"></div>
                  <div className="placeholder-line medium"></div>
                  <div className="placeholder-line long"></div>
                  <div className="placeholder-line"></div>
                  <div className="placeholder-line medium"></div>
                  <div className="placeholder-line short"></div>
                  <div className="placeholder-line"></div>
                </div>
              ) : (
                <div
                  contentEditable={!loading}
                  onBlur={(e) => handleEdit(e.currentTarget.innerText)}
                  onSelect={handleTextSelection}
                  suppressContentEditableWarning={true}
                  className="editable-content"
                  style={{ 
                    whiteSpace: 'pre-wrap',
                    position: 'relative',
                    zIndex: 1200 
                  }}
                >
                  <AnimatePresence>
                    {showAnimation ? (
                      paragraphs.map((paragraph: string, index: number) => (
                        <motion.p
                          key={index}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{
                            duration: 0.5,
                            delay: index * 0.2,
                            ease: "easeOut"
                          }}
                          style={{ marginBottom: '1rem' }}
                        >
                          {paragraph}
                        </motion.p>
                      ))
                    ) : (
                      paragraphs.map((paragraph: string, index: number) => (
                        <p key={index} style={{ marginBottom: '1rem' }}>
                          {paragraph}
                        </p>
                      ))
                    )}
                  </AnimatePresence>
                </div>
              )}
            </div>
          </div>
         {/* {selection && <HoveringButton />} }
          {showSuggestions && <SuggestionsCard /> */}
        </div>
      </div>
      <div className="right-container relative">
        <div className='bottom-0 absolute w-11/12 flex flex-col gap-3'>
          {showButtons && (
            <>
              <a
                href={job.job_url}
                onClick={handleApply}
                className="external-link"
              >
                Apply Externally <svg className="external-icon" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 3V9H19V6.414L11.207 14.207L9.793 12.793L17.586 5H15V3H21Z" fill="currentColor"/>
                  <path d="M19 19H5V5H12V3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21H19C20.103 21 21 20.103 21 19V12H19V19Z" fill="currentColor"/>
                </svg>
              </a>
              <button className='action-btn primary' onClick={handleDownload}>
                Download PDF
              </button>
            </>
          )}
        </div>
      </div>
      {hasLeftForExternalSite && (
        <div className="modal-backdrop">
          <ApplicationConfirmModal />
        </div>
      )}
    </div>
  );
};

export default CoverLetter;