import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { stripeService, SubscriptionData } from '../../services/stripe.service';
import './SubscriptionPlans.scss';
import { stripPublishingKey } from '../../constant'

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(stripPublishingKey || '');

interface Plan {
  id: string;
  name: string;
  price: number;
  interval: 'month' | 'year';
  features: string[];
}

const plans: Plan[] = [
  {
    id: 'price_basic_monthly',
    name: 'Basic Plan',
    price: 9.99,
    interval: 'month',
    features: ['Feature 1', 'Feature 2', 'Feature 3']
  },
  {
    id: 'price_premium_monthly',
    name: 'Premium Plan',
    price: 19.99,
    interval: 'month',
    features: ['All Basic Features', 'Premium Feature 1', 'Premium Feature 2', 'Premium Feature 3']
  }
];

const SubscriptionPlans = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | undefined>(undefined);

  useEffect(() => {
    loadPaymentMethods();
  }, []);

  const loadPaymentMethods = async () => {
    try {
      const { data } = await stripeService.listPaymentMethods();
      setPaymentMethods(data.paymentMethods || []);
      if (data.paymentMethods?.length > 0) {
        setSelectedPaymentMethod(data.paymentMethods[0].id);
      }
    } catch (err: any) {
      console.error('Error loading payment methods:', err);
    }
  };

  const handleSubscribe = async (priceId: string) => {
    try {
      setLoading(true);
      setError(null);

      const subscriptionData: SubscriptionData = {
        priceId,
        paymentMethodId: selectedPaymentMethod
      };

      const { data } = await stripeService.createSubscription(subscriptionData);

      if (data.requiresAction) {
        // Handle 3D Secure authentication if required
        const stripe = await stripePromise;
        const { error } = await stripe!.confirmCardPayment(data.clientSecret);
        
        if (error) {
          throw new Error(error.message);
        }
      }

      // Redirect to success page or reload subscription status
      window.location.reload();
    } catch (err: any) {
      setError(err.message || 'Failed to create subscription');
    } finally {
      setLoading(false);
    }
  };

  const handleManageSubscription = async () => {
    try {
      setLoading(true);
      const { data } = await stripeService.createPortalSession();
      if (data.url) {
        window.location.href = data.url;
      }
    } catch (err: any) {
      setError(err.message || 'Could not open customer portal');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="subscription-plans">
      <h2>Choose a Subscription Plan</h2>
      {error && <div className="error-message">{error}</div>}
      
      {paymentMethods.length > 0 && (
        <div className="payment-methods">
          <h3>Select Payment Method</h3>
          <select
            value={selectedPaymentMethod || ''}
            onChange={(e) => setSelectedPaymentMethod(e.target.value)}
          >
            {paymentMethods.map((method) => (
              <option key={method.id} value={method.id}>
                {method.card.brand} ending in {method.card.last4}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="plans-container">
        {plans.map((plan) => (
          <div key={plan.id} className={`plan-card ${plan.name.toLowerCase().includes('premium') ? 'premium' : ''}`}>
            <h3>{plan.name}</h3>
            <p className="price">${plan.price}/{plan.interval}</p>
            <ul>
              {plan.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <button 
              onClick={() => handleSubscribe(plan.id)}
              disabled={loading || !selectedPaymentMethod}
            >
              {loading ? 'Processing...' : 'Subscribe'}
            </button>
          </div>
        ))}
      </div>

      <div className="manage-subscription">
        <button 
          onClick={handleManageSubscription}
          disabled={loading}
          className="manage-btn"
        >
          Manage Subscription
        </button>
      </div>
    </div>
  );
};

export default SubscriptionPlans;
