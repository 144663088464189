import axiosClient from '../axios-client';

export interface Application {
  jobId: string;
  status: 'applied' | 'interviewing' | 'offer';
  appliedDate: string;
  lastStatusUpdate: string;
}

export interface DashboardMetrics {
  totalApplications: number;
  applicationsChange: {
    percentage: number;
    trend: 'increase' | 'decrease' | 'neutral';
  };
  totalResponses: number;
  responsesChange: {
    percentage: number;
    trend: 'increase' | 'decrease' | 'neutral';
  };
}

export const addApplication = async (jobId: string): Promise<Application[]> => {
  const response = await axiosClient.post('/applications', { jobId });
  return response.data;
};

export const getApplications = async (): Promise<Application[]> => {
  const response = await axiosClient.get('/applications');
  return response.data;
};

export const updateApplicationStatus = async (
  jobId: string,
  status: 'applied' | 'interviewing' | 'offer'
): Promise<Application> => {
  const response = await axiosClient.patch(`/applications/${jobId}`, { status });
  return response.data;
};

export const getDashboardMetrics = async (): Promise<DashboardMetrics> => {
  const response = await axiosClient.get('/users/dashboard-metrics');
  return response.data;
}; 