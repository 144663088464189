import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaArrowUp, FaArrowDown } from "react-icons/fa"; // Import icons for metrics
import { FaChevronRight } from 'react-icons/fa6';  // Importing the modern arrow icon
import Sidebar from './Sidebar'; 
import JobTile from './JobTile'; 
import JobDetailsModal, { JobData } from './JobDetailsModal';
import WelcomeModal from './WelcomeModal';
import '../styles/components/dashboard.scss';
import { fetchMatchedJobs } from '../jobService';
import { getDashboardMetrics, DashboardMetrics, getApplications } from '../services/applicationService';
import jobTitles from '../jobs_titles.json';
import axiosClient from '../axios-client';

interface Job {
  _id: string;
  title: string;
  company: string;
  location: string;
  jobType: string;
  description: string;
  requirements: string[];
  skills: string[];
  matchScore?: number;
  [key: string]: any;
}

const defaultPreferences = {
  locations: ['open_to_all'],
  jobType: ['full_time'],
  industries: Object.keys(jobTitles).map((key) => ({ value: key, label: key })),
};

const MetricSkeleton = () => (
  <div className="job-tile stat skeleton" />
);

const WeeklyGoalSkeleton = () => (
  <div className="stat skeleton">
    <div className="stat-text">
      <h2 className="skeleton-text">Weekly Goal</h2>
      <div className="weekly-goal-content">
        <div className="weekly-metric-section">
          <div className="stat-number skeleton-text">0</div>
          <div className="stat-label skeleton-text">Applications</div>
        </div>
        <div className="goal-visualization">
          <div className="circle-skeleton"></div>
        </div>
      </div>
    </div>
  </div>
);

const Dashboard: React.FC = () => {
  const [jobResults, setJobResults] = useState<Job[]>([]);
  const [error, setError] = useState('');
  const [greeting, setGreeting] = useState('Good Morning');  // For dynamic greeting
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [selection, setSelection] = useState<JobData | null>(null);
  const [jobsLoading, setJobsLoading] = useState(false);
  const [metricsLoading, setMetricsLoading] = useState(false);
  const [metrics, setMetrics] = useState<DashboardMetrics | null>(null);
  const [weeklyApplications, setWeeklyApplications] = useState(0);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [appliedJobs, setAppliedJobs] = useState<Set<string>>(new Set());

  // Function to set the greeting based on the current time
  const determineGreeting = () => {
    const currentHour = new Date().getHours();
    
    if (currentHour < 12) {
      setGreeting('Good Morning');
    } else if (currentHour < 18) {
      setGreeting('Good Afternoon');
    } else {
      setGreeting('Good Evening');
    }
  };

  useEffect(() => {
    determineGreeting();

    const initializeDashboard = async () => {
      try {
        const userData = localStorage.getItem('user');
        if (!userData) {
          navigate('/auth/login');
          return;
        }

        const user = JSON.parse(userData);
        if (!user._id) {
          navigate('/auth/login');
          return;
        }
        setUser(user);

        // Check welcome modal
        const hasSeenWelcome = localStorage.getItem('hasSeenWelcome');
        if (!hasSeenWelcome) {
          setShowWelcomeModal(true);
          localStorage.setItem('hasSeenWelcome', 'true');
        }

        // Make all API calls in parallel
        setJobsLoading(true);
        setMetricsLoading(true);

        try {
          await Promise.all([
            // Load metrics and user data
            (async () => {
              try {
                const [metricsResponse, userData] = await Promise.all([
                  getDashboardMetrics(),
                  fetchUserData()
                ]);
                if (metricsResponse) {
                  setMetrics(metricsResponse);
                }
              } catch (error) {
                console.error('Error loading metrics:', error);
                setError('Failed to load metrics.');
              } finally {
                setMetricsLoading(false);
              }
            })(),

            // Load jobs
            (async () => {
              try {
                const filters = {
                  page: 1,
                  limit: 6
                };

                const jobsResponse = await fetchMatchedJobs(filters);

                if (!jobsResponse || !jobsResponse.jobs || jobsResponse.jobs.length === 0) {
                  const filtersWithPreferences = {
                    ...filters,
                    locations: defaultPreferences.locations,
                    jobTypes: defaultPreferences.jobType,
                    industries: defaultPreferences.industries.map(i => i.value)
                  };
                  const response = await fetchMatchedJobs(filtersWithPreferences);
                  if (response?.jobs) {
                    setJobResults(response.jobs.slice(0, 6));
                  }
                } else {
                  setJobResults(jobsResponse.jobs.slice(0, 6));
                }
              } catch (error) {
                console.error('Error loading jobs:', error);
                setError('Failed to load job matches.');
              } finally {
                setJobsLoading(false);
              }
            })(),

            // Load applied jobs
            refreshAppliedJobs()
          ]);
        } catch (error) {
          console.error('Error during parallel loading:', error);
        }
      } catch (error) {
        console.error('Error initializing dashboard:', error);
        setError('Failed to load dashboard data.');
      }
    };

    initializeDashboard();
  }, [navigate]);

  useEffect(() => {
    const fetchAppliedJobs = async () => {
      try {
        const applications = await getApplications();
        const appliedJobIds = new Set(applications.map(app => app.jobId));
        setAppliedJobs(appliedJobIds);
      } catch (error) {
        console.error('Error fetching applied jobs:', error);
      }
    };

    fetchAppliedJobs();
  }, []);

  const calculateWeeklyApplications = (applications: any[]) => {
    const currentDate = new Date();
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
    startOfWeek.setHours(0, 0, 0, 0);

    return applications.filter((app: any) => {
      const appDate = new Date(app.appliedDate);
      return appDate >= startOfWeek && appDate <= currentDate;
    }).length;
  };

  const refreshMetrics = async () => {
    try {
      const [metricsResponse, userData] = await Promise.all([
        getDashboardMetrics(),
        fetchUserData()
      ]);

      if (metricsResponse) {
        setMetrics(metricsResponse);
      }

      if (userData?.applications) {
        const weeklyCount = calculateWeeklyApplications(userData.applications);
        setWeeklyApplications(weeklyCount);
      }
    } catch (error) {
      console.error('Error refreshing metrics:', error);
    }
  };

  const loadJobs = async () => {
    setJobsLoading(true);
    try {
      const filters = {
        page: 1,
        limit: 6
      };

      const jobsResponse = await fetchMatchedJobs(filters);

      if (!jobsResponse || !jobsResponse.jobs || jobsResponse.jobs.length === 0) {
        const filtersWithPreferences = {
          ...filters,
          locations: defaultPreferences.locations,
          jobTypes: defaultPreferences.jobType,
          industries: defaultPreferences.industries.map(i => i.value)
        };
        const response = await fetchMatchedJobs(filtersWithPreferences);
        if (response?.jobs) {
          setJobResults(response.jobs.slice(0, 6));
        }
      } else {
        setJobResults(jobsResponse.jobs.slice(0, 6));
      }
    } catch (error) {
      console.error('Error loading jobs:', error);
      setError('Failed to load job matches.');
    } finally {
      setJobsLoading(false);
    }
  };

  const refreshDashboardData = async () => {
    // Refresh metrics and user data first
    await refreshMetrics();
    // Then refresh jobs
    await loadJobs();
  };

  const fetchUserData = async () => {
    try {
      const userData = localStorage.getItem('user');
      if (userData) {
        const user = JSON.parse(userData);
        if (user._id) {
          const response = await axiosClient.get(`/users/${user._id}`);
          const { data } = response;
          
          // Calculate weekly applications
          const weeklyCount = calculateWeeklyApplications(data.applications || []);
          setWeeklyApplications(weeklyCount);
          setUser({
            ...data,
            totalApplications: data.applications?.length || 0
          });
          
          return data;
        }
      }
      navigate('/auth/login');
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Failed to fetch user data.');
    }
  };

  const handleWelcomeModalClose = () => {
    setShowWelcomeModal(false);
    // No need to refresh since data is already loaded
  };

  // Add a function to refresh applied jobs
  const refreshAppliedJobs = async () => {
    try {
      const applications = await getApplications();
      const appliedJobIds = new Set(applications.map(app => app.jobId));
      setAppliedJobs(appliedJobIds);
    } catch (error) {
      console.error('Error fetching applied jobs:', error);
    }
  };

  // Update the event listener for application updates
  useEffect(() => {
    const handleApplicationUpdate = async () => {
      await Promise.all([
        refreshAppliedJobs(),
        refreshMetrics()
      ]);
    };

    window.addEventListener('application-updated', handleApplicationUpdate);
    return () => {
      window.removeEventListener('application-updated', handleApplicationUpdate);
    };
  }, []);

  const renderProgressCircle = (score: number, goal: number, percentage: number) => {
    const radius = 70;
    const stroke = 8;
    const normalizedRadius = radius - stroke / 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    return (
      <svg
        height={radius * 2}
        width={radius * 2}
        viewBox={`0 0 ${radius * 2} ${radius * 2}`}
        className="progress-circle"
      >
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%">
            <stop offset="0%" stopColor="#43CC48" />
            <stop offset="100%" stopColor="#216624" />
          </linearGradient>
        </defs>
        {/* Inner grey ring */}
        <circle
          stroke="#F3F4F6"
          fill="transparent"
          strokeWidth="1"
          r={normalizedRadius - stroke/2 - 1}
          cx={radius}
          cy={radius}
        />
        <g transform={`rotate(90, ${radius}, ${radius})`}>
          {/* Main progress circle */}
          <circle
            stroke="url(#gradient)"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={strokeDashoffset}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
            style={{ transition: "stroke-dashoffset 0.35s ease-in-out" }}
            strokeLinecap="round"
          />
        </g>
        <g transform={`translate(${radius}, ${radius})`}>
          <text
            x="0"
            y="-12"
            textAnchor="middle"
            dominantBaseline="central"
            fontSize="30px"
            fontWeight="bold"
            fill="#333333"
          >
            {goal}
          </text>
          <text
            x="0"
            y="16"
            textAnchor="middle"
            dominantBaseline="central"
            fontSize="14px"
            fill="#A2A2A2"
          >
            Goal
          </text>
        </g>
      </svg>
    );
  };

  const handleJobSelection = (job: JobData) => {
    setSelection(job);
  };

  const renderJobSection = () => {
    if (error) {
      return <div className="error-message">{error}</div>;
    }

    if (jobResults.length === 0 && !error) {
      return (
        <div className="job-tiles-container">
          <div className="no-jobs-message">
            No matching jobs found at the moment. Please check back later or adjust your preferences.
          </div>
        </div>
      );
    }

    return (
      <div className="job-tiles-container">
        {jobResults.map((job, index) => (
          <JobTile 
            key={index} 
            job={job} 
            userId={user?._id} 
            handleSelection={handleJobSelection}
          />
        ))}
      </div>
    );
  };

  const renderMetricChange = (change: { percentage: number; trend: string } | undefined) => {
    if (!change) {
      return (
        <p className="stat-goal neutral">
          <span className="dash-placeholder">-</span>
        </p>
      );
    }
    
    const { percentage, trend } = change;
    
    if (trend === 'neutral') {
      return (
        <p className="stat-goal neutral">
          <span className="dash-placeholder">-</span>
        </p>
      );
    }
    
    return (
      <p className={`stat-goal ${trend}`}>
        {trend === 'increase' ? (
          <FaArrowUp className="arrow-icon" />
        ) : (
          <FaArrowDown className="arrow-icon" />
        )}{' '}
        {percentage}%
      </p>
    );
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard">
        <header className="dashboard-header">
          {user ? (
            <h1 className="greeting">{greeting}, {user.firstName}!</h1>
          ) : (
            <h1 className="greeting">{greeting}!</h1>
          )}
        </header>

        <WelcomeModal 
          isOpen={showWelcomeModal} 
          onClose={handleWelcomeModalClose}
          onAfterClose={refreshDashboardData}
        />

        <div className="job-tiles-container">
          {metricsLoading ? (
            <>
              <MetricSkeleton />
              <MetricSkeleton />
              <MetricSkeleton />
            </>
          ) : (
            <>
              {/* Weekly Goal */}
              <div className="job-tile stat">
                <div className="stat-text">
                  <h2>Weekly Goal</h2>
                  <div className="weekly-goal-content">
                    <div className="weekly-metric-section">
                      <div className="stat-number-container">
                        <p className="stat-number">
                          <strong>{weeklyApplications}</strong>
                        </p>
                      </div>
                      <p className="stat-label weekly-label">jobs applied</p>
                    </div>
                    <div className="goal-visualization">
                      {renderProgressCircle(
                        weeklyApplications,
                        user?.applicationGoal || 50,
                        (weeklyApplications / (user?.applicationGoal || 50)) * 100
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Applications Applied */}
              <div className="job-tile stat">
                <div className="stat-text">
                  <h2>Applications Applied</h2>
                  <div className="stat-number-container">
                    <p className="stat-number">
                      <strong>{user?.totalApplications || 0}</strong>
                    </p>
                    {renderMetricChange(metrics?.applicationsChange)}
                  </div>
                  <p className="stat-label">compared to last week</p>
                </div>
              </div>

              {/* Companies Responded */}
              <div className="job-tile stat">
                <div className="stat-text">
                  <h2>Companies Responded</h2>
                  <div className="stat-number-container">
                    <p className="stat-number">
                      <strong>{metrics?.totalResponses || 0}</strong>
                    </p>
                    {renderMetricChange(metrics?.responsesChange)}
                  </div>
                  <p className="stat-label">compared to last week</p>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="job-matches-header">
          <h2>Job Matches</h2>
          <Link to="/job-search" className="view-all-jobs">
            View All<span className="arrow"><FaChevronRight /></span>
          </Link>
        </div>

        <div className="job-tiles-container">
          {jobsLoading ? (
            <>
              {[...Array(6)].map((_, index) => (
                <div key={index} className="job-tile skeleton" />
              ))}
            </>
          ) : jobResults.length > 0 ? (
            jobResults.map((job, index) => (
              <JobTile 
                key={job._id || index} 
                job={job} 
                userId={user?._id} 
                handleSelection={handleJobSelection}
                isApplied={appliedJobs.has(job._id)}
                onApplicationUpdate={refreshAppliedJobs}
              />
            ))
          ) : (
            <div className="no-jobs-message">
              No matching jobs found at the moment. Please check back later or adjust your preferences.
            </div>
          )}
        </div>

        {selection && (
          <JobDetailsModal
            job={selection}
            isOpen={Boolean(selection._id)}
            onClose={() => setSelection(null)}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;