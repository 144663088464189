"use client"

import { ColumnDef } from "@tanstack/react-table"

export type Interview = {
  id: string
  jobTitle: string
  language: string
  duration: number
  status: "completed" | "scheduled" | "cancelled"
  date: string
}

export const columns: ColumnDef<Interview>[] = [
  {
    accessorKey: "jobTitle",
    header: "Job Title",
  },
  {
    accessorKey: "language",
    header: "Language",
  },
  {
    accessorKey: "duration",
    header: "Duration",
    cell: ({ row }) => {
      return <div>{row.getValue("duration")} min</div>
    },
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const status = row.getValue("status") as string
      return (
        <div className={`capitalize ${
          status === "completed" ? "text-green-600" :
          status === "scheduled" ? "text-blue-600" :
          "text-red-600"
        }`}>
          {status}
        </div>
      )
    },
  },
  {
    accessorKey: "date",
    header: "Date",
    cell: ({ row }) => {
      const date = new Date(row.getValue("date"))
      return <div>{date.toLocaleDateString()}</div>
    },
  },
]
