import axios, { AxiosHeaders } from "axios";
import { mainUrl } from "./constant";
import { jwtDecode } from 'jwt-decode';

const axiosClient = axios.create({
  baseURL: mainUrl,
  timeout: 30000,
  withCredentials: false,
  headers: new AxiosHeaders({
    'Content-Type': 'application/json'
  })
});

// Check if the token is expired
const isTokenExpired = (token: string): boolean => {
  try {
    const decoded: any = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return decoded.exp < currentTime;
  } catch (error) {
    return true;
  }
};

// Create a function to refresh the token
const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  
  if (!refreshToken) {
    throw new Error('No refresh token available');
  }
  
  try {
    const response = await axios.post(`${mainUrl}auth/refresh-tokens`, {
      refreshToken,
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    const { accessToken, refreshToken: newRefreshToken } = response.data;
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", newRefreshToken);
    
    return accessToken;
  } catch (error) {
    console.error("Failed to refresh token:", error);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href = '/auth/login';
    throw error;
  }
};

// Request interceptor
axiosClient.interceptors.request.use(async (config) => {
  let token = localStorage.getItem("accessToken");
  
  // Ensure headers exist and are properly typed
  if (!config.headers) {
    config.headers = new AxiosHeaders({
      'Content-Type': 'application/json'
    });
  }

  if (token) {
    // Always set the token if we have one
    config.headers.set('Authorization', `Bearer ${token}`);
    
    // Check if token is expired
    if (isTokenExpired(token)) {
      try {
        token = await refreshAccessToken();
        config.headers.set('Authorization', `Bearer ${token}`);
      } catch (error) {
        console.error("Token refresh failed:", error);
        // Don't throw, just continue with expired token
      }
    }
  }
  
  return config;
}, (error) => {
  console.error("Request interceptor error:", error);
  return Promise.reject(error);
});

// Response interceptor
axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = await refreshAccessToken();
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return axiosClient(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed in response interceptor:", refreshError);
        return Promise.reject(refreshError);
      }
    }
    
    return Promise.reject(error);
  }
);

// Add request logging
axiosClient.interceptors.request.use(request => {
  return request;
});

// Add response logging
axiosClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.error('Request failed:', {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data
    });
    return Promise.reject(error);
  }
);

export default axiosClient;