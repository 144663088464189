import React, { useEffect, useState, useRef, useCallback } from "react";
import "../../styles/components/landing-pages/jobPage.scss";
import Navbar from "./Navbar";
import ResumeHighlight from "../../animations/components/ResumeHighlight";
import feature2 from "../../assets/feature2.png";
import feature3 from "../../assets/feature3.png";
import feature4 from "../../assets/feature4.png";
import Footer from "./Footer";
import jobDescriptionIcon from "../../assets/job-details.svg";
import jobTile from "../../assets/job-tile.svg";
import { Link } from "react-router-dom";
import JobTile from "../../animations/components/JobTile";
import NewJobBenefits from "../../animations/components/NewJobBenefits";

const OutreachPage = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const demoSectionRef = useRef<HTMLDivElement | null>(null);
  const heroSectionRef = useRef<HTMLElement | null>(null);
  const [demoSectionDimensions, setDemoSectionDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [heroHeight, setHeroHeight] = useState(0);
  const NAVBAR_HEIGHT = 88;
  const [isCaptionVisible, setIsCaptionVisible] = useState(false);
  const [hasExpanded, setHasExpanded] = useState(false);
  const [activeGroup, setActiveGroup] = useState<'days5' | 'days10' | 'days15' | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activeFaqIndex, setActiveFaqIndex] = useState<number | null>(null);

  // Get dimensions after component mounts
  useEffect(() => {
    if (demoSectionRef.current) {
      const rect = demoSectionRef.current.getBoundingClientRect();
      setDemoSectionDimensions({
        width: rect.width,
        height: rect.height,
      });
    }
    if (heroSectionRef.current) {
      const rect = heroSectionRef.current.getBoundingClientRect();
      setHeroHeight(rect.height);
    }
  }, []);

  // Handle scroll
  useEffect(() => {
    const handleScroll = () => {
      if (demoSectionRef.current) {
        const rect = demoSectionRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        
        const startScroll = rect.top - viewportHeight * 1.8;
        const endScroll = rect.top + (viewportHeight * 0.8);
        const scrollRange = endScroll - startScroll;
        
        const currentScroll = window.scrollY - startScroll;
        const progress = Math.max(0, Math.min(1, currentScroll / scrollRange));
        
        setScrollProgress(progress);
        
        if (progress >= 1 && !hasExpanded) {
          setHasExpanded(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    requestAnimationFrame(handleScroll);
    
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasExpanded]);

  // Calculate transforms
  const progress = scrollProgress || 0;

  useEffect(() => {
    if (progress >= 1 && !isCaptionVisible) {
      setIsCaptionVisible(true);
    }
  }, [progress]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Set up intersection observer for feature animations
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Add animate class immediately
          entry.target.classList.add('animate');
          
          // Force a reflow
          if (entry.target instanceof HTMLElement) {
            void entry.target.offsetHeight;
          }
          
          if (entry.isIntersecting) {
            // Add visible class after a small delay to ensure animation triggers
            requestAnimationFrame(() => {
              entry.target.classList.add('visible');
            });
            observer.unobserve(entry.target); // Only animate once
          }
        });
      },
      {
        root: null,
        threshold: 0.2, // Start animation when 20% of element is visible
        rootMargin: '0px'
      }
    );

    // Observe all feature rows
    document.querySelectorAll('.feature-row').forEach((feature) => {
      observer.observe(feature);
    });

    return () => observer.disconnect();
  }, []);

  const handleFaqClick = (index: number) => {
    setActiveFaqIndex(activeFaqIndex === index ? null : index);
  };

  // Handle entrance animations for FAQs
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.target.classList.add('animate');
          
          if (entry.target instanceof HTMLElement) {
            void entry.target.offsetHeight;
          }
          
          if (entry.isIntersecting) {
            requestAnimationFrame(() => {
              entry.target.classList.add('visible');
            });
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        threshold: 0.2,
        rootMargin: '0px'
      }
    );

    document.querySelectorAll('.faq-item').forEach((item) => {
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <Navbar />
      <div className="job-page-container">
        <section
          ref={heroSectionRef}
          className="job-features-hero-section animate"
        >
          <div className="hero-content">
            <h1 className="hero-heading animate">
              Outreach AI
            </h1>
            <p className="hero-subheading animate">
              Connect with employers and grow your professional network with our intelligent 
              outreach assistant. Get personalized guidance for every interaction.
            </p>
            <Link to="/auth/signup" className="cta-btn animate">
              Get Started - It's Free
            </Link>
          </div>
          <div className="hero-image-container">
            <div className="resume-highlight-container">
              <ResumeHighlight />
            </div>
          </div>
        </section>

        <section className="job-features-section">
          <div className="feature-row">
            <div className="feature-content">
              <h2>Smart Networking Assistant</h2>
              <p>
                Get personalized suggestions for connecting with employers and industry professionals. 
                Our AI helps you craft the perfect outreach messages.
              </p>
            </div>
            <div className="feature-image">
              <NewJobBenefits />
            </div>
          </div>

          <div className="feature-row">
            <div className="feature-image">
              <img src={feature2} alt="Templates feature" />
            </div>
            <div className="feature-content">
              <h2>Message Templates</h2>
              <p>
                Access professionally crafted templates for different networking scenarios, 
                from cold outreach to follow-ups.
              </p>
            </div>
          </div>

          <div className="feature-row">
            <div className="feature-content">
              <h2>Relationship Management</h2>
              <p>
                Keep track of your professional connections and get reminders for 
                follow-ups to maintain and grow your network.
              </p>
            </div>
            <div className="feature-image">
              <JobTile />
            </div>
          </div>

          <div className="feature-row">
            <div className="feature-image">
              <img src={feature4} alt="Analytics feature" />
            </div>
            <div className="feature-content">
              <h2>Outreach Analytics</h2>
              <p>
                Monitor your networking effectiveness with detailed analytics on 
                your outreach activities and response rates.
              </p>
            </div>
          </div>
        </section>

        <section className="faq-section">
          <div className="faq-container">
            <h2 className="faq-heading">Frequently Asked Questions</h2>
            <div className="faq-grid">
              {[
                {
                  question: "How does AI assist with outreach?",
                  answer: "Our AI helps craft personalized outreach messages by analyzing company culture, job requirements, and your background to create engaging, professional communications that stand out."
                },
                {
                  question: "Can I customize the outreach templates?",
                  answer: "Yes! While our AI provides optimized templates, you can easily customize any message to add your personal touch and specific details about your interest in the role."
                },
                {
                  question: "What types of outreach does it support?",
                  answer: "Our platform supports various outreach types including cold outreach, follow-ups, thank you notes, and networking messages, each optimized for different scenarios."
                },
                {
                  question: "How personalized are the messages?",
                  answer: "Our AI creates highly personalized messages by incorporating specific details about the company, role, and your background, ensuring each message feels authentic and relevant."
                },
                {
                  question: "Can I track outreach effectiveness?",
                  answer: "Yes, our platform includes analytics to track response rates, engagement, and success metrics for your outreach campaigns, helping you optimize your approach."
                },
                {
                  question: "Is there a limit to outreach messages?",
                  answer: "No, you can create unlimited outreach messages. Our platform helps you manage and organize all your communications in one place."
                }
              ].map((faq, index) => (
                <div 
                  key={index}
                  className={`faq-item ${activeFaqIndex === index ? 'active' : ''}`}
                >
                  <div 
                    className="faq-question"
                    onClick={() => handleFaqClick(index)}
                  >
                    <h3>{faq.question}</h3>
                    <span className="faq-icon">+</span>
                  </div>
                  <div className="faq-answer">
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default OutreachPage; 