import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Draggable, DropResult, DroppableProvided, DroppableStateSnapshot, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import JobTile from '../JobTile';
import { StrictModeDroppable } from '../StrictModeDroppable';
import styles from '../../styles/components/applicationTracker.module.scss';
import { getApplications, Application as UserApplication, updateApplicationStatus } from '../../services/applicationService';
import { fetchJobById } from '../../services/jobService';
import Sidebar from '../Sidebar';
import JobDetailsModal from './JobDetailsModal';
import { JobButtonContext } from '../../constant';

interface JobData {
  _id: string;
  title: string;
  company: string;
  location: string;
  type: string;
  description: string;
  requirements: string[];
  salary?: string;
  posted: string;
  deadline?: string;
  logo?: string;
  days_to_live: any;
  jobType: string;
  _preserveLogoState?: boolean;
  status?: 'applied' | 'interviewing' | 'offer';
  appliedDate?: string;
  lastStatusUpdate?: string;
}

interface ApplicationWithJob {
  id: string;
  job: JobData;
  status: 'applied' | 'interviewing' | 'offer';
  appliedDate: string;
  lastStatusUpdate?: string;
}

interface Column {
  id: string;
  title: string;
  items: ApplicationWithJob[];
}

interface BoardState {
  columns: {
    [key: string]: Column;
  };
}

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const ApplicationTracker: React.FC = () => {
  const navigate = useNavigate();
  const [boardState, setBoardState] = useState<BoardState>({
    columns: {
      applied: {
        id: 'applied',
        title: 'Applied',
        items: []
      },
      interviewing: {
        id: 'interviewing',
        title: 'Interviewing',
        items: []
      },
      offer: {
        id: 'offer',
        title: 'Offer',
        items: []
      }
    }
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>(null);
  const [selection, setSelection] = useState<JobData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const initializeData = async () => {
      try {
        const userData = localStorage.getItem('user');
        if (!userData) {
          navigate('/auth/login');
          return;
        }

        const user = JSON.parse(userData);
        if (!user._id) {
          navigate('/auth/login');
          return;
        }

        setUser(user);
        await fetchApplications();
      } catch (error) {
        setError('Failed to initialize application tracker.');
      } finally {
        setLoading(false);
      }
    };

    initializeData();
  }, [navigate]);

  const handleJobSelection = (job: JobData) => {
    const selectedApplication = boardState.columns[job.status || 'applied'].items.find(
      item => item.job._id === job._id
    );
    setSelection({
      ...job,
      status: selectedApplication?.status,
      appliedDate: selectedApplication?.appliedDate,
      lastStatusUpdate: selectedApplication?.lastStatusUpdate
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelection(null);
  };

  const fetchApplications = async () => {
    try {
      const userData = localStorage.getItem('user');
      if (!userData) {
        navigate('/auth/login');
        return;
      }

      const user = JSON.parse(userData);
      if (!user._id) {
        navigate('/auth/login');
        return;
      }

      // Fetch user's applications
      const applications = await getApplications();
      console.log('Fetched applications:', applications);
      
      // Fetch complete job details for each application
      const applicationPromises = applications.map(async (application: UserApplication) => {
        try {
          console.log('Fetching job details for ID:', application.jobId);
          const jobDetails = await fetchJobById(application.jobId);
          console.log('Received job details:', jobDetails);
          return {
            id: `job-${application.jobId}`,
            job: jobDetails,
            status: application.status,
            appliedDate: application.appliedDate
          } as ApplicationWithJob;
        } catch (error) {
          console.error(`Error fetching job details for ${application.jobId}:`, error);
          return null;
        }
      });

      const resolvedApplications = (await Promise.all(applicationPromises)).filter((app: ApplicationWithJob | null): app is ApplicationWithJob => app !== null);
      console.log('Final resolved applications:', resolvedApplications);
      
      // Group applications by status
      const newState: BoardState = {
        columns: {
          applied: {
            id: 'applied',
            title: 'Applied',
            items: resolvedApplications.filter((app: ApplicationWithJob) => app.status === 'applied')
          },
          interviewing: {
            id: 'interviewing',
            title: 'Interviewing',
            items: resolvedApplications.filter((app: ApplicationWithJob) => app.status === 'interviewing')
          },
          offer: {
            id: 'offer',
            title: 'Offer',
            items: resolvedApplications.filter((app: ApplicationWithJob) => app.status === 'offer')
          }
        }
      };
      console.log('Final board state:', newState);

      setBoardState(newState);
      setError(null);
    } catch (err) {
      console.error('Error fetching applications:', err);
      setError('Failed to fetch applications');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  const updateStatus = async (jobId: string, status: 'applied' | 'interviewing' | 'offer', retries = 0): Promise<void> => {
    try {
      await updateApplicationStatus(jobId, status);
    } catch (error) {
      console.error('Error updating status:', error);
      if (retries < MAX_RETRIES) {
        await sleep(RETRY_DELAY);
        await updateStatus(jobId, status, retries + 1);
      }
    }
  };

  const onDragEnd = useCallback((result: DropResult) => {
    if (!result.destination) return;

    const { source, destination } = result;
    
    if (source.droppableId === destination.droppableId && 
        source.index === destination.index) {
      return;
    }

    setBoardState(prevState => {
      const sourceColumn = prevState.columns[source.droppableId];
      const destColumn = prevState.columns[destination.droppableId];
      
      const sourceItems = [...sourceColumn.items];
      const destItems = source.droppableId === destination.droppableId 
        ? sourceItems 
        : [...destColumn.items];
      
      const [removed] = sourceItems.splice(source.index, 1);
      
      // Preserve the job data during status change
      const updatedApplication: ApplicationWithJob = {
        ...removed,
        status: destination.droppableId as 'applied' | 'interviewing' | 'offer',
        job: {
          ...removed.job,
          _preserveLogoState: true
        }
      };
      
      destItems.splice(destination.index, 0, updatedApplication);

      // Extract jobId from the application id (remove 'job-' prefix)
      const jobId = removed.id.replace('job-', '');
      
      // Silently update the status in the background
      updateStatus(jobId, destination.droppableId as 'applied' | 'interviewing' | 'offer');

      return {
        columns: {
          ...prevState.columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems
          }
        }
      };
    });
  }, []);

  const renderColumn = useCallback((columnId: string) => {
    const column = boardState.columns[columnId];
    
    return (
      <div className={styles.kanbanColumn} key={columnId}>
        <h2>
          {column.title}
          <span className={styles.count}>{column.items.length}</span>
        </h2>
        <StrictModeDroppable droppableId={columnId}>
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`${styles.columnContent} ${snapshot.isDraggingOver ? styles.draggingOver : ''}`}
            >
              {column.items.map((application, index) => (
                <Draggable
                  key={application.id}
                  draggableId={application.id}
                  index={index}
                >
                  {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`${styles.draggableContainer} ${snapshot.isDragging ? styles.dragging : ''}`}
                      style={provided.draggableProps.style}
                    >
                      <JobTile
                        key={application.job._id}
                        job={application.job}
                        userId={user._id}
                        handleSelection={handleJobSelection}
                        context={JobButtonContext.APPLICATION_TRACKER}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </div>
    );
  }, [boardState, user]);

  if (loading) {
    return (
      <div className={styles.appTrackerContainer}>
        <Sidebar />
        <div className={styles.appTrackerMain}>
          <header className={styles.dashboardHeader}>
            <div className={`${styles.greeting} ${styles.skeleton}`}></div>
          </header>
          <div className={styles.kanbanBoard}>
            <div className={styles.appTrackerTilesContainer}>
              {['applied', 'interviewing', 'offer'].map((columnId) => (
                <div key={columnId} className={styles.kanbanColumn}>
                  <h2 className={styles.skeleton}></h2>
                  <div className={styles.columnContent}>
                    {[1, 2, 3].map((i) => (
                      <div key={i} className={`${styles.jobTileSkeleton} ${styles.skeleton}`}></div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const totalApplications = Object.values(boardState.columns).reduce(
    (total, column) => total + column.items.length,
    0
  );

  if (error) {
    return (
      <div className={styles.appTrackerContainer}>
        <Sidebar />
        <div className={styles.appTrackerMain}>
          <div className={styles.error}>{error}</div>
        </div>
      </div>
    );
  }

  if (totalApplications === 0) {
    return (
      <div className={styles.appTrackerContainer}>
        <Sidebar />
        <div className={styles.appTrackerMain}>
          <header className={styles.dashboardHeader}>
            <h1 className={styles.greeting}>Application Tracker</h1>
          </header>
          <div className={styles.noApplications}>
            <h2>No applications yet</h2>
            <p>Start applying to jobs to track your applications here</p>
            <button onClick={() => navigate('/jobs')} className={styles.searchButton}>
              Search Jobs
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.appTrackerContainer}>
      <Sidebar />
      <div className={styles.appTrackerMain}>
        <header className={styles.dashboardHeader}>
          <h1 className={styles.greeting}>Application Tracker</h1>
        </header>
        <div className={styles.kanbanBoard}>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className={styles.appTrackerTilesContainer}>
              {renderColumn('applied')}
              {renderColumn('interviewing')}
              {renderColumn('offer')}
            </div>
          </DragDropContext>
        </div>
      </div>
      {selection && (
        <JobDetailsModal
          job={selection}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onStatusChange={(newStatus) => {
            // Handle status change
            console.log('Status changed to:', newStatus);
          }}
        />
      )}
    </div>
  );
};

export default ApplicationTracker;