import React, { createContext, useContext, useState } from 'react';

interface ModalData {
  showModal: boolean;
  jobTitle: string;
  companyName: string;
  jobId: string;
}

interface ApplicationModalContextType {
  showModal: boolean;
  jobTitle: string;
  companyName: string;
  jobId: string;
  setModalData: (data: Partial<ModalData>) => void;
  onConfirm: (didApply: boolean) => void;
}

const ApplicationModalContext = createContext<ApplicationModalContextType | undefined>(undefined);

export const ApplicationModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [modalData, setModalData] = useState<ModalData>({
    showModal: false,
    jobTitle: '',
    companyName: '',
    jobId: ''
  });

  const handleModalDataUpdate = (data: Partial<ModalData>) => {
    setModalData(prev => ({
      ...prev,
      ...data
    }));
  };

  const handleConfirm = (didApply: boolean) => {
    handleModalDataUpdate({ 
      showModal: false,
      jobTitle: '',
      companyName: '',
      jobId: ''
    });
  };

  return (
    <ApplicationModalContext.Provider
      value={{
        showModal: modalData.showModal,
        jobTitle: modalData.jobTitle,
        companyName: modalData.companyName,
        jobId: modalData.jobId,
        setModalData: handleModalDataUpdate,
        onConfirm: handleConfirm,
      }}
    >
      {children}
    </ApplicationModalContext.Provider>
  );
};

export const useApplicationModal = () => {
  const context = useContext(ApplicationModalContext);
  if (context === undefined) {
    throw new Error('useApplicationModal must be used within an ApplicationModalProvider');
  }
  return context;
}; 