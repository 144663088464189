import React, { useState, useEffect, useRef } from 'react';
import styles from '../styles/JobProcess.module.scss';
import appleTile from '../assets/tiles/apple.svg';
import doordashTile from '../assets/tiles/doordash.svg';
import googleTile from '../assets/tiles/google.svg';
import netflixTile from '../assets/tiles/netflix.svg';
import spotifyTile from '../assets/tiles/spotify.svg';
import teslaTile from '../assets/tiles/tesla.svg';
import nvidiaTitle from '../assets/tiles/nvidia.svg';
import redditTile from '../assets/tiles/reddit.svg';
import datadogTile from '../assets/tiles/datadog.svg';
import walmartTile from '../assets/tiles/walmart.svg';
import grammarly from '../assets/tiles/grammarly.svg';
import lyftTile from '../assets/tiles/lyft.svg';
import salesforceTile from '../assets/tiles/salesforce.svg';
import amexTile from '../assets/tiles/amex.svg';
import scaleTile from '../assets/tiles/scale.svg';
import spacexTile from '../assets/tiles/spacex.svg';
import jobDetailsModal from '../../assets/job-details.svg';
import resumeModal from '../../assets/resume.svg';
import coverLetterModal from '../../assets/cover-letter.svg';
import cursor from '../assets/cursor.svg';
import companyLogo from '../assets/companyLogo.svg';

interface JobProcessProps {
  onStateChange?: (state: {
    phase: 'all' | 'few' | 'many';
    currentView: 'jobDetails' | 'resume' | 'coverLetter' | 'logo';
    showModal: boolean;
  }) => void;
}

const JobProcess: React.FC<JobProcessProps> = ({ onStateChange }) => {
  const [selectedTiles, setSelectedTiles] = useState<number[]>([]);
  const [phase, setPhase] = useState<'all' | 'few' | 'many'>('all');
  const [showModal, setShowModal] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const [modalEntering, setModalEntering] = useState(false);
  const [currentView, setCurrentView] = useState<'jobDetails' | 'resume' | 'coverLetter' | 'logo'>('jobDetails');
  const [cursorPosition, setCursorPosition] = useState({ x: -100, y: -100 });
  const [showCursor, setShowCursor] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [modalFading, setModalFading] = useState(false);
  const timeoutRefs = useRef<NodeJS.Timeout[]>([]);
  const selectedTileRef = useRef<HTMLDivElement>(null);

  // Function to shuffle all tiles including Google
  const shuffleTiles = () => {
    const allTiles = [
      appleTile, netflixTile, spotifyTile, teslaTile,
      nvidiaTitle, redditTile, datadogTile, walmartTile,
      grammarly, lyftTile, salesforceTile, amexTile,
      scaleTile, spacexTile, doordashTile, googleTile
    ];

    // Fisher-Yates shuffle algorithm for true randomness
    for (let i = allTiles.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [allTiles[i], allTiles[j]] = [allTiles[j], allTiles[i]];
    }

    return allTiles;
  };

  // State for tile pattern
  const [tilePattern, setTilePattern] = useState(shuffleTiles());

  // Find indices of Google tiles
  const googleTileIndices = tilePattern.reduce((indices: number[], tile, index) => {
    if (tile === googleTile) indices.push(index);
    return indices;
  }, []);

  // Notify parent of state changes
  useEffect(() => {
    onStateChange?.({ phase, currentView, showModal });
  }, [phase, currentView, showModal, onStateChange]);

  useEffect(() => {
    const runAnimation = () => {
      timeoutRefs.current.forEach(clearTimeout);
      timeoutRefs.current = [];

      // Reset states and shuffle tiles
      setPhase('all');
      setSelectedTiles([]);
      setIsVisible(true);
      setCurrentView('jobDetails');
      setShowCursor(false);
      setCursorPosition({ x: -100, y: -100 });
      const newTilePattern = shuffleTiles();
      setTilePattern(newTilePattern);

      // Store Google's index for later use
      const googleIndex = newTilePattern.findIndex(tile => tile === googleTile);

      // After 2s, select few tiles
      const fewTimeout = setTimeout(() => {
        setShouldAnimate(false);
        setPhase('few');
        const fewSelected: number[] = [];
        
        // Select 4 random tiles (may or may not include Google)
        while (fewSelected.length < 4) {
          const randomIndex = Math.floor(Math.random() * 16);
          if (!fewSelected.includes(randomIndex)) {
            fewSelected.push(randomIndex);
          }
        }
        setSelectedTiles(fewSelected.sort((a, b) => a - b));
      }, 2000);
      timeoutRefs.current.push(fewTimeout);

      // After 4s, select many tiles, ALWAYS including Google
      const manyTimeout = setTimeout(() => {
        setPhase('many');
        const manySelected = new Set<number>();
        
        // First, add Google's index
        manySelected.add(googleIndex);
        
        // Then add random tiles until we have 10 total
        while (manySelected.size < 10) {
          const randomIndex = Math.floor(Math.random() * 16);
          manySelected.add(randomIndex);
        }
        
        setSelectedTiles(Array.from(manySelected).sort((a, b) => a - b));
      }, 4000);
      timeoutRefs.current.push(manyTimeout);

      // After 6s, start cursor animation
      const cursorTimeout = setTimeout(() => {
        // Find the selected Google tile element
        const selectedGoogleTile = document.querySelector(
          `[data-tile-type="google"].${styles.selected}`
        ) as HTMLElement;
        
        if (selectedGoogleTile) {
          const rect = selectedGoogleTile.getBoundingClientRect();
          
          // Set initial cursor position at left edge, same height as tile
          setCursorPosition({ 
            x: -50, 
            y: rect.top + (rect.height / 2)
          });
          setShowCursor(true);

          // Move to selected tile
          setTimeout(() => {
            setCursorPosition({ 
              x: rect.left + (rect.width / 2), 
              y: rect.top + (rect.height / 2)
            });

            // Click after arriving
            setTimeout(() => {
              const cursorElement = document.querySelector(`.${styles.cursor}`);
              if (cursorElement) {
                cursorElement.classList.add(styles.clicking);
              }

              // Start fade out sequence
              setTimeout(() => {
                setShowCursor(false);
                setIsVisible(false);
                
                // Show modal after fade out
                setTimeout(() => {
                  setShowModal(true);
                  setModalEntering(true);

                  // Wait for modal to be fully visible
                  setTimeout(() => {
                    const modalImg = document.querySelector(
                      `.${styles.jobDetailsModal} img`
                    ) as HTMLElement;

                    if (modalImg) {
                      const rect = modalImg.getBoundingClientRect();
                      
                      // Calculate position for "Tailor My Resume" button
                      const svgWidth = 1624 - 1454;  // Width of text in SVG
                      const modalWidth = rect.width;
                      const ratio = modalWidth / 1624; // Scale ratio
                      
                      const buttonX = rect.left + (1454 * ratio); // Starting X of text
                      const buttonY = rect.top + (211 * ratio);   // Middle Y of text

                      // Show cursor at current position and move directly to button
                      setShowCursor(true);
                      setCursorPosition({
                        x: buttonX,
                        y: buttonY
                      });

                      // Click animation after arriving
                      setTimeout(() => {
                        const cursorElement = document.querySelector(`.${styles.cursor}`);
                        if (cursorElement) {
                          cursorElement.classList.add(styles.clicking);
                        }

                        // After click, transition to resume
                        setTimeout(() => {
                          setShowCursor(false);
                          setCurrentView('resume');

                          // Wait longer for resume to be visible
                          setTimeout(() => {
                            const resumeImg = document.querySelector(
                              `.${styles.jobDetailsModal} img`
                            ) as HTMLElement;

                            if (resumeImg) {
                              const rect = resumeImg.getBoundingClientRect();
                              
                              // SVG viewbox is 1624x1624, button is at bottom right
                              const modalWidth = rect.width;
                              const modalHeight = rect.height;
                              
                              // Calculate button position - button is at bottom right
                              const buttonX = rect.right - ((170 / 1624) * modalWidth); // Offset from right edge
                              const buttonY = rect.bottom - ((100 / 1624) * modalHeight); // Increased from 85 to 95 to move cursor slightly higher

                              // Show cursor directly on button
                              setShowCursor(true);
                              setCursorPosition({
                                x: buttonX,
                                y: buttonY
                              });

                              // Wait longer before clicking
                              setTimeout(() => {
                                const cursorElement = document.querySelector(`.${styles.cursor}`);
                                if (cursorElement) {
                                  cursorElement.classList.add(styles.clicking);
                                }

                                // Longer delay before transitioning to cover letter
                                setTimeout(() => {
                                  // Remove clicking class first
                                  const cursorElement = document.querySelector(`.${styles.cursor}`);
                                  if (cursorElement) {
                                    cursorElement.classList.remove(styles.clicking);
                                  }
                                  
                                  // Wait a bit before hiding cursor and transitioning
                                  setTimeout(() => {
                                    setShowCursor(false);
                                    setCurrentView('coverLetter');

                                    // Add animation for clicking Apply Externally
                                    setTimeout(() => {
                                      const coverLetterImg = document.querySelector(
                                        `.${styles.jobDetailsModal} img`
                                      ) as HTMLElement;

                                      if (coverLetterImg) {
                                        const rect = coverLetterImg.getBoundingClientRect();
                                        const modalWidth = rect.width;
                                        const modalHeight = rect.height;
                                        
                                        // Position cursor on Apply Externally button
                                        // SVG coordinates: Apply Externally text starts at x=1220 y=969
                                        const buttonX = rect.left + ((1220 / 1728) * modalWidth);
                                        const buttonY = rect.top + ((969 / 1117) * modalHeight);

                                        setShowCursor(true);
                                        setCursorPosition({
                                          x: buttonX,
                                          y: buttonY
                                        });

                                        // Click after a delay
                                        setTimeout(() => {
                                          const cursorElement = document.querySelector(`.${styles.cursor}`);
                                          if (cursorElement) {
                                            cursorElement.classList.add(styles.clicking);
                                          }

                                          // Hide cursor after click and start fade out
                                          setTimeout(() => {
                                            setShowCursor(false);
                                            setModalFading(true);
                                            
                                            // After fade out, show logo
                                            setTimeout(() => {
                                              setModalFading(false);
                                              setCurrentView('logo');
                                              
                                              // After showing logo for 3 seconds, restart animation
                                              setTimeout(() => {
                                                setShowModal(false);
                                                setModalEntering(false);
                                                runAnimation();
                                              }, 3000);
                                            }, 500);
                                          }, 500);
                                        }, 1000);
                                      }
                                    }, 1500);
                                  }, 500);
                                }, 800);
                              }, 1500);
                            }
                          }, 1500);
                        }, 300);
                      }, 1000);
                    }
                  }, 1000);
                }, 400);
              }, 300);
            }, 1000);
          }, 800);
        }
      }, 6000);
      timeoutRefs.current.push(cursorTimeout);
    };

    runAnimation();

    return () => {
      timeoutRefs.current.forEach(clearTimeout);
    };
  }, []);

  const getModalImage = () => {
    switch (currentView) {
      case 'resume':
        return { src: resumeModal, alt: 'Resume' };
      case 'coverLetter':
        return { src: coverLetterModal, alt: 'Cover Letter' };
      default:
        return { src: jobDetailsModal, alt: 'Job Details' };
    }
  };

  return (
    <div className={`${styles.container} ${showModal ? styles.showingModal : ''}`}>
      <div className={`${styles.jobGrid} ${isVisible ? '' : styles.fadeOut}`}>
        {tilePattern.map((tile, index) => (
          <div
            key={index}
            className={`
              ${styles.tileWrapper}
              ${shouldAnimate ? styles.initialEnter : ''}
              ${selectedTiles.includes(index) ? styles.selected : ''}
              ${phase !== 'all' && !selectedTiles.includes(index) ? styles.unselected : ''}
              ${phase === 'many' && selectedTiles.includes(index) ? styles.manySelected : ''}
            `}
            data-tile-type={tile === googleTile ? 'google' : 'other'}
            ref={tile === googleTile ? selectedTileRef : null}
          >
            <img
              src={tile}
              alt="Job Tile"
              className={styles.jobTile}
            />
          </div>
        ))}
      </div>

      {showModal && (
        <div className={`${styles.jobDetailsModal} ${modalEntering ? styles.show : ''} ${modalFading ? styles.fadeOut : ''}`}>
          {currentView === 'logo' ? (
            <img
              src={companyLogo}
              alt="Company Logo"
              className={styles.companyLogo}
            />
          ) : (
            <img
              src={getModalImage().src}
              alt={getModalImage().alt}
            />
          )}
        </div>
      )}

      {showCursor && (
        <div
          className={`${styles.cursor} ${showCursor ? styles.show : ''}`}
          style={{
            transform: `translate(${cursorPosition.x}px, ${cursorPosition.y}px)`
          }}
        >
          <img src={cursor} alt="Cursor" />
        </div>
      )}
    </div>
  );
};

export default JobProcess; 