import { put, PutBlobResult } from '@vercel/blob';
import { LOGO_MAPPINGS, LogoVariant } from './logoMappings';

class BlobStorageService {
  private readonly BLOB_BASE_URL = 'https://qaloopkm7ji3ppwh.public.blob.vercel-storage.com/companies';

  async getBestLogoVariant(company: string): Promise<{ url: string; variant: LogoVariant } | null> {
    // Find exact match first
    let mapping = LOGO_MAPPINGS.find(m => m.companyName === company);
    
    // If no exact match, try case-insensitive match
    if (!mapping) {
      mapping = LOGO_MAPPINGS.find(
        m => m.companyName.toLowerCase() === company.toLowerCase()
      );
    }
    
    if (!mapping) {
      console.log(`No logos found for company: ${company}`);
      return null;
    }

    // Priority order
    const preferredOrder: LogoVariant[] = ['square', 'horizontal', 'vertical'];
    const variant = preferredOrder.find(v => mapping?.logos[v]) || 
                   Object.keys(mapping.logos)[0] as LogoVariant;
    
    if (!variant || !mapping.logos[variant]) {
      return null;
    }

    return {
      url: `${this.BLOB_BASE_URL}/${mapping.logos[variant]}`,
      variant
    };
  }

  async doesLogoExist(company: string, variant: LogoVariant): Promise<boolean> {
    const mapping = LOGO_MAPPINGS.find(
      m => m.companyName.toLowerCase() === company.toLowerCase()
    );
    return !!mapping?.logos[variant];
  }

  async getAvailableVariants(company: string): Promise<LogoVariant[]> {
    const mapping = LOGO_MAPPINGS.find(
      m => m.companyName.toLowerCase() === company.toLowerCase()
    );
    if (!mapping) return [];
    return Object.keys(mapping.logos) as LogoVariant[];
  }
}

export const blobStorageService = new BlobStorageService(); 