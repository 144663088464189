import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiUser, FiLogOut, FiMenu, FiGrid, FiChevronUp, FiSettings } from 'react-icons/fi';
import { ClipboardList, MessagesSquare, Briefcase, Upload, Sparkles, Wallet } from 'lucide-react';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import '../styles/components/sidebar.scss';

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [userData, setUserData] = useState<{ firstName: string; lastName: string; email: string } | null>(null);
  const profileRef = useRef<HTMLDivElement>(null);
  const [dropdownAnimation, setDropdownAnimation] = useState<'show' | 'hide' | ''>('');

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
        handleDropdownClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      setUserData({
        firstName: parsedUser.firstName || '',
        lastName: parsedUser.lastName || '',
        email: parsedUser.email || ''
      });
    }
  }, []);

  const handleLogout = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    navigate('/');
    window.location.reload()
  };

  const toggleSidebar = () => {
    if (isExpanded) {
      setIsHovered(false);
      setShowProfileDropdown(false);
    }
    setIsExpanded(!isExpanded);
  };

  const handleDropdownClose = () => {
    setDropdownAnimation('hide');
    setTimeout(() => {
      setShowProfileDropdown(false);
      setDropdownAnimation('');
    }, 200);
  };

  const toggleProfileDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (showProfileDropdown) {
      handleDropdownClose();
    } else {
      setShowProfileDropdown(true);
      setDropdownAnimation('show');
    }
  };

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  const getInitials = () => {
    if (!userData) return '';
    return `${userData.firstName.charAt(0)}${userData.lastName.charAt(0)}`.toUpperCase();
  };

  return (
    <div
      className={`sidebar ${isExpanded ? 'expanded' : ''} ${isHovered && !isExpanded ? 'hovered' : ''}`}
      onMouseEnter={() => !isExpanded && setIsHovered(true)}
      onMouseLeave={() => {
        if (!isExpanded) {
          setIsHovered(false);
          handleDropdownClose();
        }
      }}
    >
      <div className="sidebar-top">
        <div className="sidebar-toggle" onClick={toggleSidebar}>
          <FiMenu />
        </div>
        <div className={`sidebar-icon ${isActive('/dashboard') ? 'active' : ''}`} onClick={() => navigate('/dashboard')}>
          <FiGrid />
          <span className="icon-text">Dashboard</span>
        </div>
        <div className={`sidebar-icon ${isActive('/job-search') ? 'active' : ''}`} onClick={() => navigate('/job-search')}>
          <Briefcase className="lucide-icon" />
          <span className="icon-text">Job Search</span>
        </div>
        <div className={`sidebar-icon ${isActive('/application-tracker') ? 'active' : ''}`} onClick={() => navigate('/application-tracker')}>
          <ClipboardList className="lucide-icon" />
          <span className="icon-text">Application Tracker</span>
        </div>
        <div className={`sidebar-icon ${isActive('/bring-your-job') ? 'active' : ''}`} onClick={() => navigate('/bring-your-job')}>
          <Upload className="lucide-icon" />
          <span className="icon-text">Bring Your Own Job</span>
        </div>
      </div>
      <div className="sidebar-bottom">
        <div className="profile-section" ref={profileRef} onClick={toggleProfileDropdown}>
          <div className="profile-trigger">
            <div className="avatar-wrapper">
              <div className="avatar">{getInitials()}</div>
            </div>
            {(isExpanded || isHovered) && (
              <>
                <div className="user-info">
                  <span className="user-name">{userData?.firstName} {userData?.lastName}</span>
                  <span className="user-email">{userData?.email}</span>
                </div>
                <FiChevronUp className={`trigger-icon ${showProfileDropdown ? 'rotated' : ''}`} />
              </>
            )}
          </div>
          {showProfileDropdown && (
            <div className={`profile-dropdown ${dropdownAnimation}`}>
              <div className="dropdown-section">
                <div className="dropdown-item" onClick={() => navigate('/account-info')}>
                  <FiUser />
                  <span>Profile</span>
                </div>
                {/* Billing option commented out until Stripe integration
                <div className="dropdown-item" onClick={() => navigate('/billing')}>
                  <Wallet className="lucide-icon" />
                  <span>Billing</span>
                </div>
                */}
                {/* <div className="dropdown-item" onClick={() => navigate('/pricing')}>
                  <Sparkles className="lucide-icon" />
                  <span>Manage Plan</span>
                </div> */}
              </div>
              <div className="dropdown-section">
                <div className="dropdown-item" onClick={handleLogout}>
                  <FiLogOut />
                  <span>Log out</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
