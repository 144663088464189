import React, { useState, useEffect } from 'react';
import { LOGO_MAPPINGS } from '../services/logoMappings';
import { blobStorageService } from '../services/blobStorage.service';

interface CompanyLogoProps {
  companyName: string;
  className?: string;
}

const getInitials = (companyName: string): string => {
  const words = companyName.split(' ');
  if (words.length === 1) return words[0].charAt(0).toUpperCase();
  return (words[0].charAt(0) + words[1].charAt(0)).toUpperCase();
};

const generatePastelColor = (companyName: string): string => {
  let hash = 0;
  for (let i = 0; i < companyName.length; i++) {
    hash = companyName.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  // Generate pastel color by ensuring high lightness
  const h = hash % 360;
  return `hsl(${h}, 70%, 85%)`;
};

const CompanyLogo: React.FC<CompanyLogoProps> = ({ companyName, className = '' }) => {
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [logoVariant, setLogoVariant] = useState<'horizontal' | 'square' | 'vertical' | null>(null);
  const [logoLoadError, setLogoLoadError] = useState(false);

  useEffect(() => {
    const loadLogo = async () => {
      if (companyName) {
        const logo = await blobStorageService.getBestLogoVariant(companyName);
        if (logo) {
          setLogoUrl(logo.url);
          setLogoVariant(logo.variant);
        }
      }
    };
    loadLogo();
  }, [companyName]);

  const handleLogoError = () => {
    setLogoLoadError(true);
  };

  if (!logoUrl || logoLoadError) {
    const initials = getInitials(companyName);
    const backgroundColor = generatePastelColor(companyName);
    
    return (
      <div 
        className={`fallback-logo ${className}`}
        style={{
          backgroundColor,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
          color: '#333',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          fontFamily: 'Inter, sans-serif'
        }}
      >
        {initials}
      </div>
    );
  }

  return (
    <img
      src={logoUrl}
      alt={`${companyName} logo`}
      className={`${className} ${logoVariant === 'horizontal' ? 'horizontal-logo' : ''}`}
      onError={handleLogoError}
    />
  );
};

export default CompanyLogo; 