/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../styles/components/jobDetailsModal.scss";
import ResumeComponent from "./ResumeComponent";
import CoverLetter from "./CoverLetter"; // Import the new CoverLetter component
import axiosClient from "../axios-client";
import aiIcon from "../assets/ai-blue.svg"; // Path to AI blue icon
import JobDescriptionIcon from "../assets/job-description.svg"; // Path to job description icon
import { v4 as uuidv4 } from "uuid";
import { ResumeText } from './ResumeComponent';
import { addUtmParameters } from '../utils/utm';
import ApplicationConfirmModal from './ApplicationConfirmModal';
import { useApplicationModal } from '../context/ApplicationModalContext';
import { blobStorageService } from '../services/blobStorage.service';

export interface JobData {
  _id: string;
  title: string;
  original_title?: string;
  company: string;
  location: string;
  location_hub?: string;
  summary?: string;
  qualifications?: string[];
  preferredQualifications?: string[];
  responsibilities?: string[];
  environment?: string;
  skills?: string | string[];
  salary?: string;
  experience_level?: string;
  jobType?: string;
  tailored_flag?: string;
  days_to_live?: any;
  job_url?: string;
}

interface JobDetailsModalProps {
  job: JobData;
  isOpen: boolean;
  onClose: () => void;
}

const JobDetailsModal: React.FC<JobDetailsModalProps> = ({
  job: initialJob,
  isOpen,
  onClose,
}) => {
  const [selectedSection, setSelectedSection] = useState("job-details");
  const [resumeText, setResumeText] = useState<ResumeText | null>(null);
  const [resumeId, setResumeId] = useState<string>('');
  const [showCoverLetterTab, setShowCoverLetterTab] = useState(false);
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0); // For logo fallback
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [job, setJob] = useState<JobData>(initialJob);
  const [hasAnimated, setHasAnimated] = useState(false);

  // Add new state variables to track tailoring status
  const [hasResumeBeenTailored, setHasResumeBeenTailored] = useState(false);
  const [hasCoverLetterBeenTailored, setHasCoverLetterBeenTailored] = useState(false);

  // Add cache state
  const [cachedResumeContent, setCachedResumeContent] = useState<{
    suggestions: any[];
    score: number | null;
  } | null>(null);
  
  const [cachedCoverLetter, setCachedCoverLetter] = useState<string | null>(null);

  const [showApplicationConfirm, setShowApplicationConfirm] = useState(false);
  const [hasLeftForExternalSite, setHasLeftForExternalSite] = useState(false);

  const { setModalData } = useApplicationModal();

  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [logoVariant, setLogoVariant] = useState<'horizontal' | 'square' | 'vertical' | null>(null);

  useEffect(() => {
    const loadLogo = async () => {
      if (job.company) {
        const logo = await blobStorageService.getBestLogoVariant(job.company);
        if (logo) {
          setLogoUrl(logo.url);
          setLogoVariant(logo.variant);
        }
      }
    };
    loadLogo();
  }, [job.company]);

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !hasAnimated) {
      setHasAnimated(true);
    }
  }, [isOpen]);

  // Add cleanup function
  const cleanupJobData = () => {
    setHasAnimated(false);  // Reset animation state when modal closes
    // Clear all states
    setHasResumeBeenTailored(false);
    setHasCoverLetterBeenTailored(false);
    setShowCoverLetterTab(false);
    setSelectedSection("job-details");
    setResumeText(null);
    setCachedResumeContent(null);
    setCachedCoverLetter(null);
    setResumeId('');
    setError(null);
    
    // Clear localStorage items for this job
    localStorage.removeItem(`coverLetter_${job._id}`);
    localStorage.removeItem(`resumeContent_${job._id}`);
    localStorage.removeItem(`resumeScore_${job._id}`);
    localStorage.removeItem(`tailoredResume_${job._id}`);
    localStorage.removeItem(`resumeId_${job._id}`);
  };

  // Update the close handler
  const handleClose = () => {
    cleanupJobData();
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      fetchResume();
    } else {
      cleanupJobData();
    }
  }, [isOpen, job._id]);

  // Add new effect to handle tab changes
  useEffect(() => {
    // Don't clear cache when switching tabs
    if (selectedSection === "job-details") {
      setHasResumeBeenTailored(false);
      setHasCoverLetterBeenTailored(false);
    }
  }, [selectedSection]);

  // Add effect to persist cover letter in localStorage
  useEffect(() => {
    if (cachedCoverLetter) {
      localStorage.setItem(`coverLetter_${job._id}`, cachedCoverLetter);
    }
  }, [cachedCoverLetter, job._id]);

  // Load cached cover letter from localStorage when component mounts
  useEffect(() => {
    const savedCoverLetter = localStorage.getItem(`coverLetter_${job._id}`);
    if (savedCoverLetter) {
      setCachedCoverLetter(savedCoverLetter);
      setHasCoverLetterBeenTailored(true);
      setShowCoverLetterTab(true);
    }
  }, [job._id]);

  // Add effect to handle window focus
  useEffect(() => {
    const handleWindowFocus = () => {
      if (hasLeftForExternalSite) {
        setModalData({ 
          showModal: true, 
          jobTitle: job.title, 
          companyName: job.company,
          jobId: job._id
        });
        setHasLeftForExternalSite(false);
      }
    };

    window.addEventListener('focus', handleWindowFocus);
    return () => window.removeEventListener('focus', handleWindowFocus);
  }, [hasLeftForExternalSite, job.title, job.company, job._id, setModalData]);

  // Add effect to fetch full job details
  useEffect(() => {
    const fetchJobDetails = async () => {
      if (isOpen && initialJob._id) {
        try {
          setIsLoading(true);
          const response = await axiosClient.get(`/jobs/${initialJob._id}`);
          setJob(response.data);
        } catch (err) {
          console.error('Error fetching job details:', err);
          setError('Failed to load job details');
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchJobDetails();
  }, [isOpen, initialJob._id]);

  const user = JSON.parse(String(localStorage.getItem("user")));

  const transformData = (data: any): ResumeText => {
    const transformDescriptions = (items: any[]) => items.map(item => ({
      ...item,
      description: item.description.map((desc: any) =>
        typeof desc === "string" ? { id: uuidv4(), text: desc } : desc
      ),
    }));

    return {
      ...data,
      education: data.education.map((edu: any) => ({
        degree: edu.degree || '',
        field: edu.field || '',
        university: edu.university || '',
        graduationDate: edu.graduationDate || '',
        gpa: edu.gpa || ''
      })),
      experience: transformDescriptions(data.experience),
      projects: transformDescriptions(data.projects),
    };
  };

  const fetchResume = async () => {
    try {
      if (!user?._id || !job?._id) {
        throw new Error('Missing user or job data');
      }

      setIsLoading(true); // Add loading state

      // Try to get existing resume
      const resume = await axiosClient.get(`builder/get-resume/${user._id}/${job._id}`);
      
      if (resume?.data) {
        setResumeId(resume.data._id);
        setResumeText(transformData(resume.data));
      } else {
        // Fallback to user data if no resume exists
        const response = await axiosClient.get(`users/${user._id}`);
        if (response?.data) {
          setResumeText(transformData(response.data));
        } else {
          throw new Error('No user data found');
        }
      }
    } catch (error) {
      console.error("Error fetching resume:", error);
      setError(error instanceof Error ? error.message : 'Failed to fetch resume');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTailorButtonClick = () => {
    setShowCoverLetterTab(true);
    setSelectedSection("resume");
    // Don't set hasResumeBeenTailored here as it should be set after API call completes
  };

  const handleNavigateToCoverLetter = () => {
    setSelectedSection("cover-letter");
  };

  if (!isOpen) return null;

  const tabsConfig = [
    {
      id: "job-details",
      label: "Job Details",
      icon: (
        <img
          src={JobDescriptionIcon}
          alt="Job Description Icon"
          width="16"
          height="16"
          className="tab-icon"
        />
      ),
    },
    {
      id: "resume",
      label: "Resume",
      icon: (
        <img
          src={aiIcon}
          alt="Resume Icon"
          width="16"
          height="16"
          className="tab-icon"
        />
      ),
    },
    ...(showCoverLetterTab
      ? [
          {
            id: "cover-letter",
            label: "Cover Letter",
            icon: (
              <img
                src={aiIcon}
                alt="Cover Letter Icon"
                width="16"
                height="16"
                className="tab-icon"
              />
            ),
          },
        ]
      : []),
  ];

  const getJobTypeClass = (jobType: string) => {
    switch (jobType.toLowerCase()) {
      case "full time":
        return "full-time";
      case "part time":
        return "part-time";
      case "internship":
        return "internship";
      case "contract":
        return "contract";
      default:
        return "";
    }
  };

  const formatJobType = (jobType: string) => {
    switch (jobType.toLowerCase()) {
      case "full time":
        return "FULL-TIME";
      case "part time":
        return "PART-TIME";
      default:
        return jobType.toUpperCase();
    }
  };

  // Simplified getPostedDate function
  const getPostedDate = (daysToLive: number | undefined) => {
    if (daysToLive === undefined || daysToLive === null) {
      return "Recently Posted";
    }

    const totalDays = 15;
    const daysAgo = totalDays - daysToLive;

    switch (daysAgo) {
      case 0:
        return "Today";
      case 1:
        return "Yesterday";
      default:
        return `${daysAgo} days ago`;
    }
  };

  const renderContent = () => {
    const jobToDisplay = job;

    switch (selectedSection) {
      case "job-details":
        return (
          <div className="content-container">
            <div className="left-column">
              <h3>About the Job:</h3>
              <p>{jobToDisplay.summary || "No description provided."}</p>
              <h3>Requirements:</h3>
              {jobToDisplay.qualifications && jobToDisplay.qualifications.length > 0 ? (
                <ul>
                  {jobToDisplay.qualifications.map((req, index) => (
                    <li key={index}>{req}</li>
                  ))}
                </ul>
              ) : (
                <p>No requirements specified.</p>
              )}
              <h3>Responsibilities:</h3>
              {jobToDisplay.responsibilities && jobToDisplay.responsibilities.length > 0 ? (
                <ul>
                  {jobToDisplay.responsibilities.map((resp, index) => (
                    <li key={index}>{resp}</li>
                  ))}
                </ul>
              ) : (
                <p>No responsibilities specified.</p>
              )}
              <h3>Skills:</h3>
              <div className="skills">
                {jobToDisplay.skills ? (
                  Array.isArray(jobToDisplay.skills) ? (
                    jobToDisplay.skills.map((skill, index) => (
                      <span key={index} className="skill-badge">
                        {skill.replace(/^•\s*/, "").trim()}
                      </span>
                    ))
                  ) : (
                    jobToDisplay.skills.split(",").map((skill, index) => (
                      <span key={index} className="skill-badge">
                        {skill.trim()}
                      </span>
                    ))
                  )
                ) : (
                  <p>No skills specified.</p>
                )}
              </div>
            </div>
            <div className="right-column">
              <div className="job-info-box">
                <h4>Posted</h4>
                <p>{getPostedDate(jobToDisplay.days_to_live?.$numberInt)}</p>
                <h4>Salary Range</h4>
                <p>{jobToDisplay.salary || "Not available"}</p>
                <h4>Experience Level</h4>
                <p>{jobToDisplay.experience_level || "Not specified"}</p>
                <h4>Job Environment</h4>
                <p>{jobToDisplay.environment || "Not specified"}</p>
                <h4 className="about-company">About the Company</h4>
                <p className="about-company-text">
                  {jobToDisplay.company} is a leader in its industry providing
                  innovative solutions and services.
                </p>
              </div>
            </div>
          </div>
        );
      case "resume":
        return resumeText && (
          <ResumeComponent
            jobDescription={job}
            resumeText={resumeText}
            handleTailorButtonClick={handleTailorButtonClick}
            resumeId={resumeId}
            jobId={jobToDisplay._id}
            onNavigateToCoverLetter={handleNavigateToCoverLetter}
            hasBeenTailored={hasResumeBeenTailored}
            setHasBeenTailored={setHasResumeBeenTailored}
            cachedContent={cachedResumeContent}
            setCachedContent={setCachedResumeContent}
          />
        );
      case "cover-letter":
        return resumeText && (
          <CoverLetter 
            job={job} 
            resumeText={resumeText} 
            hasBeenTailored={hasCoverLetterBeenTailored}
            setHasBeenTailored={setHasCoverLetterBeenTailored}
            cachedContent={cachedCoverLetter}
            setCachedContent={setCachedCoverLetter}
          />
        );
      default:
        return null;
    }
  };

  // Modify tab switching to preserve tailoring state
  const handleTabChange = (tabId: string) => {
    setSelectedSection(tabId);
  };

  const handleExternalApply = (e: React.MouseEvent) => {
    e.preventDefault();
    if (job.job_url) {
      setHasLeftForExternalSite(true);
      window.open(addUtmParameters(job.job_url), '_blank', 'noopener,noreferrer');
    }
  };

  const handleApplicationConfirm = (didApply: boolean) => {
    if (didApply) {
      // Here we'll later add the logic to track the application
    }
    setShowApplicationConfirm(false);
  };

  return (
    <div className={`modal-backdrop ${isOpen ? "show" : ""}`}>
      <div className={`modal-content ${isOpen ? "show" : ""}`}>
        <div className="sticky-container">
          <div className="modal-header">
            <div className="tabs">
              {tabsConfig.map((tab) => (
                <div
                  key={tab.id}
                  className={`tab ${selectedSection === tab.id ? "selected" : ""}`}
                  onClick={() => handleTabChange(tab.id)}
                >
                  <span className="tab-icon">{tab.icon}</span>
                  <span>{tab.label}</span>
                </div>
              ))}
            </div>
            <button className="close-button" onClick={handleClose}>
              ×
            </button>
          </div>
          <div className={`header-section ${hasAnimated ? 'animate' : ''} ${selectedSection === "job-details" ? 'visible' : ''}`}>
            <div className="job-title-section">
              <div className="top-row">
                <div className={`company-logo-wrapper ${logoVariant === 'horizontal' ? 'horizontal-logo-container' : ''}`}>
                  {logoUrl ? (
                    <img
                      src={logoUrl}
                      alt={`${job.company} logo`}
                      className={`company-logo ${logoVariant === 'horizontal' ? 'horizontal-logo' : ''}`}
                    />
                  ) : (
                    <div className="logo-placeholder" />
                  )}
                </div>
                {job.jobType && (
                  <span
                    className={`job-type-tag ${getJobTypeClass(job.jobType)}`}
                  >
                    {formatJobType(job.jobType)}
                  </span>
                )}
              </div>
              <div className="bottom-row">
                <div className="job-title">
                  <h2>{job.title}</h2>
                  <p className="company-location">
                    {job.company} • {job.location}
                  </p>
                </div>
              </div>
            </div>
            <div className="button-group">
              <a
                href={job.job_url}
                onClick={handleExternalApply}
                className="external-link"
              >
                Apply Externally <svg className="external-icon" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21 3V9H19V6.414L11.207 14.207L9.793 12.793L17.586 5H15V3H21Z" fill="currentColor"/>
                      <path d="M19 19H5V5H12V3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21H19C20.103 21 21 20.103 21 19V12H19V19Z" fill="currentColor"/>
                  </svg>
              </a>
              <button
                className="tailor-button"
                onClick={() => setSelectedSection("resume")}
              >
                Tailor My Resume
              </button>
            </div>
          </div>
          {selectedSection === "job-details" && <hr className="separator-line" />}
        </div>
        <div className="modal-body">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default JobDetailsModal;