import axiosClient from "../axios-client";
// src/services/jobService.js
export const fetchJobs = async () => {
    try {
        const response = await axiosClient.get('/jobs/getJobs');
        console.log(response)
        const data = response.data
        return data;
    } catch (error) {
        console.error("Error fetching jobs:", error);
        throw error;
    }
};

export const fetchJobById = async (jobId) => {
    try {
        const response = await axiosClient.get(`/jobs/${jobId}`);
        console.log('Job details response:', response);
        return response.data;
    } catch (error) {
        console.error("Error fetching job by ID:", error);
        throw error;
    }
};