/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../styles/components/jobDetailsModal.scss";
import ResumeComponent from "../ResumeComponent";
import CoverLetter from "../CoverLetter";
import axiosClient from "../../axios-client";
import aiIcon from "../../assets/ai-blue.svg";
import JobDescriptionIcon from "../../assets/job-description.svg";
import { v4 as uuidv4 } from "uuid";
import { ResumeText } from '../ResumeComponent';
import { addUtmParameters } from '../../utils/utm';
import CompanyLogo from '../CompanyLogo';

export interface JobData {
  _id: string;
  title: string;
  original_title?: string;
  company: string;
  location: string;
  location_hub?: string;
  summary?: string;
  qualifications?: string[];
  preferredQualifications?: string[];
  responsibilities?: string[];
  environment?: string;
  skills?: string | string[];
  salary?: string;
  experience_level?: string;
  jobType?: string;
  tailored_flag?: string;
  days_to_live?: any;
  job_url?: string;
}

interface JobDetailsModalProps {
  job: JobData;
  isOpen: boolean;
  onClose: () => void;
}

const JobDetailsModal: React.FC<JobDetailsModalProps> = ({
  job,
  isOpen,
  onClose,
}) => {
  const [selectedSection, setSelectedSection] = useState("job-details");
  const [resumeText, setResumeText] = useState<ResumeText | null>(null);
  const [resumeId, setResumeId] = useState<string>('');
  const [showCoverLetterTab, setShowCoverLetterTab] = useState(false);
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0);

  const [hasResumeBeenTailored, setHasResumeBeenTailored] = useState(false);
  const [hasCoverLetterBeenTailored, setHasCoverLetterBeenTailored] = useState(false);

  const [cachedResumeContent, setCachedResumeContent] = useState<{
    suggestions: any[];
    score: number | null;
  } | null>(null);
  
  const [cachedCoverLetter, setCachedCoverLetter] = useState<string | null>(null);

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      fetchResume();
    } else {
      setHasResumeBeenTailored(false);
      setHasCoverLetterBeenTailored(false);
      setShowCoverLetterTab(false);
      setSelectedSection("job-details");
      setResumeText(null);
      setCachedResumeContent(null);
      setCachedCoverLetter(null);
    }
  }, [isOpen]);

  const user = JSON.parse(String(localStorage.getItem("user")));

  const fetchResume = async () => {
    try {
      console.log('user._id', user._id);
      console.log('job._id', job._id);
      const resume = await axiosClient.get(`builder/get-resume/${user._id}/${job._id}`);

      if (resume.data) {
        let resumeId = resume.data._id;
        setResumeId(resumeId);
        if (resume.data) {
          const updatedExperience = resume.data.experience.map((exp: any) => ({
            ...exp,
            description: exp.description.map((desc: any) =>
              typeof desc === "string" ? { id: uuidv4(), text: desc } : desc
            ),
          }));
  
          const updatedProjects = resume.data.projects.map((proj: any) => ({
            ...proj,
            description: proj.description.map((desc: any) =>
              typeof desc === "string" ? { id: uuidv4(), text: desc } : desc
            ),
          }));
  
          const transformedResume: ResumeText = {
            ...resume.data,
            education: resume.data.education.map((edu: any) => ({
              degree: edu.degree || '',
              field: edu.field || '',
              university: edu.university || '',
              graduationDate: edu.graduationDate || '',
              gpa: edu.gpa || ''
            })),
            experience: updatedExperience,
            projects: updatedProjects,
          };
          setResumeText(transformedResume);
        }
      } else {
        const response = await axiosClient.get(`users/${user._id}`);
      const data = response.data;

      if (response) {
        const updatedExperience = data.experience.map((exp: any) => ({
          ...exp,
          description: exp.description.map((desc: any) =>
            typeof desc === "string" ? { id: uuidv4(), text: desc } : desc
          ),
        }));

        const updatedProjects = data.projects.map((proj: any) => ({
          ...proj,
          description: proj.description.map((desc: any) =>
            typeof desc === "string" ? { id: uuidv4(), text: desc } : desc
          ),
        }));

        const transformedResume: ResumeText = {
          ...data,
          education: data.education.map((edu: any) => ({
            degree: edu.degree || '',
            field: edu.field || '',
            university: edu.university || '',
            graduationDate: edu.graduationDate || '',
            gpa: edu.gpa || ''
          })),
          experience: updatedExperience,
          projects: updatedProjects,
        };

        setResumeText(transformedResume);
      }
      }
    } catch (error) {
      console.error("Error fetching resume:", error);
    }
  };

  const handleTailorButtonClick = () => {
    setShowCoverLetterTab(true);
    setSelectedSection("resume");
  };

  const handleNavigateToCoverLetter = () => {
    setSelectedSection("cover-letter");
  };

  if (!isOpen) return null;

  const tabsConfig = [
    {
      id: "job-details",
      label: "Job Details",
      icon: (
        <img
          src={JobDescriptionIcon}
          alt="Job Description Icon"
          width="16"
          height="16"
          className="tab-icon"
        />
      ),
    },
    {
      id: "resume",
      label: "Resume",
      icon: (
        <img
          src={aiIcon}
          alt="Resume Icon"
          width="16"
          height="16"
          className="tab-icon"
        />
      ),
    },
    ...(showCoverLetterTab
      ? [
          {
            id: "cover-letter",
            label: "Cover Letter",
            icon: (
              <img
                src={aiIcon}
                alt="Cover Letter Icon"
                width="16"
                height="16"
                className="tab-icon"
              />
            ),
          },
        ]
      : []),
  ];

  const getCompanyLogo = (company: string | undefined): string[] => {
    if (!company) {
      return ['/company-logos/default-logo.png'];
    }

    const formattedCompanyName = company.toLowerCase().replace(/\s/g, '-');

    return [
      `/company-logos/${formattedCompanyName}_horizontal.png`,
      `/company-logos/${formattedCompanyName}_vertical.png`,
      `/company-logos/${formattedCompanyName}_square.png`,
      `/company-logos/default-logo.png`,
    ];
  };

  const logoPaths = getCompanyLogo(job.company);

  const handleLogoError = () => {
    if (currentLogoIndex < logoPaths.length - 1) {
      setCurrentLogoIndex(currentLogoIndex + 1);
    }
  };

  const isHorizontal = logoPaths[currentLogoIndex].includes('_horizontal');

  const getJobTypeClass = (jobType: string) => {
    switch (jobType.toLowerCase()) {
      case "full time":
        return "full-time";
      case "part time":
        return "part-time";
      case "internship":
        return "internship";
      case "contract":
        return "contract";
      default:
        return "";
    }
  };

  const formatJobType = (jobType: string) => {
    switch (jobType.toLowerCase()) {
      case "full time":
        return "FULL-TIME";
      case "part time":
        return "PART-TIME";
      default:
        return jobType.toUpperCase();
    }
  };

  const getPostedDate = (daysToLive: number | undefined) => {
    console.log("days_to_live value:", daysToLive);
    if (daysToLive === undefined || daysToLive === null) {
      return "Recently Posted";
    }

    const totalDays = 15;
    const daysAgo = totalDays - daysToLive;

    switch (daysAgo) {
      case 0:
        return "Today";
      case 1:
        return "Yesterday";
      default:
        return `${daysAgo} days ago`;
    }
  };

  console.log("Job data in component:", job);

  const renderContent = () => {
    const jobToDisplay = job;

    switch (selectedSection) {
      case "job-details":
        return (
          <div className="content-container">
            <div className="left-column">
              <h3>About the Job:</h3>
              <p>{jobToDisplay.summary || "No description provided."}</p>
              <h3>Requirements:</h3>
              {jobToDisplay.qualifications && jobToDisplay.qualifications.length > 0 ? (
                <ul>
                  {jobToDisplay.qualifications.map((req, index) => (
                    <li key={index}>{req}</li>
                  ))}
                </ul>
              ) : (
                <p>No requirements specified.</p>
              )}
              <h3>Responsibilities:</h3>
              {jobToDisplay.responsibilities && jobToDisplay.responsibilities.length > 0 ? (
                <ul>
                  {jobToDisplay.responsibilities.map((resp, index) => (
                    <li key={index}>{resp}</li>
                  ))}
                </ul>
              ) : (
                <p>No responsibilities specified.</p>
              )}
              <h3>Skills:</h3>
              <div className="skills">
                {jobToDisplay.skills ? (
                  Array.isArray(jobToDisplay.skills) ? (
                    jobToDisplay.skills.map((skill, index) => (
                      <span key={index} className="skill-badge">
                        {skill.replace(/^•\s*/, "").trim()}
                      </span>
                    ))
                  ) : (
                    jobToDisplay.skills.split(",").map((skill, index) => (
                      <span key={index} className="skill-badge">
                        {skill.trim()}
                      </span>
                    ))
                  )
                ) : (
                  <p>No skills specified.</p>
                )}
              </div>
            </div>
            <div className="right-column">
              <div className="job-info-box">
                <h4>Posted</h4>
                <p>{getPostedDate(jobToDisplay.days_to_live?.$numberInt)}</p>
                <h4>Salary Range</h4>
                <p>{jobToDisplay.salary || "Not available"}</p>
                <h4>Experience Level</h4>
                <p>{jobToDisplay.experience_level || "Not specified"}</p>
                <h4>Job Environment</h4>
                <p>{jobToDisplay.environment || "Not specified"}</p>
                <h4 className="about-company">About the Company</h4>
                <p className="about-company-text">
                  {jobToDisplay.company} is a leader in its industry providing
                  innovative solutions and services.
                </p>
              </div>
            </div>
          </div>
        );
      case "resume":
        return resumeText && (
          <ResumeComponent
            jobDescription={job}
            resumeText={resumeText}
            handleTailorButtonClick={handleTailorButtonClick}
            resumeId={resumeId}
            jobId={jobToDisplay._id}
            onNavigateToCoverLetter={handleNavigateToCoverLetter}
            hasBeenTailored={hasResumeBeenTailored}
            setHasBeenTailored={setHasResumeBeenTailored}
            cachedContent={cachedResumeContent}
            setCachedContent={setCachedResumeContent}
          />
        );
      case "cover-letter":
        return resumeText && (
          <CoverLetter 
            job={job} 
            resumeText={resumeText}
            hasBeenTailored={hasCoverLetterBeenTailored}
            setHasBeenTailored={setHasCoverLetterBeenTailored}
            cachedContent={cachedCoverLetter}
            setCachedContent={setCachedCoverLetter}
          />
        );
      default:
        return null;
    }
  };

  const handleTabChange = (tabId: string) => {
    setSelectedSection(tabId);
  };

  const handleExternalApply = (e: React.MouseEvent) => {
    e.preventDefault();
    if (job.job_url) {
      window.open(addUtmParameters(job.job_url), '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className={`modal-backdrop ${isOpen ? "show" : ""}`}>
      <div className={`modal-content ${isOpen ? "show" : ""}`}>
        <div className="sticky-container">
          <div className="modal-header">
            <div className="tabs">
              {tabsConfig.map((tab) => (
                <div
                  key={tab.id}
                  className={`tab ${selectedSection === tab.id ? "selected" : ""}`}
                  onClick={() => handleTabChange(tab.id)}
                >
                  <span className="tab-icon">{tab.icon}</span>
                  <span>{tab.label}</span>
                </div>
              ))}
            </div>
            <button className="close-button" onClick={onClose}>
              ×
            </button>
          </div>
          {selectedSection === "job-details" && (
            <>
              <div className="header-section visible animate">
                <div className="job-title-section">
                  <div className="top-row">
                    <div className="company-logo-wrapper">
                      <CompanyLogo 
                        companyName={job.company} 
                        className="company-logo"
                      />
                    </div>
                    {job.jobType && (
                      <span
                        className={`job-type-tag ${getJobTypeClass(job.jobType)}`}
                      >
                        {formatJobType(job.jobType)}
                      </span>
                    )}
                  </div>
                  <div className="bottom-row">
                    <div className="job-title">
                      <h2>{job.title}</h2>
                      <p className="company-location">
                        {job.company} • {job.location}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="button-group">
                  {job.job_url && job.job_url.trim() !== '' && (
                    <a
                      href={job.job_url}
                      onClick={handleExternalApply}
                      className="external-link"
                    >
                      Apply Externally <svg className="external-icon" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 3V9H19V6.414L11.207 14.207L9.793 12.793L17.586 5H15V3H21Z" fill="currentColor"/>
                        <path d="M19 19H5V5H12V3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21H19C20.103 21 21 20.103 21 19V12H19V19Z" fill="currentColor"/>
                      </svg>
                    </a>
                  )}
                  <button
                    className="tailor-button"
                    onClick={() => setSelectedSection("resume")}
                  >
                    Tailor My Resume
                  </button>
                </div>
              </div>
              <hr className="separator-line" />
            </>
          )}
        </div>
        <div className="modal-body">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default JobDetailsModal; 