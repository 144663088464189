import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/components/landing-pages/signUpComponent.scss';

const features = [
  'Job Matches',
  'Tailored Resumes',
  'Tailored Cover Letters',
  'Autofill Applications',
  'Mock Interviews'
];

const SignUpComponent: React.FC = () => {
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFeatureIndex((prevIndex) => (prevIndex + 1) % features.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="signup-section">
      <div className="signup-content">
        <h2>Smarter Job Search. Faster Results.</h2>
        <p>AI-powered job matches to help you find your next role faster.</p>
        <Link to="/auth/signup" className="cta-btn">
          Get Started - It's Free
        </Link>
      </div>

      <div className="features-carousel">
        {features.map((feature, index) => (
          <div
            key={index}
            className={`feature-item ${
              index === currentFeatureIndex ? 'active' : 'inactive'
            }`}
          >
            {feature}
          </div>
        ))}
      </div>
    </section>
  );
};

export default SignUpComponent;
