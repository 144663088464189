import React from 'react';
import '../styles/components/welcomeModal.scss';
import { AiOutlineClose } from 'react-icons/ai';

interface WelcomeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAfterClose?: () => void; // Optional callback for after modal closes
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({ isOpen, onClose, onAfterClose }) => {
  if (!isOpen) return null;

  const handleClose = () => {
    // First close the modal
    onClose();
    
    // Then trigger any necessary dashboard updates
    if (onAfterClose) {
      // Small delay to ensure modal animation completes
      setTimeout(() => {
        onAfterClose();
      }, 300);
    }
  };

  return (
    <div className="welcome-modal-backdrop">
      <div className="welcome-modal-content">
        <button className="modal-close-button" onClick={handleClose} aria-label="Close modal">
          <AiOutlineClose />
        </button>
        
        <div className="welcome-header">
          <div className="trial-badge">7-DAY FREE TRIAL</div>
          <div className="title-row">
            <h2>Experience QuickCruit Pro</h2>
            <span className="celebration-icon">🎉</span>
          </div>
          <p>Get ready to supercharge your job search with premium features.</p>
        </div>

        <div className="features-list">
          <div className="feature-item premium">
            <span className="feature-icon">✨</span>
            <div className="feature-details">
              <h3>Unlimited Resume Tailoring</h3>
              <p>Get AI-powered resume optimization for unlimited job applications</p>
            </div>
          </div>

          <div className="feature-item premium">
            <span className="feature-icon">📝</span>
            <div className="feature-details">
              <h3>AI Cover Letter Generation</h3>
              <p>Generate personalized cover letters in seconds</p>
            </div>
          </div>

          <div className="feature-item premium">
            <span className="feature-icon">💬</span>
            <div className="feature-details">
              <h3>Premium Support</h3>
              <p>Get priority email support throughout your job search</p>
            </div>
          </div>

          <div className="features-divider">
            <span>Plus all free features</span>
          </div>

          <div className="free-features">
            <div className="feature-item free">
              <span className="feature-icon">🎯</span>
              <div className="feature-details">
                <h3>Smart Job Matching</h3>
                <p>Find the perfect jobs that match your skills and preferences</p>
              </div>
            </div>

            <div className="feature-item free">
              <span className="feature-icon">📊</span>
              <div className="feature-details">
                <h3>Application Tracker</h3>
                <p>Keep track of all your job applications in one place</p>
              </div>
            </div>

            <div className="feature-item free">
              <span className="feature-icon">💼</span>
              <div className="feature-details">
                <h3>Professional Portfolio</h3>
                <p>Showcase your work and achievements to potential employers</p>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button className="get-started-btn" onClick={handleClose}>
            START YOUR FREE TRIAL
          </button>
          <p className="trial-note">No credit card required</p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal; 