import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/components/legal.scss';
import logoImage from '../logo.svg';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="legal-wrapper">
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/">
            <img src={logoImage} alt="QuickCruit Logo" className="logo" />
          </Link>
        </div>
      </nav>
      <div className="legal-container">
        <h1>Privacy Policy</h1>
        <p><em>Last Updated: 12/21/2024</em></p>

        <h2>1. Introduction</h2>
        <p>
          QuickCruit Inc. (“<strong>QuickCruit</strong>,” “<strong>we</strong>,” “<strong>us</strong>,” or “<strong>our</strong>”) 
          is committed to protecting your privacy. This Privacy Policy explains how we collect, use, store, and safeguard 
          your personal information when you use our website, platform, mobile applications, browser extensions, and 
          related services (collectively, the “<strong>Services</strong>”).
        </p>

        <h2>2. Information We Collect</h2>
        <p>
          We collect information to provide and improve our Services, enhance user experiences, and ensure system integrity.
          The types of information we may collect include:
        </p>
        <ul>
          <li><strong>Personal Information:</strong> When you create an account, we collect your first name, last name, email address, and a password. Passwords are securely hashed and never visible to QuickCruit staff.</li>
          <li><strong>User-Generated Content:</strong> Résumés, cover letters, interview responses, and other materials you create or store using our Services are retained to allow you to access and modify them over time.</li>
          <li><strong>Usage Data:</strong> We may collect anonymized usage statistics, feature adoption metrics, and general activity patterns to help us improve platform reliability, features, and user satisfaction.</li>
        </ul>
        <p>
          We do not intentionally collect sensitive personal information (e.g., race, religion, health, or biometric data). 
          We encourage users to refrain from including unnecessary sensitive information in their application materials.
        </p>

        <h2>3. How We Use Your Information</h2>
        <p>
          We use your information for the following purposes:
        </p>
        <ul>
          <li><strong>Service Delivery:</strong> To provide core functionalities, such as AI résumé generation, AI cover letter creation, mock interviews, and career coaching.</li>
          <li><strong>Personalization:</strong> To offer tailored job recommendations, suggestions for résumé improvements, and personalized career advice.</li>
          <li><strong>Platform Enhancement:</strong> To identify areas of improvement, refine AI models, enhance user interfaces, and develop new features.</li>
          <li><strong>Security & Integrity:</strong> To monitor and protect the Services, detect and prevent fraudulent activity, and ensure a safe user environment.</li>
          <li><strong>Communication:</strong> To send updates about changes to our Terms, Privacy Policy, or features, and to respond to inquiries and support requests.</li>
        </ul>
        <p>
          Data used to improve our AI models or algorithms is anonymized and aggregated. No personally identifiable information 
          is used in model training or sharing processes.
        </p>

        <h2>4. Data Sharing & Disclosure</h2>
        <p>
          We do not sell, rent, or trade your personal information to third parties. We may share limited information in the following situations:
        </p>
        <ul>
          <li><strong>Service Providers:</strong> We work with trusted third-party service providers who assist with payment processing, analytics, infrastructure hosting, and other essential operations. These partners are contractually bound to handle data securely and comply with our privacy standards.</li>
          <li><strong>Legal Compliance:</strong> We may disclose information if required by law, regulation, legal process, or governmental request to protect the rights, property, or safety of QuickCruit, our users, or the public.</li>
        </ul>

        <h2>5. Data Security Measures</h2>
        <p>
          We take data security seriously and implement multiple layers of protection:
        </p>
        <ul>
          <li><strong>Authentication & Authorization:</strong> JWT-based authentication and token-based session management to ensure only authorized access.</li>
          <li><strong>Password Protection:</strong> Bcrypt hashing of user passwords to prevent unauthorized retrieval.</li>
          <li><strong>Input Validation & Sanitization:</strong> Use of tools like Joi, xss-clean, and express-mongo-sanitize to prevent injection attacks or malicious payloads.</li>
          <li><strong>Network Security:</strong> Rate limiting, Helmet security headers, and carefully managed CORS policies to reduce the risk of unauthorized requests.</li>
          <li><strong>Ongoing Improvement:</strong> Periodic reviews, audits, and updates to infrastructure and security protocols to keep pace with evolving threats.</li>
        </ul>

        <h2>6. Data Breach Protocol</h2>
        <p>
          In the event of a data breach, we will:
        </p>
        <ul>
          <li>Isolate affected systems and revoke compromised tokens immediately.</li>
          <li>Notify impacted users as soon as we confirm an incident.</li>
          <li>Implement automated breach detection and real-time monitoring measures if not already in place.</li>
          <li>Use encryption at rest, where applicable, to safeguard sensitive data in the future.</li>
          <li>Maintain detailed incident response plans and regular backups for swift recovery.</li>
        </ul>

        <h2>7. User Rights & Control</h2>
        <p>
          You have the right to access, update, or delete your personal information at any time. Simply visit your account 
          profile page to make changes, or contact us if you require assistance. If you wish to close your account, please 
          email <a href="mailto:contact@quickcruit.ai">contact@quickcruit.ai</a>.
        </p>

        <h2>8. Cookies & Tracking Technologies</h2>
        <p>
          Currently, we do not use cookies or other tracking technologies to collect personal data. If we introduce cookies 
          or similar technologies in the future, we will update this Privacy Policy and provide appropriate disclosures and 
          consent mechanisms.
        </p>

        <h2>9. International Data Transfers</h2>
        <p>
          QuickCruit primarily operates in the United States. If you access the Services from outside the U.S., your 
          information may be transferred to servers located in other countries. We will take measures to ensure appropriate 
          safeguards protect your data in compliance with applicable laws.
        </p>

        <h2>10. Children’s Privacy</h2>
        <p>
          Our Services are intended for users 18 years of age or older. We do not knowingly collect personal information from 
          children. If you believe that a child under 18 has provided us with personal information, please contact us so we 
          can remove such data and terminate the associated account.
        </p>

        <h2>11. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes in our practices, technologies, or legal 
          obligations. We will notify you of significant changes via email or through prominent notices on our website. By 
          continuing to use the Services after updates become effective, you acknowledge and accept the modified Privacy Policy.
        </p>

        <h2>12. Contact Us</h2>
        <p>
          If you have questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at 
          <a href="mailto:contact@quickcruit.ai"> contact@quickcruit.ai</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;