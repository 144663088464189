import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../styles/components/auth.scss';
import { useParams, useNavigate } from 'react-router-dom';
import logoImage from '../logo.svg';
import axiosClient from '../axios-client';
import { Bounce, toast } from 'react-toastify';



const EmailVerified = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const navigate = useNavigate();

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                await axiosClient.get(`/auth/verify-email?token=${token}`)
                toast('Verification Mail Sent', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
                // navigate('/auth/login');
            } catch (err:any) {
                toast(err.response?.data?.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
            }
        }

        const interval = setInterval(async () => {
            verifyEmail()
            clearInterval(interval)
        },10000)
    }, [token])
    return (
        <div className="auth-wrapper">
            <header className="auth-header">
                <Link to="/">
                    <img src={logoImage} alt="QuickCruit Logo" className="auth-logo" />
                </Link>
            </header>
            <div className="auth-container">
                <h2>Email Verified</h2>
                <p>Thank you for verifying your account.</p>
                {/* <p>If you did not receive the email, please check your spam folder or <Link to="/auth/resend-verification">click here</Link> to resend the verification email.</p> */}
            </div>
        </div>
    )
}

export default EmailVerified