import React from 'react'
import { Button, ButtonProps } from './Button'
import "../../styles/buttons.scss";


interface PrimaryButtonProps extends Omit<ButtonProps, 'variant'> {
  children: React.ReactNode
}

const PrimaryButton = React.forwardRef<HTMLButtonElement, PrimaryButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        variant="default"
        className="text-white bg-['#0033a0'] p-2 rounded-sm"
        {...props}
      >
        {children}
      </Button>
    )
  }
)

PrimaryButton.displayName = 'PrimaryButton'

export default PrimaryButton