import React, { useEffect, useState, useRef, useCallback } from "react";
import "../../styles/components/landing-pages/jobPage.scss";
import Navbar from "./Navbar";
import ResumeHighlight from "../../animations/components/ResumeHighlight";
import feature2 from "../../assets/feature2.png";
import feature3 from "../../assets/feature3.png";
import feature4 from "../../assets/feature4.png";
import Footer from "./Footer";
import jobDescriptionIcon from "../../assets/resume.svg";
import { Link } from "react-router-dom";
import JobTile from "../../animations/components/JobTile";
import NewJobBenefits from "../../animations/components/NewJobBenefits";

const ResumeTailoringPage = () => {
  // Animation states - commented out for now but kept for future use
  /*
  const [scrollProgress, setScrollProgress] = useState(0);
  const [demoSectionDimensions, setDemoSectionDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [heroHeight, setHeroHeight] = useState(0);
  const NAVBAR_HEIGHT = 88;
  const [isCaptionVisible, setIsCaptionVisible] = useState(false);
  const [hasExpanded, setHasExpanded] = useState(false);
  */
  
  const demoSectionRef = useRef<HTMLDivElement | null>(null);
  const heroSectionRef = useRef<HTMLElement | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Animation effects - commented out for now but kept for future use
  /*
  useEffect(() => {
    if (demoSectionRef.current) {
      const rect = demoSectionRef.current.getBoundingClientRect();
      setDemoSectionDimensions({
        width: rect.width,
        height: rect.height,
      });
    }
    if (heroSectionRef.current) {
      const rect = heroSectionRef.current.getBoundingClientRect();
      setHeroHeight(rect.height);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (demoSectionRef.current) {
        const rect = demoSectionRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        
        const startScroll = rect.top - viewportHeight * 1.8;
        const endScroll = rect.top + (viewportHeight * 0.8);
        const scrollRange = endScroll - startScroll;
        
        const currentScroll = window.scrollY - startScroll;
        const progress = Math.max(0, Math.min(1, currentScroll / scrollRange));
        
        setScrollProgress(progress);
        
        if (progress >= 1 && !hasExpanded) {
          setHasExpanded(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    requestAnimationFrame(handleScroll);
    
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasExpanded]);

  const progress = scrollProgress || 0;
  const heroTranslateY = -progress * 100;
  const heroOpacity = Math.max(1 - progress * 2, 0);
  const maxScaleX = Math.min(5, window.innerWidth / (180 * 2));
  const maxScaleY = Math.min(5, (window.innerHeight - NAVBAR_HEIGHT) / (180 * 2));
  const maxScale = Math.min(5, maxScaleX, maxScaleY);
  const demoScale = 1 + (progress * (maxScale - 1));
  const deltaHeight = demoSectionDimensions.height * (demoScale - 1);
  const demoTranslateY = progress * -50;

  useEffect(() => {
    if (progress >= 1 && !isCaptionVisible) {
      setIsCaptionVisible(true);
    }
  }, [progress]);

  useEffect(() => {
    if (progress >= 1 && !hasExpanded) {
      setHasExpanded(true);
    }
  }, [progress]);

  const calculateProgress = useCallback(() => {
    if (!demoSectionRef.current) return 0;
    
    const rect = demoSectionRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight - NAVBAR_HEIGHT;
    const elementTop = rect.top - NAVBAR_HEIGHT;
    
    const progress = Math.max(0, Math.min(1, 1 - (elementTop / viewportHeight)));
    
    return progress;
  }, []);
  */

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Set up intersection observer for feature animations
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Add animate class immediately
          entry.target.classList.add('animate');
          
          // Force a reflow
          if (entry.target instanceof HTMLElement) {
            void entry.target.offsetHeight;
          }
          
          if (entry.isIntersecting) {
            // Add visible class after a small delay to ensure animation triggers
            requestAnimationFrame(() => {
              entry.target.classList.add('visible');
            });
            observer.unobserve(entry.target); // Only animate once
          }
        });
      },
      {
        root: null,
        threshold: 0.2, // Start animation when 20% of element is visible
        rootMargin: '0px'
      }
    );

    // Observe all feature rows
    document.querySelectorAll('.feature-row').forEach((feature) => {
      observer.observe(feature);
    });

    return () => observer.disconnect();
  }, []);

  // Add state for active FAQ item
  const [activeFaqIndex, setActiveFaqIndex] = useState<number | null>(null);

  const handleFaqClick = (index: number) => {
    setActiveFaqIndex(activeFaqIndex === index ? null : index);
  };

  // Handle entrance animations
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Add animate class immediately
          entry.target.classList.add('animate');
          
          // Force a reflow
          if (entry.target instanceof HTMLElement) {
            void entry.target.offsetHeight;
          }
          
          if (entry.isIntersecting) {
            // Add visible class after a small delay to ensure animation triggers
            requestAnimationFrame(() => {
              entry.target.classList.add('visible');
            });
            observer.unobserve(entry.target); // Only animate once
          }
        });
      },
      {
        root: null,
        threshold: 0.2, // Start animation when 20% of element is visible
        rootMargin: '0px'
      }
    );

    // Observe all FAQ items
    document.querySelectorAll('.faq-item').forEach((item) => {
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <Navbar />
      <div className="job-page-container">
        <section
          ref={heroSectionRef}
          className="job-features-hero-section"
        >
          <div className="hero-content">
            <h1 className="hero-heading">
              Perfect Your Resume with AI
            </h1>
            <p className="hero-subheading">
              Our AI-driven tool customizes your resume for every oppurtuinity, spotlighting
              the skills and keywords recruiters are searching for.
            </p>
            <Link to="/auth/signup" className="cta-btn">
              Get Started - It's Free
            </Link>
          </div>
          <div className="hero-image-container">
            <div className="resume-highlight-container">
              <ResumeHighlight />
            </div>
          </div>
        </section>

        <section className="job-demo-section" ref={demoSectionRef}>
          <div className="demo-container">
            <h2 className="demo-heading">ATS Resume Optimization</h2>
            <div className="demo-animation-container">
              <img
                src={jobDescriptionIcon}
                alt="Resume Tailoring Demo"
                className="demo-icon"
              />
            </div>
          </div>
        </section>

        <section className="job-features-section">
          <div className="feature-row">
            <div className="feature-content">
              <h2>AI-Powered Keyword Optimization</h2>
              <p>
                Our advanced AI analyzes job descriptions and optimizes your resume 
                with relevant keywords and skills that ATS systems look for.
              </p>
            </div>
            <div className="feature-image">
              <NewJobBenefits />
            </div>
          </div>

          <div className="feature-row">
            <div className="feature-image">
              <img src={feature2} alt="Format feature" />
            </div>
            <div className="feature-content">
              <h2>Professional Formatting</h2>
              <p>
              Create a stunning resume that not only looks great but also passes ATS with ease. Choose 
              from expertly crafted templates tailored to your industry for a standout professional presentation.
              </p>
            </div>
          </div>

          <div className="feature-row">
            <div className="feature-content">
              <h2>Real-Time Feedback & Match Score</h2>
              <p>
              Boost your chances of success with instant insights. Receive a match score 
              for your resume and actionable suggestions to make it interview-ready in no time.
              </p>
            </div>
            <div className="feature-image">
              <JobTile />
            </div>
          </div>

          <div className="feature-row">
            <div className="feature-image">
              <img src={feature4} alt="Version control feature" />
            </div>
            <div className="feature-content">
              <h2>Effortless Resume Management</h2>
              <p>
              Effortlessly manage multiple resume versions tailored to different job applications. Stay 
              organized and update your resumes with ease, all in one convenient place.
              </p>
            </div>
          </div>
        </section>

        <section className="faq-section">
          <div className="faq-container">
            <h2 className="faq-heading">Frequently Asked Questions</h2>
            <div className="faq-grid">
              {[
                {
                  question: "How does AI optimize my resume?",
                  answer: "Our AI analyzes job descriptions and matches them with your experience, suggesting relevant keywords and skills. It ensures your resume aligns with ATS requirements while maintaining a professional, readable format."
                },
                {
                  question: "Will my resume still look professional?",
                  answer: "Absolutely! Our AI maintains the perfect balance between ATS optimization and human readability. Your resume will be beautifully formatted while containing all the right keywords for ATS systems."
                },
                {
                  question: "How many versions can I create?",
                  answer: "Create unlimited versions of your resume, each tailored to different job applications. Our platform saves all versions, making it easy to track and update them as needed."
                },
                {
                  question: "Can I export my optimized resume?",
                  answer: "Yes! Export your resume in multiple formats including PDF, Word, and plain text. Each format is optimized for its specific use case, whether it's for human readers or ATS systems."
                },
                {
                  question: "How accurate is the ATS optimization?",
                  answer: "Our AI is trained on millions of successful resumes and current ATS systems. It achieves over 90% accuracy in keyword optimization while maintaining natural, professional language."
                },
                {
                  question: "Do you offer resume templates?",
                  answer: "Yes, we provide a variety of ATS-friendly templates designed for different industries and experience levels. Each template is fully customizable to match your personal style."
                }
              ].map((faq, index) => (
                <div 
                  key={index}
                  className={`faq-item ${activeFaqIndex === index ? 'active' : ''}`}
                >
                  <div 
                    className="faq-question"
                    onClick={() => handleFaqClick(index)}
                  >
                    <h3>{faq.question}</h3>
                    <span className="faq-icon">+</span>
                  </div>
                  <div className="faq-answer">
                    <p>{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default ResumeTailoringPage; 