import React, { useState, useEffect, useRef } from "react";
import "../../styles/components/landing-pages/landingPage.scss";
import Navbar from "./Navbar";
import SignUpComponent from "./SignUpComponent";
import CompanyLogos from "./CompanyLogos";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import JobProcess from "../../animations/components/JobProcess";
import jobMatching from "../../assets/jobMatching.png";
import atsResume from "../../assets/atsResume.svg";
import extension from "../../assets/extension.svg";
import portfolio from "../../assets/portfolio.svg";
import featureImage5 from "../../assets/feature5.png";
import progress from '../../animations/assets/progress.svg';
import ProgressIndicator from "../../animations/components/ProgressIndicator";
import companyLogo from "../../animations/assets/companyLogo.svg";

const LandingPage: React.FC = () => {
  const [scrollY, setScrollY] = useState(0);
  const [currentFeature, setCurrentFeature] = useState<number | null>(null);
  const [previousFeature, setPreviousFeature] = useState<number | null>(null);
  const [isImageVisible, setImageVisible] = useState(false);
  const [hasScrolledOut, setHasScrolledOut] = useState(false);
  const featureRefs = useRef<HTMLDivElement[]>([]);
  const featureHighlightRef = useRef<HTMLDivElement>(null);
  const [isLeavingFeatureSection, setIsLeavingFeatureSection] = useState(false);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(null);
  const lastScrollY = useRef(0);
  const [isFirstFeatureMoving, setIsFirstFeatureMoving] = useState(false);
  const [isLastFeatureMoving, setIsLastFeatureMoving] = useState(false);
  const [featureScrollOffset, setFeatureScrollOffset] = useState(0);
  const [isEnteringSection, setIsEnteringSection] = useState(false);
  const [isExitingSection, setIsExitingSection] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [isFeatureScrolling, setIsFeatureScrolling] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  // Simple progress state - completely separate from features
  const [progressState, setProgressState] = useState<{
    currentView: 'jobDetails' | 'resume' | 'coverLetter';
    phase: 'all' | 'few' | 'many';
    showModal: boolean;
  }>({
    currentView: 'jobDetails',
    phase: 'all',
    showModal: false
  });

  // Isolated timer for progress animation with adjusted timing
  useEffect(() => {
    const timer1 = setTimeout(() => {
      setProgressState({
        currentView: 'resume',
        phase: 'many',
        showModal: true
      });
    }, 4000); // Faster transition to resume

    const timer2 = setTimeout(() => {
      setProgressState({
        currentView: 'coverLetter',
        phase: 'many',
        showModal: true
      });
    }, 7000); // Faster transition to cover letter

    const timer3 = setTimeout(() => {
      setProgressState({
        currentView: 'coverLetter', // Keep as coverLetter but will show as "done" in ProgressIndicator
        phase: 'many',
        showModal: true
      });
    }, 10000); // Add final step that shows company logo

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, []);

  // IntersectionObserver to update currentFeature
  useEffect(() => {
    const featureSections = featureRefs.current;
    const intersectingFeatures = new Map<number, number>();

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = featureRefs.current.indexOf(
            entry.target as HTMLDivElement
          );
          console.log('Feature intersection:', index, entry.isIntersecting, entry.intersectionRatio);

          if (entry.isIntersecting) {
            intersectingFeatures.set(index, entry.intersectionRatio);
            (entry.target as HTMLDivElement).classList.add("in-view");
          } else {
            intersectingFeatures.delete(index);
            (entry.target as HTMLDivElement).classList.remove("in-view");
          }
        });

        if (intersectingFeatures.size > 0) {
          let mostVisibleFeatureIndex = -1;
          let maxRatio = -1;
          intersectingFeatures.forEach((ratio, index) => {
            if (ratio > maxRatio) {
              maxRatio = ratio;
              mostVisibleFeatureIndex = index;
            }
          });

          if (
            mostVisibleFeatureIndex !== -1 &&
            mostVisibleFeatureIndex !== currentFeature
          ) {
            console.log('Changing current feature to:', mostVisibleFeatureIndex);
            setPreviousFeature(currentFeature);
            setCurrentFeature(mostVisibleFeatureIndex);
          }
        } else {
          if (currentFeature !== null) {
            console.log('No features visible, resetting');
            setPreviousFeature(currentFeature);
            setCurrentFeature(null);
          }
        }
      },
      {
        root: null,
        threshold: 0.5,
      }
    );

    featureSections.forEach((section) => observer.observe(section));
    return () => observer.disconnect();
  }, [currentFeature]);

  // Reset previousFeature after animation
  useEffect(() => {
    if (previousFeature !== null && previousFeature !== currentFeature) {
      const timeout = setTimeout(() => {
        setPreviousFeature(null);
      }, 600);

      return () => clearTimeout(timeout);
    }
  }, [previousFeature, currentFeature]);

  // Scroll handler to control image visibility
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth >= 769) {
        setScrollY(window.scrollY);
        console.log('Scroll Y:', window.scrollY);

        const lastFeatureIndex = featureRefs.current.length - 1;
        if (currentFeature === lastFeatureIndex && featureRefs.current[lastFeatureIndex]) {
          const featureElement = featureRefs.current[lastFeatureIndex];
          const rect = featureElement.getBoundingClientRect();
          const featureCenter = rect.top + rect.height / 2;
          const distanceToTop = featureCenter - window.innerHeight / 2;
          console.log('Feature center distance:', distanceToTop);

          const threshold = 100;
          if (distanceToTop < threshold && distanceToTop > -threshold) {
            console.log('Setting image visible');
            setImageVisible(true);
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isImageVisible, currentFeature]);

  // Reset visibility when scrolling out
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            setImageVisible(false);
            setHasScrolledOut(true);
          } else {
            if (hasScrolledOut) {
              setHasScrolledOut(false);
            }
          }
        });
      },
      {
        root: null,
        threshold: 0,
      }
    );

    if (featureHighlightRef.current) {
      observer.observe(featureHighlightRef.current);
    }

    return () => {
      if (featureHighlightRef.current) {
        observer.unobserve(featureHighlightRef.current);
      }
    };
  }, [hasScrolledOut]);

  // Track scroll direction
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY.current) {
        setScrollDirection('down');
      } else if (currentScrollY < lastScrollY.current) {
        setScrollDirection('up');
      }
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Handle feature section exit
  useEffect(() => {
    const handleFeatureExit = () => {
      if (window.innerWidth >= 769) {
        const featureSection = featureHighlightRef.current;
        if (!featureSection) return;

        const rect = featureSection.getBoundingClientRect();
        const isAboutToLeave = 
          (scrollDirection === 'up' && currentFeature === 0 && rect.bottom <= window.innerHeight) ||
          (scrollDirection === 'down' && currentFeature === featureRefs.current.length - 1 && rect.top >= 0);

        setIsLeavingFeatureSection(isAboutToLeave);
      }
    };

    window.addEventListener('scroll', handleFeatureExit);
    return () => window.removeEventListener('scroll', handleFeatureExit);
  }, [currentFeature, scrollDirection]);

  // Handle feature movement
  useEffect(() => {
    const handleFeatureMovement = () => {
      if (window.innerWidth >= 769) {
        const firstFeature = featureRefs.current[0];
        const lastFeature = featureRefs.current[featureRefs.current.length - 1];
        
        if (firstFeature && lastFeature) {
          const firstRect = firstFeature.getBoundingClientRect();
          const lastRect = lastFeature.getBoundingClientRect();
          const viewportCenter = window.innerHeight / 2;

          if (currentFeature === 0) {
            const distanceFromCenter = firstRect.top + firstRect.height / 2 - viewportCenter;
            setIsFirstFeatureMoving(true);
            setFeatureScrollOffset(distanceFromCenter);
          } else {
            setIsFirstFeatureMoving(false);
          }

          if (currentFeature === featureRefs.current.length - 1) {
            const distanceFromCenter = lastRect.top + lastRect.height / 2 - viewportCenter;
            setIsLastFeatureMoving(true);
            setFeatureScrollOffset(distanceFromCenter);
          } else {
            setIsLastFeatureMoving(false);
          }
        }
      }
    };

    window.addEventListener('scroll', handleFeatureMovement);
    return () => window.removeEventListener('scroll', handleFeatureMovement);
  }, [currentFeature]);

  // Handle section entry/exit
  useEffect(() => {
    const handleSectionMovement = () => {
      if (window.innerWidth >= 769) {
        const featureSection = featureHighlightRef.current;
        if (!featureSection) return;

        const rect = featureSection.getBoundingClientRect();
        const viewportCenter = window.innerHeight / 2;

        if (currentFeature === 0) {
          const firstFeature = featureRefs.current[0];
          if (firstFeature) {
            const featureRect = firstFeature.getBoundingClientRect();
            const distanceFromCenter = featureRect.top + featureRect.height / 2 - viewportCenter;
            
            setIsEnteringSection(true);
            setScrollOffset(distanceFromCenter);
            
            if (Math.abs(distanceFromCenter) < 50) {
              setIsEnteringSection(false);
            }
          }
        }

        const lastIndex = featureRefs.current.length - 1;
        if (currentFeature === lastIndex) {
          const lastFeature = featureRefs.current[lastIndex];
          if (lastFeature) {
            const featureRect = lastFeature.getBoundingClientRect();
            const distanceFromCenter = featureRect.top + featureRect.height / 2 - viewportCenter;
            
            setIsExitingSection(true);
            setScrollOffset(distanceFromCenter);
            
            if (Math.abs(distanceFromCenter) < 50) {
              setIsExitingSection(false);
            }
          }
        }
      }
    };

    window.addEventListener('scroll', handleSectionMovement);
    return () => window.removeEventListener('scroll', handleSectionMovement);
  }, [currentFeature]);

  // Handle smooth scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth >= 769) {
        const viewportCenter = window.innerHeight / 2;
        const currentFeatureEl = featureRefs.current[currentFeature ?? 0];
        const lastFeatureIndex = featureRefs.current.length - 1;
        const featureSection = featureHighlightRef.current;
        
        if (currentFeatureEl && featureSection) {
          const featureRect = currentFeatureEl.getBoundingClientRect();
          const textCenter = featureRect.top + featureRect.height / 2;
          const distanceFromCenter = textCenter - viewportCenter;
          console.log('Smooth scroll:', { 
            currentFeature, 
            distanceFromCenter,
            isFeatureScrolling,
            scrollPosition
          });

          if (currentFeature === 0) {
            requestAnimationFrame(() => {
              if (textCenter > window.innerHeight) {
                setImageVisible(false);
              } else {
                setImageVisible(true);
              }
              
              if (textCenter > viewportCenter) {
                setIsFeatureScrolling(true);
                setScrollPosition(distanceFromCenter);
              } else {
                setIsFeatureScrolling(false);
                setScrollPosition(0);
              }
            });
          }
          else if (currentFeature === lastFeatureIndex) {
            requestAnimationFrame(() => {
              const isAboveViewport = textCenter < 0;
              
              if (isAboveViewport) {
                setImageVisible(false);
              } else {
                setImageVisible(true);
              }
              
              if (textCenter < viewportCenter) {
                setIsFeatureScrolling(true);
                setScrollPosition(distanceFromCenter);
              } else {
                setIsFeatureScrolling(false);
                setScrollPosition(0);
              }
            });
          }
          else {
            setIsFeatureScrolling(false);
            setScrollPosition(0);
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [currentFeature]);

  // Mobile feature animations
  useEffect(() => {
    if (window.innerWidth <= 768) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              requestAnimationFrame(() => {
                entry.target.classList.add('visible');
              });
              observer.unobserve(entry.target);
            }
          });
        },
        {
          root: null,
          threshold: 0.2,
          rootMargin: '0px'
        }
      );

      document.querySelectorAll('.feature-mobile-image').forEach((feature) => {
        feature.classList.add('animate');
        observer.observe(feature);
      });

      document.querySelectorAll('.feature-text').forEach((feature) => {
        feature.classList.add('animate');
        observer.observe(feature);
      });

      return () => observer.disconnect();
    }
  }, []);

  const featureImages = [
    jobMatching,
    atsResume,
    extension,
    portfolio,
    featureImage5,
  ];

  return (
    <>
      <div className={`landing-container ${scrollY > 50 ? "scrolled" : ""}`}>
        <Navbar />
        <section className="hero-section">
          <div className="hero-content">
            <h1 className="supercharge-your-job">
              Supercharge Your Job Search with AI
            </h1>
            <p className="enhance-your-application">
              <b>Enhance your application</b> with tailored resumes,
              personalized cover letters,
              <b> ensure ATS compatibility</b>, and comprehensive job search
              tools—all powered by our AI-driven platform.
            </p>
            <Link to="/auth/signup" className="cta-btn">
              Get Started - It's Free
            </Link>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="hero-image-container" style={{ border: 'none', background: 'transparent', boxShadow: 'none' }}>
              <JobProcess />
            </div>
            {/* Commenting out progress indicator for now
            <div style={{ marginTop: '20px', width: '500px', height: '80px' }}>
              <ProgressIndicator 
                currentView={progressState.currentView}
                phase={progressState.phase}
                showModal={progressState.showModal}
              />
            </div>
            */}
          </div>
        </section>
        <section className={`credibility-section ${scrollY > 400 ? "visible" : ""}`}>
          <CompanyLogos />
        </section>

        {/* Feature Highlight Section */}
        <section className="feature-highlight-section" ref={featureHighlightRef}>
          <div className="scrolling-content">
            <div className="feature-section" ref={(el) => (featureRefs.current[0] = el!)}>
              <div className="feature-text">
                <h2>The Perfect Job, Instantly</h2>
                <p>
                  Our AI matches your skills and experience to the ideal opportunities. No more endless scrolling.
                </p>
              </div>
              <div className="feature-mobile-image">
                <img src={jobMatching} alt="Feature Visual" />
              </div>
            </div>

            <div className="feature-section" ref={(el) => (featureRefs.current[1] = el!)}>
              <div className="feature-text">
                <h2>Elevate Your Resume and Cover Letter with AI Precision</h2>
                <p>
                  QuickCruit transforms your applications to stand out
                  to recruiters and effortlessly pass ATS filters.
                </p>
              </div>
              <div className="feature-mobile-image">
                <img src={atsResume} alt="Feature Visual" style={{ maxWidth: '100%', height: 'auto' }} />
              </div>
            </div>

            <div className="feature-section" ref={(el) => (featureRefs.current[2] = el!)}>
              <div className="feature-text">
                <h2>Apply in Seconds</h2>
                <p>
                  QuickCruit auto-fills your job applications, ensuring accuracy
                  and saving you hours of tedious work.
                </p>
              </div>
              <div className="feature-mobile-image">
                <img src={extension} alt="Feature Visual" style={{ maxWidth: '100%', height: 'auto' }} />
              </div>
            </div>

            <div className="feature-section" ref={(el) => (featureRefs.current[3] = el!)}>
              <div className="feature-text">
                <h2>Turn Your Profile into a Portfolio</h2>
                <p>
                  Highlight your skills, achievements, and experience with a
                  shareable portfolio—no sign-in needed.
                </p>
              </div>
              <div className="feature-mobile-image">
                <img src={portfolio} alt="Feature Visual" style={{ maxWidth: '100%', height: 'auto' }} />
              </div>
            </div>

            {/* <div className="feature-section" ref={(el) => (featureRefs.current[4] = el!)}>
              <div className="feature-text">
                <h2>Your Data, Protected</h2>
                <p>
                  Your privacy is our priority. We never share your information,
                  and you control what's public.
                </p>
              </div>
              <div className="feature-mobile-image">
                <img src={featureImage5} alt="Feature Visual" />
              </div>
            </div> */}
          </div>

          {/* Right-hand side image */}
          {window.innerWidth >= 769 && isImageVisible && (
            <div
              className={`static-image-container ${isFeatureScrolling ? "scrolling" : ""}`}
              style={{
                '--scroll-y': `${scrollPosition}px`
              } as React.CSSProperties}
            >
              {currentFeature !== null && (
                <img
                  key={`feature-${currentFeature}`}
                  src={featureImages[currentFeature]}
                  alt="Feature Visual"
                  className="feature-image"
                />
              )}
            </div>
          )}
        </section>
        <SignUpComponent />
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;