import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { Dialog, DialogContent } from "./ui/Dialog";
import { DialogTrigger } from "@radix-ui/react-dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/Form";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/Popover";
import { cn } from "../lib/utils";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./ui/Command";
import { Check, ChevronsUpDown } from "lucide-react";
import "../styles/components/mockInterview.scss";
import PrimaryButton from "./ui/PrimaryButton";
import { InterviewTable } from "./AiMockInterview/Interview-table/interview-table";
import { Interview, columns } from "./AiMockInterview/Interview-table/columns";
import { useNavigate } from 'react-router-dom';
import { Input } from "./ui/Input";

const formSchema = z.object({
  job: z.string().min(1, "Job title is required"),
  duration: z.number().min(1, "Duration is required"),
  language: z.string().min(1, "Language is required"),
});

type FormData = z.infer<typeof formSchema>;

const languages = [
  { label: "English", value: "en" },
  { label: "French", value: "fr" },
  { label: "German", value: "de" },
  { label: "Spanish", value: "es" },
  { label: "Portuguese", value: "pt" },
  { label: "Russian", value: "ru" },
  { label: "Japanese", value: "ja" },
  { label: "Korean", value: "ko" },
  { label: "Chinese", value: "zh" },
] as const;

const getData = async (): Promise<Interview[]> => {
  // Fetch data from your API here.
  return [
    {
      id: "728ed52f",
      jobTitle: "Frontend Developer",
      language: "English",
      duration: 30,
      status: "completed",
      date: "2023-08-01",
    },
    {
      id: "728ed53f",
      jobTitle: "Backend Developer",
      language: "Spanish",
      duration: 45,
      status: "scheduled",
      date: "2023-08-15",
    },
    // Add more mock data as needed
  ];
};

const AiMockInterview = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Interview[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getData();
      setData(result);
    };

    fetchData();
  }, []);

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      job: "",
      duration: 30,
      language: "",
    },
  });

  const onSubmit = async (values: FormData) => {
    try {
      setIsLoading(true);
      const response = await fetch('/api/interviews', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error('Failed to create interview');
      }

      const interview = await response.json();
      navigate(`/interview/${interview._id}`);
    } catch (error) {
      console.error('Error creating interview:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mock-interview-container">
      <Sidebar />
      <div className="mock-interview">
        <div className="flex justify-between w-full">
          <div>
            <h3>AI Mock Interview</h3>
          </div>
          <div>
            <Dialog>
              <DialogTrigger>Create Mock Interview</DialogTrigger>
              <DialogContent>
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                    <FormField
                      control={form.control}
                      name="language"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Language</FormLabel>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <PrimaryButton
                                  role="combobox"
                                  className={cn(
                                    "w-[200px] justify-between",
                                    !field.value && "text-muted-foreground"
                                  )}
                                >
                                  {field.value
                                    ? languages.find(
                                        (language) => language.value === field.value
                                      )?.label
                                    : "Select language"}
                                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </PrimaryButton>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-[200px] p-0">
                              <Command>
                                <CommandInput placeholder="Search language..." />
                                <CommandList>
                                  <CommandEmpty>No language found.</CommandEmpty>
                                  <CommandGroup>
                                    {languages.map((language) => (
                                      <CommandItem
                                        value={language.label}
                                        key={language.value}
                                        onSelect={() => {
                                          form.setValue("language", language.value);
                                        }}
                                      >
                                        {language.label}
                                        <Check
                                          className={cn(
                                            "ml-auto",
                                            language.value === field.value
                                              ? "opacity-100"
                                              : "opacity-0"
                                          )}
                                        />
                                      </CommandItem>
                                    ))}
                                  </CommandGroup>
                                </CommandList>
                              </Command>
                            </PopoverContent>
                          </Popover>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="job"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Job Title</FormLabel>
                          <FormControl>
                            <Input {...field} placeholder="Enter job title" />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="duration"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Duration (minutes)</FormLabel>
                          <FormControl>
                            <Input
                              type="number"
                              {...field}
                              onChange={(e) => field.onChange(parseInt(e.target.value))}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <PrimaryButton type="submit" disabled={isLoading}>
                      {isLoading ? "Creating..." : "Start Interview"}
                    </PrimaryButton>
                  </form>
                </Form>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <div className="mt-10 w-full border-black rounded-sm">
          <InterviewTable columns={columns} data={data} />
        </div>
      </div>
    </div>
  );
};

export default AiMockInterview;
