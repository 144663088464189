import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import "../../styles/components/landing-pages/pricing.scss";
import { FaCheck, FaGift } from 'react-icons/fa';
import { HiOutlineCheck } from 'react-icons/hi';

const Pricing = () => {
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'quarterly'>('monthly');

  const pricingData = {
    free: {
      monthly: 0,
      quarterly: 0,
    },
    pro: {
      monthly: 20,
      quarterly: 54, // $18/month, billed as $54 for 3 months
    }
  };

  const getPriceDisplay = (plan: 'free' | 'pro') => {
    if (plan === 'free') {
      return {
        amount: 0,
        period: '/month',
        billing: 'Free forever'
      };
    }
    
    if (billingCycle === 'monthly') {
      return {
        amount: pricingData[plan].monthly,
        period: '/month',
        billing: 'Pay monthly'
      };
    } else {
      return {
        amount: 18,
        period: '/month',
        billing: 'Save 10% with quarterly plan'
      };
    }
  };

  return (
    <>
      <Navbar />
      <div className="pricing-page-second">
        <div className="pricing-container">
          <h1>Make every application count</h1>
          <p className="description">From smart job matching to AI-powered applications - tools that get you noticed</p>

          <div className="billing-toggle" data-active={billingCycle}>
            <button 
              className={`toggle-btn ${billingCycle === 'monthly' ? 'active' : ''}`}
              onClick={() => setBillingCycle('monthly')}
            >
              Monthly
            </button>
            <button 
              className={`toggle-btn ${billingCycle === 'quarterly' ? 'active' : ''}`}
              onClick={() => setBillingCycle('quarterly')}
            >
              3&nbsp;Months
            </button>
          </div>

          <div className="pricing-cards">
            {/* Free Plan */}
            <div className="pricing-card">
              <div className="card-header">
                <h2 className="pricing-tier-title">Free</h2>
                <p className="description">Start your job search journey with essential tools and features.</p>
                <div className="price">
                  <span className="amount">${getPriceDisplay('free').amount}</span>
                  <span className="period">{getPriceDisplay('free').period}</span>
                </div>
                <div className="billing-info">{getPriceDisplay('free').billing}</div>
                <div className="button-container">
                  <Link to="/auth/signup" className="signup-button secondary">
                    Get Started
                  </Link>
                  <span className="placeholder-text"></span>
                </div>
              </div>
              <ul className="features">
                <li className="placeholder-feature">
                  <span className="check-icon-placeholder"></span>
                  <span className="text-placeholder"></span>
                </li>
                <li>
                  <HiOutlineCheck className="check-icon" />
                  Tailored Job Matches
                </li>
                <li>
                  <HiOutlineCheck className="check-icon" />
                  Job Tracking 
                </li>
                <li>
                  <HiOutlineCheck className="check-icon" />
                  Shareable Portfolio
                </li>
              </ul>
            </div>

            {/* Pro Plan */}
            <div className="pricing-card pro">
              <div className="card-header">
                <h2 className="pricing-tier-title">Pro</h2>
                <p className="description">Unlock AI-powered tools to maximize your job search success.</p>
                <div className="price">
                  <span className="amount">${getPriceDisplay('pro').amount}</span>
                  <span className="period">{getPriceDisplay('pro').period}</span>
                </div>
                <div className="billing-info">{getPriceDisplay('pro').billing}</div>
                <div className="button-container">
                  <Link to="/auth/signup" className="signup-button primary">
                    Start Free Trial
                  </Link>
                  <span className="no-card-text">No credit card required</span>
                </div>
              </div>
              <ul className="features">
                <li>
                  <FaGift className="check-icon gift" />
                  7-day free trial included
                </li>
                <li>
                  <HiOutlineCheck className="check-icon" />
                  Everything in Free
                </li>
                <li>
                  <HiOutlineCheck className="check-icon" />
                  AI Resume Tailoring
                </li>
                <li>
                  <HiOutlineCheck className="check-icon" />
                  AI Cover Letter Generator
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
