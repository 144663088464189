import React, { useState, useEffect, useRef } from "react";
import "../styles/components/resumeComponent.scss";
import "react-circular-progressbar/dist/styles.css";
import axiosClient from "../axios-client";
import Resume from "./Resume";
import Suggestions from "./Suggestions";
import { Bounce, toast } from "react-toastify";
import axios from "axios";
import { mainUrl } from "../constant";

export interface Education {
  degree: string;
  field: string;
  university: string;
  graduationDate: string; // Match the backend
  gpa?: string;
}

export interface Experience {
  title: string;
  company: string;
  location: string;
  startDate: string;
  endDate: string | null;
  isCurrentPosition: boolean;
  description: { id: string; text: string }[];
}

export interface Project {
  title: string;
  project_name: string;
  startDate: string;
  endDate: string | null;
  isOngoing: boolean;
  description: { id: string; text: string }[];
}

export interface ResumeText {
  firstName?: string;
  lastName?: string;
  email: string;
  phone: string;
  linkedin: string;
  website: string;
  location: string;
  citizenship: string;
  education: Education[];
  experience: Experience[];
  projects: Project[];
  skills: any;
  interests: string[];
}

interface ResumeComponentProps {
  jobDescription: any;
  resumeText: ResumeText;
  handleTailorButtonClick: () => void;
  resumeId: string;
  jobId: string;
  onNavigateToCoverLetter: () => void;
  hasBeenTailored: boolean;
  setHasBeenTailored: (value: boolean) => void;
  cachedContent: { suggestions: any[]; score: number | null } | null;
  setCachedContent: (
    content: { suggestions: any[]; score: number | null } | null
  ) => void;
}

export interface BulletPointSuggestion {
  id: string;
  original: string;
  suggested: string;
  isLoading: boolean;
  expIndex: number;
  descId: string;
}

interface BulletPoint {
  id: string;
  text: string;
}

const ResumeComponent: React.FC<ResumeComponentProps> = ({
  jobDescription,
  resumeText,
  handleTailorButtonClick,
  resumeId,
  jobId,
  onNavigateToCoverLetter,
  hasBeenTailored,
  setHasBeenTailored,
  cachedContent,
  setCachedContent,
}) => {
  const [revisedResume, setRevisedResume] = useState<ResumeText>(resumeText);
  const [isEditingLocked, setIsEditingLocked] = useState<boolean>(false);
  const [isResumeTailored, setIsResumeTailored] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<BulletPointSuggestion[]>([]);
  const [score, setScore] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [hasTailored, setHasTailored] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState<string | null>(null);
  const [selectedBulletPoint, setSelectedBulletPoint] =
    useState<BulletPointSuggestion | null>(null);
  const resumeRef = useRef(null);
  const [loading, setLoading] = useState<boolean>(true);

  // New state variables for responsiveness
  const [isSuggestionsCollapsed, setIsSuggestionsCollapsed] =
    useState<boolean>(false);
  const [isSuggestionsPopupOpen, setIsSuggestionsPopupOpen] =
    useState<boolean>(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [expandedBulletPoint, setExpandedBulletPoint] = useState<{
    expIndex: number;
    descId: string;
  } | null>(null);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  useEffect(() => {
    // If we have cached content, use it instead of loading
    if (cachedContent) {
      setSuggestions(cachedContent.suggestions);
      setScore(cachedContent.score);
      setLoading(false);
      setIsEditingLocked(false);
      return;
    }

    // Only fetch if not already tailored and no cached content
    if (!hasBeenTailored) {
      fetchSuggestions();
    } else {
      setLoading(false);
    }
  }, [hasBeenTailored, cachedContent]);

  console.log('ressumeeeiD', resumeId)

  const fetchSuggestions = async () => {
    if (hasBeenTailored) {
      return;
    }

    const user = JSON.parse(String(localStorage.getItem("user")));
    setLoading(true);
    try {
      setIsEditingLocked(true);
      const description = `title: ${jobDescription.original_title} responsibilities: ${jobDescription.responsibilities} qualification: ${jobDescription.qualifications} skills: ${jobDescription.skills}`;
      const response = await axiosClient.post("builder/resume-builder", {
        jobDescription: description,
        userDetails: JSON.stringify(user),
      });

      const transformedSuggestions = response.data.bullet_points.map(
        (item: any) => ({
          id: item.id,
          original: item.text,
          suggested: item.text,
          isLoading: false,
          expIndex: 0,
          descId: item.id,
        })
      );

      setSuggestions(transformedSuggestions);
      setScore(response.data.ATS_score);
      setHasBeenTailored(true);
      setLoading(false);
      setIsEditingLocked(false);

      // Cache the results
      setCachedContent({
        suggestions: transformedSuggestions,
        score: response.data.ATS_score,
      });
    } catch (err) {
      setLoading(false);
      setIsEditingLocked(false);
      console.error("Error in fetchSuggestions:", err);
      setError("Failed to fetch suggestions. Please try again.");
    }
  };

  const handleToggleDropdown = (id: string) => {
    setExpandedIndex(expandedIndex === id ? null : id);
  };

  const handleFieldChange = (updater: (prev: ResumeText) => ResumeText) => {
    if (!isEditingLocked) {
      setRevisedResume(updater);
    }
  };

  const handleAcceptSingleSuggestion = async (
    suggestion: string,
    suggestionId: string
  ) => {
    try {
      setLoading(true);
      let found = false;

      const updatedExperience = revisedResume.experience.map((experience) => {
        const updatedDescriptions = experience.description.map((bullet) => {
          if (bullet.id === suggestionId && !found) {
            found = true;
            return { ...bullet, text: suggestion };
          }
          return bullet;
        });
        return { ...experience, description: updatedDescriptions };
      });

      const updatedProjects = revisedResume.projects.map((project) => {
        const updatedDescriptions = project.description.map((bullet) => {
          if (bullet.id === suggestionId && !found) {
            found = true;
            return { ...bullet, text: suggestion };
          }
          return bullet;
        });
        return { ...project, description: updatedDescriptions };
      });

      if (found) {
        // Create the updated resume object
        const updatedResume = {
          ...revisedResume,
          experience: updatedExperience,
          projects: updatedProjects,
        };

        // Update state and wait for it to complete
        setRevisedResume(updatedResume);

        // Save the updated resume directly
        await saveResume(updatedResume);

        // Update suggestions list
        const updatedSuggestions = suggestions.filter(
          (item) => item.id !== suggestionId
        );
        setSuggestions(updatedSuggestions);

        setLoading(false);
        toast("Tailored Your Resume Successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else {
        throw new Error("Failed to find matching bullet point to replace");
      }
    } catch (error: unknown) {
      setLoading(false);
      if (error instanceof Error) {
        toast(error.message || "Something went wrong", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
        });
      }
    }
  };

  const handleDownload = async () => {
    try {
      const response = await axiosClient.post(
        "/pdf/generate-pdf",
        revisedResume
      );

      if (response.data && response.data.downloadUrl) {
        window.open(`${mainUrl}${response.data.downloadUrl}`, '_blank');

        const link = document.createElement("a");
        link.href = response.data.downloadUrl;
        link.download = `${revisedResume.firstName}_${revisedResume.lastName}_resume.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        throw new Error('Download URL not received from server');
      }
    } catch (error: unknown) {
      console.error("Error generating PDF:", error);
      if (axios.isAxiosError(error) && error.response) {
        console.error("Error response:", error.response.data);
        toast.error(`Error: ${error.response.data.message || "Unknown error"}`);
      } else {
        toast.error("An error occurred while generating the PDF. Please try again.");
      }
    }
  };

  // Modify saveResume to accept a parameter
  const saveResume = async (resumeToSave = revisedResume as any) => {
    try {
      const user = JSON.parse(String(localStorage.getItem("user")));
      resumeToSave.userId = user._id;
      resumeToSave.jobId = jobId;
      if (resumeId) {
        const response = await axiosClient.put(
          `/builder/update-resume/${resumeId}`,
          resumeToSave
        );
        console.log("Resume updated:", response.data);
        toast("Resume updated successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else {
        delete resumeToSave._id;
        const response = await axiosClient.post(
          `/builder/create-resume`,
          resumeToSave
        );
        console.log("Resume saved:", response.data);
        toast("Resume saved successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (error: unknown) {
      console.error("Error saving resume:", error);
    }
  };

  const handleTailorBulletPoint = async (expIndex: number, descId: string) => {
    const bulletPoint = revisedResume.experience[expIndex].description.find(
      (desc) => desc.id === descId
    );

    if (!bulletPoint) return;

    try {
      setSelectedBulletPoint({
        id: bulletPoint.id,
        original: bulletPoint.text,
        suggested: "",
        isLoading: true,
        expIndex,
        descId,
      });

      const description = `title: ${jobDescription.original_title} responsibilities: ${jobDescription.responsibilities} qualification: ${jobDescription.qualifications} skills: ${jobDescription.skills}`;
      const response = await axiosClient.post("builder/tailor-bullets", {
        bulletPoint: bulletPoint.text,
        jobDescription: description,
      });

      setSelectedBulletPoint({
        id: bulletPoint.id,
        original: bulletPoint.text,
        suggested: response.data,
        isLoading: false,
        expIndex,
        descId,
      });
    } catch (err) {
      setError(
        "Failed to fetch bullet point suggestion. Please try again later."
      );
      setSelectedBulletPoint(null);
    }
  };

  const handleAcceptSuggestion = async (expIndex: number, descId: string) => {
    if (selectedBulletPoint && !selectedBulletPoint.isLoading) {
      handleFieldChange((prev) => {
        const updatedExperience = [...prev.experience];
        const bulletIndex = updatedExperience[expIndex].description.findIndex(
          (bullet) => bullet.id === descId
        );

        if (bulletIndex !== -1) {
          updatedExperience[expIndex].description[bulletIndex].text =
            selectedBulletPoint.suggested;
        }

        return { ...prev, experience: updatedExperience };
      });
      await saveResume();
      toast("Suggestion Added to your Resume Successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setSelectedBulletPoint(null);
    }
  };

  const handleRejectSuggestion = () => {
    setSelectedBulletPoint(null);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSuggestionsCollapsed(true);
      } else {
        setIsSuggestionsCollapsed(false);
        setIsSuggestionsPopupOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1200) {
        setIsSidebarCollapsed(true);
        setIsSidebarExpanded(false);
      } else {
        setIsSidebarCollapsed(false);
        setIsSidebarExpanded(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        isSidebarExpanded &&
        !target.closest(".expanded-sidebar") &&
        !target.closest(".collapsed-sidebar")
      ) {
        setIsSidebarExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isSidebarExpanded]);

  const handleCoverLetterClick = async () => {
    // First, handle the tab changes
    await handleTailorButtonClick();

    // Use setTimeout to ensure the scroll happens after rendering
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "auto" }); // Use 'auto' for immediate scroll
    }, 0);

    // Finally, navigate to cover letter tab
    onNavigateToCoverLetter();
  };

  return (
    <div className="resume-container">
          <div className="left-container">
            <Resume
              revisedResume={revisedResume}
              isEditingLocked={isEditingLocked}
              handleFieldChange={handleFieldChange}
              handleTailorBulletPoint={handleTailorBulletPoint}
              selectedBulletPoint={selectedBulletPoint}
              handleAcceptSuggestion={handleAcceptSuggestion}
              handleRejectSuggestion={handleRejectSuggestion}
              expandedBulletPoint={expandedBulletPoint}
            />
          </div>

          {!isSidebarCollapsed && (
            <div className="right-container">
              <Suggestions
                suggestions={suggestions}
                expandedIndex={expandedIndex}
                handleToggleDropdown={handleToggleDropdown}
                isHovered={isHovered}
                handleTailorButtonClick={handleCoverLetterClick}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                handleSingleSuggestion={handleAcceptSingleSuggestion}
                score={score}
                isResumeTailored={isResumeTailored}
                handleDownload={handleDownload}
                setSuggestions={setSuggestions}
                loading={loading}
                errorMessage={error}
                setExpandedBulletPoint={setExpandedBulletPoint}
              />
            </div>
          )}

          {isSidebarCollapsed && !isSidebarExpanded && (
            <div
              className="collapsed-sidebar"
              onClick={() => setIsSidebarExpanded(true)}
            >
       <div className="score-preview">
                {score && <div className="score">{score}</div>}
              </div>
            </div>
          )}

          {isSidebarExpanded && (
            <div className="expanded-sidebar">
              <div className="suggestions-container">
                <button
                  className="close-button"
                  onClick={() => setIsSidebarExpanded(false)}
                >
                  <span>×</span>
                </button>
                <Suggestions
                  suggestions={suggestions}
                  expandedIndex={expandedIndex}
                  handleToggleDropdown={handleToggleDropdown}
                  isHovered={isHovered}
                  handleTailorButtonClick={handleCoverLetterClick}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  handleSingleSuggestion={handleAcceptSingleSuggestion}
                  score={score}
                  isResumeTailored={isResumeTailored}
                  handleDownload={handleDownload}
                  setSuggestions={setSuggestions}
                  loading={loading}
                  errorMessage={error}
                  isInExpandedSidebar={true}
                  setExpandedBulletPoint={setExpandedBulletPoint}
                />
              </div>
            </div>
          )}
        </div>
  );
};

export default ResumeComponent;
