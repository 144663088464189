import React, { useState, KeyboardEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/components/auth.scss';
import logoImage from '../logo.svg';
import axiosClient from '../axios-client';
import { Bounce, toast } from 'react-toastify';
import { GoogleLogin } from '@react-oauth/google';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';

const commonDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com', 'icloud.com', 'aol.com'];
const commonTLDs = ['com', 'net', 'org', 'edu', 'gov', 'mil', 'int'];

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [suggestedDomain, setSuggestedDomain] = useState('');
  const [suggestedTLD, setSuggestedTLD] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axiosClient.post('/auth/login', { email, password });
      const { user, tokens } = response.data;

      localStorage.setItem('accessToken', tokens.access.token);
      localStorage.setItem('refreshToken', tokens.refresh.token);
      localStorage.setItem('user', JSON.stringify(user));
      toast('Login Success', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      if (!user.userOnboarding) {
        window.location.reload();
        navigate('/onboarding');
      }
      window.location.reload();
      navigate('/dashboard');
    } catch (error: any) {
      setError(error.response?.data?.message || 'An error occurred during login');
      toast(error.response?.data?.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setEmail(val);
    const { domain, tld } = getEmailSuggestion(val);
    setSuggestedDomain(domain);
    setSuggestedTLD(tld);
  };

  // If there's a suggestion, pressing Tab will autocomplete. Otherwise, Tab goes to the next field.
  const handleEmailKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Tab' &&
      !e.shiftKey && // don't interfere with Shift+Tab
      (suggestedDomain || suggestedTLD) // only autocomplete if a suggestion is present
    ) {
      e.preventDefault();
      if (suggestedDomain) {
        setEmail((prev) => prev + suggestedDomain);
        setSuggestedDomain('');
      } else if (suggestedTLD) {
        setEmail((prev) => prev + suggestedTLD);
        setSuggestedTLD('');
      }
    }
  };

  const getEmailSuggestion = (value: string) => {
    const atIndex = value.indexOf('@');
    if (atIndex === -1) return { domain: '', tld: '' };

    const afterAt = value.slice(atIndex + 1).toLowerCase();
    const dotIndex = afterAt.indexOf('.');

    // Handle domain suggestions (e.g. "@g" -> "gmail.com")
    if (dotIndex === -1) {
      if (afterAt.length > 0) {
        const matchedDomain = commonDomains.find((d) => d.startsWith(afterAt));
        if (matchedDomain) {
          // Return the remainder of matchedDomain after what's already typed
          return { domain: matchedDomain.slice(afterAt.length), tld: '' };
        }
      }
    } 
    // Handle TLD suggestions (e.g. "gmail.c" -> "gmail.com")
    else {
      const afterDot = afterAt.slice(dotIndex + 1);
      if (afterDot.length > 0) {
        const matchedTLD = commonTLDs.find((t) => t.startsWith(afterDot));
        if (matchedTLD) {
          // Return the remainder of matchedTLD after what's already typed
          return { domain: '', tld: matchedTLD.slice(afterDot.length) };
        }
      }
    }

    return { domain: '', tld: '' };
  };

  const handleGoogleSuccess = async (credentialResponse: any) => {
    try {
      const result = await axiosClient.post('/auth/google', {
        credential: credentialResponse.credential,
      });

      const { user, tokens } = result.data;
      localStorage.setItem('accessToken', tokens.access.token);
      localStorage.setItem('refreshToken', tokens.refresh.token);
      localStorage.setItem('user', JSON.stringify(user));

      toast('Google Sign-In Successful', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce
      });
      if (!user.userOnboarding) {
        window.location.reload();
        navigate('/onboarding');
      }

      window.location.reload();
      navigate('/dashboard');
    } catch (error: any) {
      toast(error.response?.data?.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  return (
    <div className="auth-wrapper">
      <header className="auth-header">
        <Link to="/">
          <img src={logoImage} alt="QuickCruit Logo" className="auth-logo" />
        </Link>
      </header>
      <div className="auth-container">
        <h2>Sign In</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="email-field-with-suggestion">
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              onKeyDown={handleEmailKeyDown}
              placeholder="Email"
              required
            />
            <div className="suggestion-overlay">
              <span className="typed-text">{email}</span>
              {(suggestedDomain || suggestedTLD) && (
                <span className="suggested-part">
                  {suggestedDomain || suggestedTLD}
                </span>
              )}
            </div>
          </div>
          <div className="password-field">
            <input
              type={passwordVisible ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <span onClick={() => setPasswordVisible(!passwordVisible)}>
              {!passwordVisible ? <HiOutlineEyeOff /> : <HiOutlineEye />}
            </span>
          </div>
          <button type="submit" className="auth-button">Sign In</button>
        </form>
        <p className="forgot-password">
          Trouble signing in? <Link to="/auth/forgot-password">Forgot password?</Link>
        </p>
        <div className="or-divider">Or continue with</div>
        <div className="google-button-container">
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            shape="rectangular"
            width="390"
            logo_alignment="center"
            text="signin_with"
            onError={() => {
              toast.error('Google Sign-In Failed');
            }}
            useOneTap
          />
        </div>
        <p className="signup-link">
          Don't have an account?<Link to="/auth/signup">Sign up</Link>
        </p>
      </div>
      <div className="terms-text">
        By continuing, you agree to our <a href="/terms">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>.
      </div>
    </div>
  );
};

export default Login;
