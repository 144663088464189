import React, { useEffect, useState, useRef, useCallback } from "react";
import "../../styles/components/landing-pages/jobPage.scss";
import Navbar from "./Navbar";
import ResumeHighlight from "../../animations/components/ResumeHighlight";
import feature2 from "../../assets/feature2.png";
import feature3 from "../../assets/feature3.png";
import feature4 from "../../assets/feature4.png";
import Footer from "./Footer";
import jobDescriptionIcon from "../../assets/job-details.svg";
import jobTile from "../../assets/job-tile.svg";
import { Link } from "react-router-dom";
import JobTile from "../../animations/components/JobTile";
import NewJobBenefits from "../../animations/components/NewJobBenefits";

const MockInterviewsPage = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const demoSectionRef = useRef<HTMLDivElement | null>(null);
  const heroSectionRef = useRef<HTMLElement | null>(null);
  const [demoSectionDimensions, setDemoSectionDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [heroHeight, setHeroHeight] = useState(0);
  const NAVBAR_HEIGHT = 88;
  const [isCaptionVisible, setIsCaptionVisible] = useState(false);
  const [hasExpanded, setHasExpanded] = useState(false);
  const [activeGroup, setActiveGroup] = useState<'days5' | 'days10' | 'days15' | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Get dimensions after component mounts
  useEffect(() => {
    if (demoSectionRef.current) {
      const rect = demoSectionRef.current.getBoundingClientRect();
      setDemoSectionDimensions({
        width: rect.width,
        height: rect.height,
      });
    }
    if (heroSectionRef.current) {
      const rect = heroSectionRef.current.getBoundingClientRect();
      setHeroHeight(rect.height);
    }
  }, []);

  // Handle scroll
  useEffect(() => {
    const handleScroll = () => {
      if (demoSectionRef.current) {
        const rect = demoSectionRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        
        const startScroll = rect.top - viewportHeight * 1.8;
        const endScroll = rect.top + (viewportHeight * 0.8);
        const scrollRange = endScroll - startScroll;
        
        const currentScroll = window.scrollY - startScroll;
        const progress = Math.max(0, Math.min(1, currentScroll / scrollRange));
        
        setScrollProgress(progress);
        
        if (progress >= 1 && !hasExpanded) {
          setHasExpanded(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    requestAnimationFrame(handleScroll);
    
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasExpanded]);

  // Calculate transforms
  const progress = scrollProgress || 0;

  // For hero section
  const heroTranslateY = -progress * 100;
  const heroOpacity = Math.max(1 - progress * 2, 0);

  // For demo section
  const maxScaleX = Math.min(5, window.innerWidth / (180 * 2)); // Using fixed base width
  const maxScaleY = Math.min(5, (window.innerHeight - NAVBAR_HEIGHT) / (180 * 2));
  const maxScale = Math.min(5, maxScaleX, maxScaleY); // Limit max scale to 5x

  const demoScale = 1 + (progress * (maxScale - 1)); // This makes it scale up smoothly
  const deltaHeight = demoSectionDimensions.height * (demoScale - 1);
  const demoTranslateY = progress * -50;

  useEffect(() => {
    if (progress >= 1 && !isCaptionVisible) {
      setIsCaptionVisible(true);
    }
  }, [progress]);

  useEffect(() => {
    if (progress >= 1 && !hasExpanded) {
      setHasExpanded(true);
    }
  }, [progress]);

  const calculateProgress = useCallback(() => {
    if (!demoSectionRef.current) return 0;
    
    const rect = demoSectionRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight - NAVBAR_HEIGHT;
    const elementTop = rect.top - NAVBAR_HEIGHT;
    
    const progress = Math.max(0, Math.min(1, 1 - (elementTop / viewportHeight)));
    
    return progress;
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Navbar />
      <div className="job-page-container">
        <section
          ref={heroSectionRef}
          className="job-features-hero-section"
        >
          <div className="hero-content">
            <h1 className="hero-heading">
              Practice Interviews with AI
            </h1>
            <p className="hero-subheading">
              Get ready for your next interview with our AI-powered mock interview platform. 
              Practice with industry-specific questions and receive instant feedback.
            </p>
            <Link to="/auth/signup" className="cta-btn">
              Get Started - It's Free
            </Link>
          </div>
          <div className="hero-image-container">
            <div className="resume-highlight-container">
              <ResumeHighlight />
            </div>
          </div>
        </section>

        <section className="job-demo-section" ref={demoSectionRef}>
          <div className="demo-container">
            <h2 className="demo-heading">Real-Time Interview Practice</h2>
            <div className="demo-animation-container">
              <img
                src={jobDescriptionIcon}
                alt="Mock Interview Demo"
                className={`demo-icon ${hasExpanded && !isMobile ? 'expanded' : ''}`}
                style={!hasExpanded && !isMobile ? { 
                  '--scale': `${1 + (progress * 5.67)}`
                } as React.CSSProperties : {}}
              />
            </div>
          </div>
        </section>

        <section className="job-features-section">
          <div className="feature-row">
            <div className="feature-content">
              <h2>Industry-Specific Questions</h2>
              <p>
                Practice with questions tailored to your industry and role, from technical 
                interviews to behavioral questions.
              </p>
            </div>
            <div className="feature-image">
              <NewJobBenefits />
            </div>
          </div>

          <div className="feature-row">
            <div className="feature-image">
              <img src={feature2} alt="Feedback feature" />
            </div>
            <div className="feature-content">
              <h2>Instant AI Feedback</h2>
              <p>
                Receive detailed feedback on your responses, including suggestions for 
                improvement and analysis of your communication style.
              </p>
            </div>
          </div>

          <div className="feature-row">
            <div className="feature-content">
              <h2>Progress Tracking</h2>
              <p>
                Monitor your improvement over time with detailed analytics and 
                performance metrics for each practice session.
              </p>
            </div>
            <div className="feature-image">
              <JobTile />
            </div>
          </div>

          <div className="feature-row">
            <div className="feature-image">
              <img src={feature4} alt="Recording feature" />
            </div>
            <div className="feature-content">
              <h2>Video Recording & Review</h2>
              <p>
                Record your practice interviews to review your body language and 
                presentation skills, with AI-powered suggestions for improvement.
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default MockInterviewsPage; 