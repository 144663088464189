import React, { useState, useEffect } from 'react';
import '../styles/components/applicationConfirmModal.scss';
import { useApplicationModal } from '../context/ApplicationModalContext';
import { addApplication } from '../services/applicationService';
import { toast } from 'react-hot-toast';

const ApplicationConfirmModal: React.FC = () => {
  const { showModal, jobTitle, companyName, jobId, setModalData, onConfirm } = useApplicationModal();
  const [isClosing, setIsClosing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (showModal) {
      setIsClosing(false);
    }
  }, [showModal]);

  const handleClose = async (didApply: boolean) => {
    if (didApply && jobId) {
      setIsSubmitting(true);
      try {
        await addApplication(jobId);
        toast.success('Application tracked successfully!');
        window.dispatchEvent(new Event('application-updated'));
        onConfirm(true);
      } catch (error) {
        console.error('Error tracking application:', error);
        toast.error('Failed to track application. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      onConfirm(false);
    }

    setIsClosing(true);
    setTimeout(() => {
      setModalData({ showModal: false });
    }, 200);
  };

  if (!showModal) return null;

  return (
    <div className={`application-confirm-backdrop ${isClosing ? 'closing' : ''}`}>
      <div className="application-confirm-content">
        <h2>Did you apply?</h2>
        <p>If you applied, we'll add it to your application tracker.</p>
        <div className="button-group">
          <button 
            className="keep-button" 
            onClick={() => handleClose(false)}
            disabled={isSubmitting}
          >
            No
          </button>
          <button 
            className="discard-button" 
            onClick={() => handleClose(true)}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Tracking...' : 'Yes'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApplicationConfirmModal; 