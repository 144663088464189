type LogoVariant = 'horizontal' | 'square' | 'vertical';

interface CompanyLogoMapping {
  companyName: string;  // Exact company name as it appears in the job listings
  logos: {
    [key in LogoVariant]?: string;  // Full filename including hash
  };
}

// Each entry maps the EXACT company name to its logo filenames
export const LOGO_MAPPINGS: CompanyLogoMapping[] = [
  {
    companyName: "3M",
    logos: {
      horizontal: "3M_horizontal-4nJphCkGhLhZQiHqyDaYzLOfhVenFD.png"
    }
  },
  {
    companyName: "6Sense",
    logos: {
      square: "6sense_square-MKR7Ubaxu06PpnJEzNa6umV8TW7Onb.png"
    }
  },
  {
    companyName: "A10 Networks",
    logos: {
      horizontal: "A10_Networks_horizontal-a99vQvSpObpPTOh3C8eiR6LavxFTs6.png"
    }
  },
  {
    companyName: "Abbott",
    logos: {
      square: "abbott_square-fjktEv7pAZ2q8Lte8YK0p8QOLmKHyy.png"
    }
  },
  {
    companyName: "Abnormal Security",
    logos: {
      square: "abnormal_security_square-N1EXNTDyOivk7jC5jfVfn37AsbPlxp.png"
    }
  },
  {
    companyName: "ACDS",
    logos: {
      horizontal: "acds_horizontal-pTC2tApm9u5QqJ0Ngk5O1zgueCkNpw.png"
    }
  },
  {
    companyName: "Accucode AI",
    logos: {
      square: "accucode_ai_square-eDNKFkQIGzsmzEVYYSMKulzKJbclDv.png"
    }
  },
  {
    companyName: "Activision",
    logos: {
      horizontal: "activision_horizontal-U6gnKMsT02MbUHonAdw2HV7BJ7h135.png"
    }
  },
  {
    companyName: "Adaptavist",
    logos: {
      square: "adaptavist_square-kD5iWkJvH9TMmIcxQ8NUpWFStUkqnA.png"
    }
  },
  {
    companyName: "Adobe",
    logos: {
      square: "adobe_square-V5CXLzVz8q0Jjbuvol5k8d6dWMSb9L.png"
    }
  },
  {
    companyName: "Adentro",
    logos: {
      square: "adentro_square-C5AR5QRWEtpPrU8uyxJmxmk3NIiWDa.png"
    }
  },
  {
    companyName: "Aero",
    logos: {
      horizontal: "aero_horizontal-3nbTqbVpD4fusouC1zS42Qwq5fec6i.png"
    }
  },
  {
    companyName: "Affinitiv",
    logos: {
      square: "affinitiv_square-C53dGwmA3KheJIdkJIZPnoXPfeNP5T.png"
    }
  },
  {
    companyName: "Affirm",
    logos: {
      square: "affirm_square-D6jzNAnJXHLmqPUftWCLbED0kBXjXn.png"
    }
  },
  {
    companyName: "AI Fund",
    logos: {
      square: "aifund_square-p36bXDhXRG5LVpca3YI0GFMGAPXbO6.png"
    }
  },
  {
    companyName: "Aigen",
    logos: {
      square: "aigen_square-yEFt1DrIhOU6qNdMmVVlallDvrmIrr.png"
    }
  },
  {
    companyName: "Airbus",
    logos: {
      horizontal: "airbus_horizontal-YOQcXKAZM2xPoWrHkz2nfYADS8Tyg5.png"
    }
  },
  {
    companyName: "Aircall",
    logos: {
      square: "aircall_square-XkULXaI7SUqj3FThtIeUXwX9BeIiKq.png"
    }
  },
  {
    companyName: "Aisera",
    logos: {
      square: "aisera_square-sCKVmnLtMrK4aWFPBqEkqbrhG1Xvxp.png"
    }
  },
  {
    companyName: "Albedo",
    logos: {
      square: "albedo_square-O6RSaTE9crmTs0hvzf5M6j2wupOp0B.png"
    }
  },
  {
    companyName: "Alchemy",
    logos: {
      square: "alchemy_square-pf1i53nSry6A1LRadoGEH9CRURj54X.png"
    }
  },
  {
    companyName: "Algolia",
    logos: {
      square: "algolia_square-fOczIqchgAgA81MkT3fH0U2DyllUTC.png"
    }
  },
  {
    companyName: "Allegiant Air",
    logos: {
      horizontal: "allegiantair_horizontal-PtFXA3DgaOhKhk6bEfQmvkuzyQUyNT.png"
    }
  },
  {
    companyName: "AllTrails",
    logos: {
      square: "alltrails_square-URMGywtqVRPYeqSFBI45Hd1zzLMWlf.png"
    }
  },
  {
    companyName: "Alpinestars",
    logos: {
      horizontal: "alpinestars_horizontal-fIRBC7x2Osh2PTGWUqZ5kv0W10BJpY.png"
    }
  },
  {
    companyName: "Amazon",
    logos: {
      square: "amazon_square-QD5W5XyNxp81QAF3VFutwD0iw7DZTK.png"
    }
  },
  {
    companyName: "Amplitude",
    logos: {
      square: "amplitude_square-EtDcrkZt7RJtT9Pw5PbY3WlUosIgbx.png"
    }
  },
  {
    companyName: "Analytic Partners",
    logos: {
      square: "analyticpartners_square-HiL0zYKVuKjO2WHjYvCE0Yam3K5gEd.png"
    }
  },
  {
    companyName: "Angi",
    logos: {
      horizontal: "angi_horizontal-KHOm9KMazC1oQWLKbM6Jms8YqF4Zch.png"
    }
  },
  {
    companyName: "Anthropic",
    logos: {
      square: "anthropic_square-uYptmtxcWiXGsDkSNkgFrbgGELmnuC.png"
    }
  },
  {
    companyName: "Anyscale",
    logos: {
      horizontal: "anyscale_horizontal-EqKncwIiIUVgaSMEp23RTmSnLsEBXD.png"
    }
  },
  {
    companyName: "Apollo",
    logos: {
      square: "apollo_square-yizB8ISNxjJG8Y2cNDPZl1InEzTEuZ.png"
    }
  },
  {
    companyName: "Apollo GraphQL",
    logos: {
      horizontal: "apollographql_horizontal-Z8oPwXCtqcUZ8cQ5vTsjDs7UTIMyMZ.png"
    }
  },
  {
    companyName: "Apple",
    logos: {
      square: "apple_square-uweqDAX1PK7rwpdXzpewmCqw5ZiA4i.png"
    }
  },
  {
    companyName: "AppLovin",
    logos: {
      square: "applovin_square-Adnk90p2PjtQyFPKaCqaiTZEIAi6lP.png"
    }
  },
  {
    companyName: "Arena AI",
    logos: {
      horizontal: "arenaai_horizontal-aHeaWLIqrJo74StwXHZ66FnshVzcna.png"
    }
  },
  {
    companyName: "Asana",
    logos: {
      horizontal: "asana_horizontal-vXx2zJ6DbZVCnZnvCJudb52SU8JN6A.png"
    }
  },
  {
    companyName: "Astranis",
    logos: {
      square: "astranis_square-gTi77hfktfxKnHnGCdfbAW7rYL7Mvg.png"
    }
  },
  {
    companyName: "Atomic",
    logos: {
      square: "atomic_square-TSSvZaAiFsvzdtVjq67cVxYCq2queP.png"
    }
  },
  {
    companyName: "Attabotics",
    logos: {
      horizontal: "attabotics_horizontal-plVnsKaKCrY9SFyPBUihtsjkfTeirg.png"
    }
  },
  {
    companyName: "Attentive",
    logos: {
      horizontal: "attentive_horizontal-moRoYWME6OcbvNk0Df8qnoP5dcgAbt.png"
    }
  },
  {
    companyName: "Autodesk",
    logos: {
      square: "autodesk_square-iprtnEOpSEY0Iie7oUj8hkcmWedJq6.png"
    }
  },
  {
    companyName: "Automation Anywhere",
    logos: {
      square: "automationAnywhere_square-gEqWhHPMuzTLTOGcS2vvW0ENCto7Sq.png"
    }
  },
  {
    companyName: "Avant",
    logos: {
      square: "avant_square-7oL80H2qsGGERvVlCegNhTQ2vwD5TR.png"
    }
  },
  {
    companyName: "Axiom Zen",
    logos: {
      square: "axiomzen_square-fSYiMVkb9FyQ8RByKUXicEoxXrLb6I.png"
    }
  },
  {
    companyName: "Bellese",
    logos: {
      horizontal: "bellese_horizontal-fckIpcTNlfrhRgjXPYIWtEsF1Ru9H7.png"
    }
  },
  {
    companyName: "Belvedere Trading",
    logos: {
      horizontal: "belvederetrading_horizontal-ifBA6rLAftthfbn0BmwxyeHoX9XHo5.png"
    }
  },
  {
    companyName: "Benchling",
    logos: {
      square: "benchling_square-AOmP1VQ8CXupHfPkX0zbr6vm0HIHHm.png"
    }
  },
  {
    companyName: "Beta",
    logos: {
      horizontal: "beta_horizontal-NJxQDK8ULD2xZu9lxvheR09wbboCjt.png"
    }
  },
  {
    companyName: "BillionToOne",
    logos: {
      horizontal: "billiontoone_horizontal-Db49AoGrZQdV6F05CixesjWXCIBULd.png"
    }
  },
  {
    companyName: "Binance",
    logos: {
      square: "binance_square-wMtFMUO3weUYpDFo4X1qanSDeHZdf9.png"
    }
  },
  {
    companyName: "BitGo",
    logos: {
      square: "bitgo_square-MjhUMBFDMMLZUXE2Pl1qlf2ba0dqQ1.png"
    }
  },
  {
    companyName: "Blue Origin",
    logos: {
      square: "blueOrigin_square-BwOgE2YJWyXwfWSpg0AbHqSbZZpbjG.png"
    }
  },
  {
    companyName: "Bluepoint Games",
    logos: {
      horizontal: "bluepoint_games_horizontal-m4BAWSrxM83atkXNDZIMrArXyNILUu.png"
    }
  },
  {
    companyName: "BNY Mellon",
    logos: {
      horizontal: "bny_horizontal-h0qKMozg9Yq8ZDAHpXox3AL0tkSeCb.png"
    }
  },
  {
    companyName: "Box",
    logos: {
      horizontal: "box_horizontal-Bte5xJozEdrsW3Q5A8btkcyO71NtFC.png"
    }
  },
  {
    companyName: "Brightwheel",
    logos: {
      square: "brightwheel_square-hoMEygnCFv3iQvGchTRDU9FcVPNJ3R.png"
    }
  },
  {
    companyName: "Brilliant",
    logos: {
      horizontal: "brilliant_horizontal-ppFI1eZrFu8p18y9TZ1KUhkrzCqA4o.png"
    }
  },
  {
    companyName: "Brillio",
    logos: {
      horizontal: "brillio2_horizontal-VBlvLRygI0R4o2cwYsZi0P5kusojyh.png"
    }
  },
  {
    companyName: "Butter Payments",
    logos: {
      horizontal: "butterpayments_horizontal-vELYsjh47bx6hXuGRb4LZrb5IJw8u5.png"
    }
  },
  {
    companyName: "C3.ai",
    logos: {
      horizontal: "c3.ai_horizontal-dM811XwN1G27FzwLHIeWENUPTFZHAM.png"
    }
  },
  {
    companyName: "Calm",
    logos: {
      square: "calm_square-5l6LWZZ9KgFNKCHl2onE8tyMOKcjhF.png"
    }
  },
  {
    companyName: "Captivate IQ",
    logos: {
      horizontal: "captivateig_horizontal-Xqj4RTSqWxlAVWQ38ldfXtaiBch8f9.png"
    }
  },
  {
    companyName: "Carbon",
    logos: {
      horizontal: "carbon_horizontal-1MDeubpAJVzb4h1AsuTCDUuFeZUvBJ.png"
    }
  },
  {
    companyName: "Catalist",
    logos: {
      square: "catalist_square-C8ghdmx3qRing5n9CfeIqzYtyriVDo.png"
    }
  },
  {
    companyName: "ChargePoint",
    logos: {
      square: "chargepoint_square-srL2MjY3uIJU9drZo9t8ZBjRsGpn0N.png"
    }
  },
  {
    companyName: "Checkr",
    logos: {
      square: "checkr_square-q8jx9hSniiCa6aJjgy26KTKz7IZ1lk.png"
    }
  },
  {
    companyName: "Cherry Technologies",
    logos: {
      square: "cherrytechnologies_square-gfJIuRBqDldYqcS7MXgJ6RSNpUFI32.png"
    }
  },
  {
    companyName: "CLEAR",
    logos: {
      square: "clear_square-Qho5ied71BZI6O7CRcx8KeepimP5g2.png"
    }
  },
  {
    companyName: "Clinc",
    logos: {
      horizontal: "clinc_horizontal-vxRHerDjvOMmbpjeOkvl8lBlYG0QR3.png"
    }
  },
  {
    companyName: "Cloudflare",
    logos: {
      square: "cloudflare_square-PfTiPN28XRjgLtTkURcpgoZnaZy7ES.png"
    }
  },
  {
    companyName: "Coforma",
    logos: {
      horizontal: "coforma_horizontal-SPYn22EZbo4AjfzEOziQ2Uu776tCbX.png"
    }
  },
  {
    companyName: "Cognite",
    logos: {
      square: "cognite_square-n9BkHCaotey7wWw8qivbV4FtuRWpYS.png"
    }
  },
  {
    companyName: "Cohere",
    logos: {
      square: "cohere_square-SUP7vmbCD9FCpEqHSIgGGJU0TMnrNT.png"
    }
  },
  {
    companyName: "Coins",
    logos: {
      square: "coins_square-xzRKzFGcTzOalOWq0W0UPQbkE7JPHf.png"
    }
  },
  {
    companyName: "Contentsquare",
    logos: {
      horizontal: "contentsquare_horizontal-RxQ1Haplt2T7nfgLEGz6Z7OkSB4DLX.png"
    }
  },
  {
    companyName: "Cover Genius",
    logos: {
      square: "covergenius_square-TKoChHFGKS8f6HBACd0B3JPuwI9e6g.png"
    }
  },
  {
    companyName: "Credit Karma",
    logos: {
      square: "creditkarma_square-v76mHSqltfR7Ow1zfQgy8SnCNkJw6Q.png"
    }
  },
  {
    companyName: "Credo",
    logos: {
      vertical: "credo_vertical-sToDklA9s2yLdJv0xgFnMfxFLx1fIc.png"
    }
  },
  {
    companyName: "Crest Operations",
    logos: {
      horizontal: "crestoperations_horizontal-ptcAsAafThQ2GEb534NlOdNA11WDSc.png"
    }
  },
  {
    companyName: "Cruise",
    logos: {
      horizontal: "cruise_horizontal-CxqMmdBYSMKIudcdWF8PAr24xnukkL.png"
    }
  },
  {
    companyName: "Culture Amp",
    logos: {
      square: "culture amp_square-JrEEvS5NP3uhZ2ZzsMBEEydtYArV84.png"
    }
  },
  {
    companyName: "Cure.ai",
    logos: {
      square: "cureai_square-Q5T2avJRE12EQdK1AkVEwz5Vxki02O.png"
    }
  },
  {
    companyName: "CYE",
    logos: {
      horizontal: "cye_horizontal-TqZW1NPaSndOSQaPXPYUW0NtyibuQf.png"
    }
  },
  {
    companyName: "Daily Harvest",
    logos: {
      square: "daily harvest_square-fpZHDzMD05IvyM5m0D29bRouDDl41S.png"
    }
  },
  {
    companyName: "Databricks",
    logos: {
      horizontal: "databricks_horizontal-PO4u94c2moGrt8IWjp5USKRqKj3L0I.png"
    }
  },
  {
    companyName: "Datadog",
    logos: {
      square: "datadog_square-VgWM3YlEFkSOERjpzUKcHPeb172haV.png"
    }
  },
  {
    companyName: "DAZN",
    logos: {
      square: "dazn_square-UhdJtTtCa2E4fBDNMNQfwmNGm3yIC7.png"
    }
  },
  {
    companyName: "Demandbase",
    logos: {
      square: "demandbase_square-zaDXTm5NJSnGYIzyCQFyLx3fiOH0kB.png"
    }
  },
  {
    companyName: "Dexterity",
    logos: {
      square: "dexterity_square-j3UWyE51Deq0h1LwYUsdMZvHGB7bXv.png"
    }
  },
  {
    companyName: "Discord",
    logos: {
      square: "discord_square-ajPApSOdChbqw8xbvi4fdz8wZmfDVY.png"
    }
  },
  {
    companyName: "DoorDash",
    logos: {
      horizontal: "doordash_horizontal-8ubR5BMgu0fMOWEPIIFf8h5nEFJrjB.png"
    }
  },
  {
    companyName: "Domo",
    logos: {
      square: "domo_square-Vyj4y2zxWf78aCh30tpfAG4JlNGHzJ.png"
    }
  },
  {
    companyName: "Doran Jones",
    logos: {
      horizontal: "doran_jones_horizontal-dLkGYg2JDNmmWpqc2ZvDjOeBO3FS3z.png"
    }
  },
  {
    companyName: "DraftKings",
    logos: {
      square: "draftkings_square-d4GaUMO5TlTXfsvSyEjr3vQbc40Q7f.png"
    }
  },
  {
    companyName: "D-Wave Systems",
    logos: {
      horizontal: "dwavesys_horizontal-oUWJSqCdb4Cfi9BYsUyZeTyEBxhKSh.png"
    }
  },
  {
    companyName: "Egen",
    logos: {
      horizontal: "egen_horizontal-ZDFzYX2ehiievWsVLyrn7jMTlCJmM5.png"
    }
  },
  {
    companyName: "Elevate Labs",
    logos: {
      square: "elevatelabs_square-tvdExG6j4tD5y2szB39nWIOjhjYtP0.png"
    }
  },
  {
    companyName: "Employwell",
    logos: {
      square: "employwell_square-zffxGOGykPgRRFabgk00D9JKVTiXtg.png"
    }
  },
  {
    companyName: "Empowerly",
    logos: {
      horizontal: "empowerly_horizontal-lD9MRIGuPqzP79oLhHbEXPjtxSJUzQ.png"
    }
  },
  {
    companyName: "Enable",
    logos: {
      square: "enable_square-gyBB8seDVU3qibSkZ6Q26lPpT0pvT6.png"
    }
  },
  {
    companyName: "Entify",
    logos: {
      square: "entify_square-v53UY52DiOXQDRPTQ9bb5vI2V0ILFz.png"
    }
  },
  {
    companyName: "ERG",
    logos: {
      horizontal: "erg_horizontal-XnHajAcqR6tkrooPxT1eHv5Rxrfvbb.png"
    }
  },
  {
    companyName: "Eventbrite",
    logos: {
      square: "eventbrite_square-Hp5S8ewfQ8mx0nogcwBoX7ImAf8pKm.png"
    }
  },
  {
    companyName: "Everbridge",
    logos: {
      horizontal: "everbridge_horizontal-qGtMhwcLtOtSAUotXP9JAAteHExdmh.png"
    }
  },
  {
    companyName: "Everlane",
    logos: {
      square: "everlane_square-KBuP8wAFdTbCBydQimo1MTIpgLz7TI.png"
    }
  },
  {
    companyName: "Exa Labs",
    logos: {
      square: "exalabs_square-YAaeLc0TXJLa9XtQcWCnn6DqQgJreY.png"
    }
  },
  {
    companyName: "Faire",
    logos: {
      square: "faire_square-pX35nzl8nlmVwsbvnBnKv7KtK2uVqd.png"
    }
  },
  {
    companyName: "FalconX",
    logos: {
      square: "falconx_square-HNFJ5mSK8yhmPoWlWahQ8tstpi7XYG.png"
    }
  },
  {
    companyName: "FamPay",
    logos: {
      square: "fampay_square-8iUaqR4K46K6NbNlGRSbmiFGbLqZdv.png"
    }
  },
  {
    companyName: "Firmex",
    logos: {
      square: "firmex_square-Qyx3d55xwljPP0PL4UzoGogKvwmaPR.png"
    }
  },
  {
    companyName: "Five9",
    logos: {
      square: "five9_square-6XAEf6DiATbShia34ra7BM8r1R4Uuz.png"
    }
  },
  {
    companyName: "Flexport",
    logos: {
      square: "flexport_square-JU7Hen6nSuWyjnLZnwGmBvi6b4D26b.png"
    }
  },
  {
    companyName: "Fliff",
    logos: {
      square: "fliff_square-Y0QE5rTbM2kAMyebyKfE6iOxyrpLT7.png"
    }
  },
  {
    companyName: "Flow",
    logos: {
      horizontal: "flow_horizontal-YqwSWlGV4sa8AcU0rhrxZjI8qcP1OL.png"
    }
  },
  {
    companyName: "FloQast",
    logos: {
      square: "floqast_square-esOG9XfqvEpF5QrEb7Wvs5yVpf0h7E.png"
    }
  },
  {
    companyName: "Flow Life",
    logos: {
      horizontal: "flowlife_horizontal-iLcwvqqJsus9hIYSwxaUlafs6JgmWI.png"
    }
  },
  {
    companyName: "Fluid Logic",
    logos: {
      square: "fluidlogic_square-o5f9QszWR6kYa4VtBbwf7cJupLFJh6.png"
    }
  },
  {
    companyName: "Galileo",
    logos: {
      square: "galileo_square-dQdABuAJXGhz6VabVOhJhykHOfQ2wD.png"
    }
  },
  {
    companyName: "Gauntlet",
    logos: {
      square: "gauntlet_square-vONjm9YLKAsnhfjjQlHqod7x1z8wDo.png"
    }
  },
  {
    companyName: "GetMysa",
    logos: {
      horizontal: "getmysa_horizontal-vaVq1RgDQdDaM76X2A7UQwUASOEEgr.png"
    }
  },
  {
    companyName: "Glean",
    logos: {
      square: "glean_square-RNzDCs6mshKfCu8zYOivGVCPGsrQDB.png"
    }
  },
  {
    companyName: "Glossier",
    logos: {
      square: "glossier_square-plwj12FtFKr2yWK7mHYW0aL2kklT23.png"
    }
  },
  {
    companyName: "Go1",
    logos: {
      horizontal: "go1_horizontal-dZkEBB91Zb8eQZOWYbjFU731CRyIPQ.png"
    }
  },
  {
    companyName: "Go Forward",
    logos: {
      horizontal: "goforward_horizontal-iOcQG38hqudpOMIgNnvsZY0Bw0ZSxr.png"
    }
  },
  {
    companyName: "Good American",
    logos: {
      horizontal: "goodamerican_horizontal-DzZ22jIc1myiIaaNjPLCuMGrW0vNwR.png"
    }
  },
  {
    companyName: "Google",
    logos: {
      square: "google_square-uRIODSRqMK7dIXH3TdlgNGHYexQ5Wu.png"
    }
  },
  {
    companyName: "Grammarly",
    logos: {
      square: "grammarly_square-gQfmJ2L6nhv6h7hXvdOV9HTguuox7k.png"
    }
  },
  {
    companyName: "Granicus",
    logos: {
      square: "granicus_square-N8Xq3ifmlCn5wzWw0IJF9VGOSFQYQN.png"
    }
  },
  {
    companyName: "Gray Matter",
    logos: {
      square: "graymatter_square-JhLLOxeuj26XMnxvZj2tSp8eJQIEbv.png"
    }
  },
  {
    companyName: "Gridmatic",
    logos: {
      horizontal: "gridmatic_horizontal-CoQ9nubNWRfCIpo5DJrzCIZf9VgC4x.png"
    }
  },
  {
    companyName: "Grow Therapy",
    logos: {
      square: "growtherapy_square-O3QOJY8l3EAA2ETUDdwsbstXtHqnPk.png"
    }
  },
  {
    companyName: "Halter",
    logos: {
      square: "halter_square-mVVYkSgWTt1Pid9vgABmJbssUgwZ2D.png"
    }
  },
  {
    companyName: "Handshake",
    logos: {
      square: "handshake_square-ExtsUaZDW0NbDJbAW8XQqWiVEtTOhL.png"
    }
  },
  {
    companyName: "Haus",
    logos: {
      horizontal: "haus_horizontal-AJkKFUtJrIr0zIlesAWLmpGmJJgt0b.png"
    }
  },
  {
    companyName: "HCVT",
    logos: {
      square: "hcvt_square-tSehAUOkbtX3YFB5jPqfbldy5L1Zkv.png"
    }
  },
  {
    companyName: "Hello Neighbor",
    logos: {
      square: "helloneighbor_square-9x3xci9i5g8X5KX48ktVfydLg8JlsG.png"
    }
  },
  {
    companyName: "Hermeus",
    logos: {
      square: "hermeus_square-pk2NM7BwkI0pKZnRq0YRyfObTZaX7b.png"
    }
  },
  {
    companyName: "Highland Machine Works",
    logos: {
      horizontal: "highland_machine_works_horizontal-XzuteQzUircr1nIS2V8i15lpASaawr.png"
    }
  },
  {
    companyName: "Hims & Hers",
    logos: {
      square: "hims&hers_square-5jhhVfZK0y5QdC93ORBsQQT0PIBirW.png"
    }
  },
  {
    companyName: "Hinge",
    logos: {
      square: "hinge_square-3vaIMEsuuNMLi7XptCkrogn15d4YBM.png"
    }
  },
  {
    companyName: "Hive",
    logos: {
      horizontal: "hive_horizontal-L1kG9lylzHcAbBjv8QOPTUlo8CfNcn.png"
    }
  },
  {
    companyName: "Hopper",
    logos: {
      square: "hopper_square-CY6xBzce3LWKlNWc4qaVVmqQsIKlxX.png"
    }
  },
  {
    companyName: "Hotstar",
    logos: {
      horizontal: "hotstar_horizontal-g6rEijldDHznP1eLaaeRO3wqnGP7Kr.png"
    }
  },
  {
    companyName: "Houzz",
    logos: {
      horizontal: "houzz_horizontal-7RbQmQzP783BKwiLG2pe51wX103iy6.png"
    }
  },
  {
    companyName: "Imbue",
    logos: {
      square: "imbue_square-4GvjyQKFmjeeV93vNYTcRf5Xne1T5E.png"
    }
  },
  {
    companyName: "Included Health",
    logos: {
      horizontal: "includedhealth_horizontal-zRxhlelxKySQFEfzQLhl21VHW2vegu.png"
    }
  },
  {
    companyName: "InPay",
    logos: {
      horizontal: "inpay2_horizontal-2AqHfrImSA5xULkgQTmgdGEkvmfAlh.png"
    }
  },
  {
    companyName: "Insomnia Cookies",
    logos: {
      square: "insomniacookies_square-Xx1siJJhGxLZqlP8jnCm4hqkHQHbX6.png"
    }
  },
  {
    companyName: "Intuitive Surgical",
    logos: {
      horizontal: "intuitive_horizontal-A1zNcv2Hc4obk0I3TxdCp0ynhxQe1f.png"
    }
  },
  {
    companyName: "Ion",
    logos: {
      horizontal: "ion_horizontal-VKYF5AIGavabeMvLEYBezZIVP5D1qq.png"
    }
  },
  {
    companyName: "Kiddom",
    logos: {
      square: "kiddom_square-8wZaEaI6mmvMa54LIDy7yrAgvW2j4V.png"
    }
  },
  {
    companyName: "Koddi",
    logos: {
      horizontal: "koddi_horizontal-31C5MCm5KSdoYcjG56FeLMiWV30rYq.png"
    }
  },
  {
    companyName: "Kodiak",
    logos: {
      square: "kodiak_square-6LoFiZyOtcYkkr5vlvAIscsVZRo6rQ.png"
    }
  },
  {
    companyName: "Kong",
    logos: {
      horizontal: "kong_horizontal-Or5J6eqCKufUEFY6GeXTqNVmtVRk70.png"
    }
  },
  {
    companyName: "KPMG",
    logos: {
      square: "kpmg_square-kgvpnCIv90rAqCQJZ8PpX8M3RjmvKV.png"
    }
  },
  {
    companyName: "Lab49",
    logos: {
      square: "lab49_square-YCW2s6t1GJDmGfKaKaT1bdzbAPlkBk.png"
    }
  },
  {
    companyName: "LCBC Church",
    logos: {
      horizontal: "lcbcchurch_horizontal-Q1wRVfNBGVShIasoaoThYMD8mrCqbI.png"
    }
  },
  {
    companyName: "Life",
    logos: {
      square: "life_square-0WcvbdNnubxt7eX4dU8AMHSG3b0kdO.png"
    }
  },
  {
    companyName: "Life Church",
    logos: {
      square: "lifechurch_square-ttfO5n7pCni4nXDT9E9HegZQBkE6xf.png"
    }
  },
  {
    companyName: "Lime",
    logos: {
      horizontal: "lime_horizontal-PquX2I9VKRDwKYROq0P2l6fPndrxlP.png"
    }
  },
  {
    companyName: "Loft Orbital",
    logos: {
      square: "loftorbital_square-xN1pAltAf4piMfppgR9XQhnel9QA0f.png"
    }
  },
  {
    companyName: "LogRocket",
    logos: {
      square: "logrocket_square-VrHIBf1IpZW56jIEew2F0ZcEUJAQBH.png"
    }
  },
  {
    companyName: "Lucid Motors",
    logos: {
      horizontal: "lucidmotors_horizontal-AYHLR4lV8BAGWILmje9uSVsBI651xy.png"
    }
  },
  {
    companyName: "Lucid Software",
    logos: {
      square: "lucidsoftware_square-zm6wLnrJbAHNYm5Xf3dlrdRW9K4cSn.png"
    }
  },
  {
    companyName: "Luma AI",
    logos: {
      square: "lumaai_square-8GYq9dVBRBw60nf3h8ugz4nxE5Kqpo.png"
    }
  },
  {
    companyName: "Luminar",
    logos: {
      square: "luminar_square-5Qa6xyxqJQ4m0t3uOOQCu8g6QFW9mh.png"
    }
  },
  {
    companyName: "Lyft",
    logos: {
      square: "lyft_square-TNTgtXneZ97XD33PBIf12a9k8iBI2Z.png"
    }
  },
  {
    companyName: "Mactores",
    logos: {
      square: "mactores_square-pYZSH7U6Cr3XApFsp9msA7dG4osGZ8.png"
    }
  },
  {
    companyName: "Magnify",
    logos: {
      square: "magnify_square-9C7h9V1qkZtxMLUqgoITWssRav5NYu.png"
    }
  },
  {
    companyName: "Malt",
    logos: {
      horizontal: "malt_horizontal-6n2Y3ZPeUPt58gDtoWKhEjgR0BH7IL.png"
    }
  },
  {
    companyName: "Matterport",
    logos: {
      square: "matterport_square-rLPKuRSI98slnV3BUuNgTX4gYY7WMc.png"
    }
  },
  {
    companyName: "Mediaocean",
    logos: {
      square: "mediaocean_square-mFokM5t0ZLN2gPJaXUowRJvUoHBzPs.png"
    }
  },
  {
    companyName: "Metabase",
    logos: {
      square: "metabase_square-Dd0DphDM4NydHe0XJQkAAL8a2fzpLA.png"
    }
  },
  {
    companyName: "Micron",
    logos: {
      horizontal: "micron_horizontal-To3yeyK8fRvTu4qaRSnNCAlN83VkCM.png"
    }
  },
  {
    companyName: "Milk Moovement",
    logos: {
      horizontal: "milkmoovement_horizontal-NvHmf8QsikUYfkmq27OgOBf02dmHrK.png"
    }
  },
  {
    companyName: "Millennium",
    logos: {
      square: "millenium_square-025pgrMjVglyCEdjaaoUoUMrWR92ty.png"
    }
  },
  {
    companyName: "Mirakl",
    logos: {
      horizontal: "mirakl_horizontal-4LvWvHcqP8KA8dbRJCjbYguS7qpBk0.png"
    }
  },
  {
    companyName: "Mistral",
    logos: {
      horizontal: "mistral_horizontal-z5TbWVZpeW1epzQbWFELpNcq8XWXJF.png"
    }
  },
  {
    companyName: "Merger Market",
    logos: {
      square: "mergermarket_square-EplrOBeeBfbu66fKktGcB5qbETHiHQ.png"
    }
  },
  {
    companyName: "ModSquad",
    logos: {
      horizontal: "modsquad_horizontal-sKIzHZ9ZA9p6KHOzw0N16xu4HxmjE7.png"
    }
  },
  {
    companyName: "Moloco",
    logos: {
      square: "moloco_square-upOgh1jRZ0ith9zasCHnxRNAQmGBSN.png"
    }
  },
  {
    companyName: "Moment",
    logos: {
      square: "momentinc_square-VllJPW91FeLlxKl8t8W48Th7Zrzf6S.png"
    }
  },
  {
    companyName: "MongoDB",
    logos: {
      vertical: "mongodb_vertical-3HQexsyq4g8Ym94bHtETNYPopRRfxz.png"
    }
  },
  {
    companyName: "Moxion Power",
    logos: {
      square: "moxionpower_square-hjyu5BeBjB3jE1Jk8ZJgtfp55SsWzp.png"
    }
  },
  {
    companyName: "Mozilla",
    logos: {
      square: "mozilla_square-BTqbYj0Hph8TJO5EZLWb5mT6r8baAA.png"
    }
  },
  {
    companyName: "Mux",
    logos: {
      horizontal: "mux_horizontal-2gi4XSoMheCxKRX709PecQxTMv0Y2Y.png"
    }
  },
  {
    companyName: "Nava",
    logos: {
      horizontal: "nava_horizontal-flrOTnOs5G0aYZWvQnWPpK03Mi7993.png"
    }
  },
  {
    companyName: "Navan",
    logos: {
      square: "navan_square-ghluWVBQaTU5iqC7ijCFVBrHVMlpWR.png"
    }
  },
  {
    companyName: "NCSolutions",
    logos: {
      square: "ncsolutions_square-XQlEEizS5mtbpru3RFi4ZxOoif50Hj.png"
    }
  },
  {
    companyName: "New Front Insurance",
    logos: {
      square: "newfrontinsurance_square-aaWt84YPcp6ScMqLjJpmSBVmGjVk0v.png"
    }
  },
  {
    companyName: "Nielsen",
    logos: {
      horizontal: "nielsen_horizontal-qvz0TIpr5dINo4qL3uuzypvAIABQJB.png"
    }
  },
  {
    companyName: "Nisum",
    logos: {
      square: "nisum_square-KL5wPacZznG2ZyJBvJpZ4hAuuZQRTV.png"
    }
  },
  {
    companyName: "Nordstrom",
    logos: {
      square: "nordstrom_square-mmY7g7W9NH3A7I9Fi397zXtRZR2yi8.png"
    }
  },
  {
    companyName: "Nuqleous",
    logos: {
      square: "nuqleous_square-vrt0ZdR88nWL9VSkr02nNVtSUaL95l.png"
    }
  },
  {
    companyName: "NVIDIA",
    logos: {
      square: "nvidia_square-dj3XlYKTVfadIJHqJqtFtiv9npleQY.png"
    }
  },
  {
    companyName: "Octopus Energy",
    logos: {
      horizontal: "octoenergy_horizontal-vvf1VCmGdSuXGD3iJCV2k663mHICPX.png"
    }
  },
  {
    companyName: "Oklo",
    logos: {
      square: "oklo_square-9XtaQWJ6ZlA2elikG68QhB6eQ2kfYv.png"
    }
  },
  {
    companyName: "Opendoor",
    logos: {
      square: "opendoor_square-OcpInMIojjmOdgqVwgICKbFNEFt7aG.png"
    }
  },
  {
    companyName: "OpenTable",
    logos: {
      square: "opentable_square-Fzi5Fw8FmCWCwG2yMIp7bObIzaXm6G.png"
    }
  },
  {
    companyName: "Orchard",
    logos: {
      square: "orchard_square-55mnB8fqdXuImYVA72NszFVMF8U1Cj.png"
    }
  },
  {
    companyName: "Osedea",
    logos: {
      horizontal: "osedea_horizontal-8jQqKQePS15Xfi01HfDXx4QAFsx9hz.png"
    }
  },
  {
    companyName: "Oshkosh",
    logos: {
      horizontal: "oshkosh_horizontal-W5hUbBB8YPF9d9jVwNtAkiO1eUL0Ar.png"
    }
  },
  {
    companyName: "Palantir",
    logos: {
      square: "palantir_square-lB1K5HVdMwvQu93X7zIQkZBV8VdSrv.png"
    }
  },
  {
    companyName: "Peach Finance",
    logos: {
      horizontal: "peachfinance_horizontal-wA3SymN3SKXFor8UZZEB60snrEekas.png"
    }
  },
  {
    companyName: "Pelmorex",
    logos: {
      horizontal: "pelmorex_horizontal-7iJSpGquUeLCjRoAiAsz9qStakSNWk.png"
    }
  },
  {
    companyName: "Penny Lane",
    logos: {
      square: "pennylane_square-baPI4ZN1n6vGAfVKmAUhkHl7LA9j6O.png"
    }
  },
  {
    companyName: "Penumbra",
    logos: {
      square: "penumbra_square-GjAnqNLcNiRAjGiVJRWcCoPSAJ0XcD.png"
    }
  },
  {
    companyName: "Persona",
    logos: {
      horizontal: "persona_horizontal-U4MZjT0EA4GOMvmyaTgd0aNPMNLoWk.png"
    }
  },
  {
    companyName: "PhysicsX",
    logos: {
      horizontal: "physicsx_horizontal-VvJgKaswGbUz8IJihMwOnJpelWdnCp.png"
    }
  },
  {
    companyName: "Pigment",
    logos: {
      square: "pigment_square-neTKqT8oPXgCEOzievXXQGDXborBVb.png"
    }
  },
  {
    companyName: "Pinterest",
    logos: {
      square: "pinterest_square-3E0LrdKYfrMIADIFyHdtcrWnK5LS5q.png"
    }
  },
  {
    companyName: "Pison",
    logos: {
      square: "pison_square-A6Cy0YcrAJ9vWG90Wkpu9fIlblQCW9.png"
    }
  },
  {
    companyName: "Plaid",
    logos: {
      horizontal: "plaid_horizontal-T7yVhe5HUuCyLSYGbMvQtR0UjXphTh.png"
    }
  },
  {
    companyName: "Planner5D",
    logos: {
      horizontal: "planner5d_horizontal-0mPMqtwdlr7iEBntoRnLeyIm9Lwj5Z.png"
    }
  },
  {
    companyName: "PlayStation",
    logos: {
      horizontal: "playstation_horizontal-5SJxGOJm4lGLHHrAx6VGpSoar8GwL9.png"
    }
  },
  {
    companyName: "PointClickCare",
    logos: {
      horizontal: "pointclickcare_horizontal-Arjp7UIRxkJHa0c0pUV9pgHaCicD5Z.png"
    }
  },
  {
    companyName: "Postman",
    logos: {
      square: "postman_square-dh7GKs9PQ56f2FNOfFoGE8uBvEi5Vw.png"
    }
  },
  {
    companyName: "Prologue Strategy",
    logos: {
      horizontal: "prologuestrategy_horizontal-CR0Jd2hG2U3JABmuXOFqhcySpW1YvN.png"
    }
  },
  {
    companyName: "Prologue",
    logos: {
      square: "prologue_square-XFyYGqJq0c867LcYbUlWNBXTaQPkC7.png"
    }
  },
  {
    companyName: "Qualcomm",
    logos: {
      square: "qualcomm_square-sn0wuYGFMy80LIXB5YmsZpPXgtLjZK.png"
    }
  },
  {
    companyName: "Qualtrics",
    logos: {
      horizontal: "qualtrics_horizontal-QtPUhSEaV9us0Hsbm1EvJZMSDDqoWm.png"
    }
  },
  {
    companyName: "Quince",
    logos: {
      horizontal: "quince_horizontal-YLqxo05GsnaReju59ImDygsiWnVALh.png"
    }
  },
  {
    companyName: "Quizlet",
    logos: {
      horizontal: "quizlet_horizontal-GZuLJ9zFHN9rRcUa4Xz5GVPkQ2easx.png"
    }
  },
  {
    companyName: "Rackspace Technology",
    logos: {
      square: "rackspace_square-Rbi2x8vJ35y2sHiPok2M6gOgtoETTs.png"
    }
  },
  {
    companyName: "Reddit",
    logos: {
      square: "reddit_square-CjHMUFTqlgmOSlIDFumJWskL57jWO9.png"
    }
  },
  {
    companyName: "Red Hat",
    logos: {
      square: "redhat_square-HkzfERqNk8tR8AbdHP3wktD6siaCgX.png"
    }
  },
  {
    companyName: "Relay",
    logos: {
      horizontal: "relay_horizontal-AaclvGdFBD3wNK6BwGxu5GByHGxWT4.png"
    }
  },
  {
    companyName: "Remedy",
    logos: {
      horizontal: "remedy_horizontal-Po6r7uSBVft024c5TCanM4V8TVjmPi.png"
    }
  },
  {
    companyName: "Richardson RFPD",
    logos: {
      horizontal: "richardson_rfpd_horizontal-xh5xZYf86ilxXSaVOfdBRWdXSAOAcd.png"
    }
  },
  {
    companyName: "Rigetti",
    logos: {
      horizontal: "rigetti_horizontal-Fbfw5WtZoO97xtBWobeJ2I3HtowpzW.png"
    }
  },
  {
    companyName: "RingCentral",
    logos: {
      square: "ringcentral_square-VBa73igxj7CDnu7GMvhgJAKSBLOqHV.png"
    }
  },
  {
    companyName: "Rinsed",
    logos: {
      horizontal: "rinsed_horizontal-hMC9Za6Pc4JQkKwYMrOrVL0hMPXFtg.png"
    }
  },
  {
    companyName: "Ro",
    logos: {
      horizontal: "ro_horizontal-V6MIATpQo0MpV6ZHWAsyNB2kwt0IV6.png"
    }
  },
  {
    companyName: "Roku",
    logos: {
      horizontal: "roku_horizontal-Nrnzd8pDuY3a6iwRQKKwHfJXZvm3dZ.png"
    }
  },
  {
    companyName: "Sagent",
    logos: {
      horizontal: "sagent_horizontal-sY8L7nUs3GiEC0JcElzy724dQapkZd.png"
    }
  },
  {
    companyName: "Salesforce",
    logos: {
      square: "salesforce_square-1wBU9rOhOdqeISE3j6a5qlilplTRtA.png"
    }
  },
  {
    companyName: "Samsara",
    logos: {
      square: "samsara_square-n4VW1Nt3h90nsVXUwiD4CBB3rwO3O8.png"
    }
  },
  {
    companyName: "Samsung Semiconductor",
    logos: {
      square: "samsungsemiconductor_square-Yx2FrtpejYTf5WvhKZYQ10n8Cd27PA.png"
    }
  },
  {
    companyName: "Sanofi",
    logos: {
      horizontal: "sanofi_horizontal-mNse0Lm5k9sWUKHgB66HiBxtxnJUjP.png"
    }
  },
  {
    companyName: "Sapphire Ventures",
    logos: {
      square: "sapphire_ventures_square-4uxgDiPOJzaefI9Rg330PzxSbjgDa5.png"
    }
  },
  {
    companyName: "Saronic",
    logos: {
      square: "saronic_square-0n9quGWc4cEh09peo1XUeFKAn3d8rf.png"
    }
  },
  {
    companyName: "Satair",
    logos: {
      square: "satair_horizontal-ColwKW33RuhUEEvUtTfd8QjoW8vwNI.png"
    }
  },
  {
    companyName: "Scale AI",
    logos: {
      horizontal: "scale_horizontal-jxn5nE2sPRwSLSP5iyDFe8O20WtleK.png"
    }
  },
  {
    companyName: "Scribd",
    logos: {
      square: "scribd_square-6rfgen1TLwAiQCMp30i6YHro1B2L8W.png"
    }
  },
  {
    companyName: "ShopMonkey",
    logos: {
      square: "shopmonkey_square-t2tkRH4UIhHSYae35YnASlgL38lxYB.png"
    }
  },
  {
    companyName: "Shyft Labs",
    logos: {
      square: "shyftlabs_square-CqKLUtFHA6y2u47PjBpT51BBvQg5q1.png"
    }
  },
  {
    companyName: "Signal",
    logos: {
      horizontal: "signal_horizontal-UyrHA0hVo6bDC37MdYGhPjAJnCREUR.png"
    }
  },
  {
    companyName: "SKIMS",
    logos: {
      horizontal: "skims_horizontal-3z0R2ZfWVmvoGb8thBZXDFOzEEWvF5.png"
    }
  },
  {
    companyName: "Slack",
    logos: {
      square: "slack_square-aayBh61duPcwGpdbYOzrXFb3o7s4EF.png"
    }
  },
  {
    companyName: "Smartsheet",
    logos: {
      square: "smartsheet_square-sOpljUPxcuVtVmGgn7vybfDQvKiumX.png"
    }
  },
  {
    companyName: "Snap",
    logos: {
      square: "snap_square-kW622antmc8tBkmUriJ4WrEgK1yrBV.png"
    }
  },
  {
    companyName: "SoFi",
    logos: {
      square: "sofi_square-NBeGM4yhMqm6RIwWNJfd7tVyD5zBJh.png"
    }
  },
  {
    companyName: "Sonatus",
    logos: {
      square: "sonatus_square-bi3Q4Tk0UlgkVAJpXUdpQeHMoVJYhy.png"
    }
  },
  {
    companyName: "Sony",
    logos: {
      horizontal: "sony_horizontal-aGv1ebXxjL4fskVzNArYVjjFEAPjX9.png"
    }
  },
  {
    companyName: "SoundHound",
    logos: {
      square: "soundhound_square-Wb5JXply9BayNTBQytXsaTZF3N7Dau.png"
    }
  },
  {
    companyName: "SpaceX",
    logos: {
      horizontal: "spacex_horizontal-iMgZBBzrp4yKDDIMgsyRwXC4xQ0BZ1.png"
    }
  },
  {
    companyName: "Sporty",
    logos: {
      square: "sporty_square-xqbbHsX9IKbKusPRJZ9q2lu9iyZ3Xs.png"
    }
  },
  {
    companyName: "Spotify",
    logos: {
      square: "spotify_square-Upt44Z2Ml7KY890hp1GmT22fP7rLd7.png"
    }
  },
  {
    companyName: "SpotOn",
    logos: {
      horizontal: "spoton_horizontal-VSZJmaXpZ2LlvE8d7CRthaPLvMdw9J.png"
    }
  },
  {
    companyName: "Squire",
    logos: {
      horizontal: "squire_horizontal-cSmkw05BpGZwcBNtfrLURMqgFwYiYp.png"
    }
  },
  {
    companyName: "StackAdapt",
    logos: {
      square: "stackadapt_square-ostQTh8zV0KYfz55IDBgaaxD32LBNE.png"
    }
  },
  {
    companyName: "Story Protocol",
    logos: {
      square: "storyprotocol_square-m4t2EKQeHjTCm47CaLfdLOY7ai3U2d.png"
    }
  },
  {
    companyName: "Stripe",
    logos: {
      horizontal: "stripe_horizontal-8BtGs7BLWJdy84GKLSl5BjI84Gzc5x.png"
    }
  },
  {
    companyName: "Stryker",
    logos: {
      horizontal: "stryker_horizontal-nWLLrK3SJ6BqxyFyxsnqk37xrCWKW5.png"
    }
  },
  {
    companyName: "StubHub",
    logos: {
      horizontal: "stubhub_horizontal-tLbW2LgT0kk5KTTiJcZuON0by6KdMC.png"
    }
  },
  {
    companyName: "Substack",
    logos: {
      square: "substack_square-74VHN5xM0n2MwairWZxpRXK8sl78LB.png"
    }
  },
  {
    companyName: "Sure",
    logos: {
      horizontal: "sure_horizontal-qhDuhCHHevdg7kWeLtxymRtL4Da5wa.png"
    }
  },
  {
    companyName: "Sword Health",
    logos: {
      horizontal: "swrod_health_horizontal-jRQfsxCJTDMv35qyxIm6mMpQfvQQqI.png"
    }
  },
  {
    companyName: "Tableau",
    logos: {
      square: "tableau_square-uBSYZMV9zQTBFzPTvfFlIwXuUENMML.png"
    }
  },
  {
    companyName: "Tendo",
    logos: {
      square: "tendo_square-DBeNilk9zsFQDL6YHtPa1cMaiLgHEM.png"
    }
  },
  {
    companyName: "Tesla",
    logos: {
      square: "tesla_square-NisoY7jAZ9iRJ4uQr5ZfeorOr1JItz.png"
    }
  },
  {
    companyName: "Toyota",
    logos: {
      square: "toyota_square-ffmMYFxL50nbkSU9efk7Mt0io0bFAo.png"
    }
  },
  {
    companyName: "Twilio",
    logos: {
      square: "twilio_square-dXeg7Yg9EBGY12tc38gyCMLXlHcDir.png"
    }
  },
  {
    companyName: "The Rounds",
    logos: {
      horizontal: "therounds_horizontal-adtUuGxAOQNMM9Ugx70k44iqKA21Yf.png"
    }
  },
  {
    companyName: "The Trevor Project",
    logos: {
      square: "thetrevorproject_square-ZUrZA5qeqzmPzdODngsjfq8ZP01bPn.png"
    }
  },
  {
    companyName: "Thrive Causemetics",
    logos: {
      horizontal: "thrivecausemetics_horizontal-unDV6MvGFjsYjlmhEk8FK4QLf03GUH.png"
    }
  },
  {
    companyName: "TripActions",
    logos: {
      horizontal: "tripactions_horizontal-AGzCbke6z2Cj4pQbCACnmG41n4eSXd.png"
    }
  },
  {
    companyName: "TrueML",
    logos: {
      square: "trueml_square-OIAcD0hPpAJwJaxOkF8rSgTvCxFkR9.png"
    }
  },
  {
    companyName: "Trustly",
    logos: {
      horizontal: "trustly_horizontal-5sh3sx41kjJTg05IERj6we3meLUa6W.png"
    }
  },
  {
    companyName: "Tubi",
    logos: {
      horizontal: "tubi_horizontal-ubOp6EDru76pNJzgIl3deMTM4ad716.png"
    }
  },
  {
    companyName: "US Mobile",
    logos: {
      horizontal: "usmobile_horizontal-lZgBnt35tZtmOmLL7EdWL9iufuMIvO.png"
    }
  },
  {
    companyName: "Vail Systems",
    logos: {
      horizontal: "vailsys_horizontal-CBuztfaFIE5qDa7D1El2cyqN3EzImx.png"
    }
  },
  {
    companyName: "Verkada",
    logos: {
      square: "verkada_square-QXDNosGz7BwnhyESIyYwOw71g6CQvO.png"
    }
  },
  {
    companyName: "Vida",
    logos: {
      horizontal: "vida_horizontal-xVMZfeObEcM3WZa036RsF0fHKp0Omv.png"
    }
  },
  {
    companyName: "Vimeo",
    logos: {
      square: "vimeo_square-W2Aj53stsSzdq0RFR35zrHjC5h9Pn4.png"
    }
  },
  {
    companyName: "Voleon",
    logos: {
      square: "voleon_square-kUfnF8I5tM38BlMqoFzjLbUSGSUPNE.png"
    }
  },
  {
    companyName: "Waabi",
    logos: {
      horizontal: "waabi_horizontal-b8fIJuJZzRRkpaOYViU1As73a8q7xU.png"
    }
  },
  {
    companyName: "Warby Parker",
    logos: {
      square: "warby_parker_square-bkJwbIUwfX0KnNH362cqgwJH3hsYaL.png" 
    }
  },
  {
    companyName: "Wahed",
    logos: {
      horizontal: "wahed_horizontal-uEeWBnTHY3K76WaLZ07AZqj4exO4L0.png"
    }
  },
  {
    companyName: "Walmart",
    logos: {
      square: "walmart_square-N2eBROBTeUTHrU9WhA1Uqvc98UPDGw.png"
    }
  },
  {
    companyName: "Waymo",
    logos: {
      square: "waymo_square-On9FriYFQ4NOZQxpuRHShOtIR5HVOA.png"
    }
  },
  {
    companyName: "Wealthfront",
    logos: {
      horizontal: "wealthfront_horizontal-Q4jWeEqpO6UVK4qJwG6nwRVmbfLaME.png"
    }
  },
  {
    companyName: "Wealthsimple",
    logos: {
      square: "wealthsimple_square-cs4pSWgEpGuE0kmfRO45WqJHeU67V3.png"
    }
  },
  {
    companyName: "Webflow",
    logos: {
      square: "webflow_square-B3rKh0ypVZJhAPXVANK2p1dYKyh452.png"
    }
  },
  {
    companyName: "Weee!",
    logos: {
      horizontal: "weee!_horizontal-dprJpuuzO5ChXdTfHn3BMgC0w9OE3Q.png"
    }
  },
  {
    companyName: "Welocalize",
    logos: {
      square: "welocalize_square-IAyxBJTmH92aeRNHCAAJIihQHceNRO.png"
    }
  },
  {
    companyName: "WHOOP",
    logos: {
      horizontal: "whoop_horizontal-wSdajCq18cxOl62scgWpNFRAqhgo4Q.png"
    }
  },
  {
    companyName: "Windfall Data",
    logos: {
      horizontal: "windfalldata_horizontal-gzx6278ILzLvNbo9vmqxswFt7U5bIL.png"
    }
  },
  {
    companyName: "Workday",
    logos: {
      horizontal: "workday_horizontal-zs4ZDJi4380ECXTsJtc80WXx9tPFRR.png"
    }
  },
  {
    companyName: "Xsolla",
    logos: {
      square: "xsolla_square-TJfAKhf2WqUB0jrgAyCHbzapKXUvFC.png"
    }
  },
  {
    companyName: "Yahoo",
    logos: {
      horizontal: "yahoo_horizontal-bB6524YhZM8e4b9cNqh1XnUUnwTEf3.png"
    }
  },
  {
    companyName: "Zebra",
    logos: {
      square: "zebra_square-9E8PQxtOarcTV3myn2NcxfUxdGDX5e.png"
    }
  },
  {
    companyName: "Zendesk",
    logos: {
      square: "zendesk_square-T8nvNFE4wBtxntnLJ33FFUtdbRQiXc.png"
    }
  },
  {
    companyName: "Zen Educate",
    logos: {
      horizontal: "zeneducate_horizontal-VWCvhpUtp026jd7jD9ZZCJONVs9tyo.png"
    }
  },
  {
    companyName: "Zoox",
    logos: {
      horizontal: "zoox_horizontal-OOzm8Rs0BL8AY6klAAe3JCB1COjmbI.png"
    }
  },
  {
    companyName: "American Express GBT",
    logos: {
      square: "amex_gbt_square-7JgifltglyBl7ReEfhSt8XU5fJ5vz2.png"
    }
  },
  {
    companyName: "American Express",
    logos: {
      square: "amex_gbt_square-7JgifltglyBl7ReEfhSt8XU5fJ5vz2.png"
    }
  },
  {
    companyName: "Anaplan",
    logos: {
      square: "anaplan_square-fTaAP2wtyO5hY2vng7ag5BXLQJSHv3.png"
    }
  },
  {
    companyName: "Applied Materials",
    logos: {
      square: "applied_materials_square-6b3NHEOv3jhrJpacc1KRTwPSXOQQ1Z.png"
    }
  },
  {
    companyName: "Apprenticely",
    logos: {
      horizontal: "apprenticely_horizontal-tY88OkLoQbcAYJ9GeNQU70S8Ui42Jj.png"
    }
  },
  {
    companyName: "Aptos Labs",
    logos: {
      square: "aptos_square-fRNPrXR4c4uW957DZB9Ukr50HwoKK1.png"
    }
  },
  {
    companyName: "Arc",
    logos: {
      square: "arc_square-hszSHS7nLuho1FRgUFhkdvt5dOXZvT.png"
    }
  },
  {
    companyName: "Arrow",
    logos: {
      horizontal: "arrow_horizontal-Ikxi3NFCpWEtCrwOhuq1aiRueWpC0R.png"
    }
  },
  {
    companyName: "ASM Global",
    logos: {
      horizontal: "asm_global_horizontal-iwHwR2urbkNtCeBZo0CbgacREr5k6W.png"
    }
  },
  {
    companyName: "Assurant",
    logos: {
      square: "assurant_square-FOWu6QCZ7B6QLY4OnNHoKtA3VrGk4j.png"
    }
  },
  {
    companyName: "Atwell",
    logos: {
      square: "atwell_square-KlIpQpE0ro79sHD2BdqjsxmtYzbtHD.png"
    }
  },
  {
    companyName: "Audicus",
    logos: {
      square: "audicus_square-WduOL9UcEHt19Zs0GaaV4sqeLXS3Ay.png"
    }
  },
  {
    companyName: "Bank of America",
    logos: {
      horizontal: "bank_of_america_horizontal-Ufi9azvwY7qKou6jwXi3kf4Y6Ptctn.png"
    }
  },
  {
    companyName: "BD",
    logos: {
      horizontal: "bd_horizontal-C7ZPAdbNU5I4meVKipMENUoOoS7toU.png"
    }
  },
  {
    companyName: "Beta Engineering",
    logos: {
      horizontal: "beta_engineering_horizontal-EvRWCEq0oy7sRYfb1ouKp483G08Yh4.png"
    }
  },
  {
    companyName: "Beta Technologies",
    logos: {
      horizontal: "beta_technologies_horizontal-XjTtn8hWmN7h3uYYrpCEmr9KYRgptb.png"
    }
  },
  {
    companyName: "Beyond",
    logos: {
      square: "beyond_square-YamapuDXB4SB9ktWvClDmM97mCZJtH.png"
    }
  },
  {
    companyName: "Billion to One",
    logos: {
      horizontal: "billion_to_one_horizontal-3GrfSweI1Mlei54PbJvWV87UU7vgAK.png"
    }
  },
  {
    companyName: "Bilt Rewards",
    logos: {
      square: "bilt_rewards_square-naCZ0F2weRqJciQgLgcyXMJftmjeNn.png"
    }
  },
  {
    companyName: "Bitcoin Depot",
    logos: {
      square: "bitcoin_depot_square-5g9LcYYxKNjAAED1fm49Z9ZhAKAjop.png"
    }
  },
  {
    companyName: "Bolt",
    logos: {
      horizontal: "bolt_horizontal-SQnQuXwLBN7OGaE1qkbxyALzZpwFEc.png"
    }
  },
  {
    companyName: "Bond Vet",
    logos: {
      square: "bond_vet_square-hYfia0985jVQWRVtJ8K3aikuGxrmss.png"
    }
  },
  {
    companyName: "Bracebridge Capital",
    logos: {
      horizontal: "bracebridge_capital_horizontal-RIIe00w34jjSMDPL2Gn9oRhcNoK9rE.png"
    }
  },
  {
    companyName: "Brain",
    logos: {
      square: "brain_square-dFqFD0MF3hThtZssbgoKqm91qn3Pjb.png"
    }
  },
  {
    companyName: "Brave Health",
    logos: {
      square: "brave_health_square-spiaBx7QZ9Cqh1S9MmfRZcPpGAyXwX.png"
    }
  },
  {
    companyName: "Breeze Airways",
    logos: {
      square: "breeze_airways_square-VjYULfdScYVFy0c5ifbSHCJUqChFgT.png"
    }
  },
  {
    companyName: "Buf",
    logos: {
      horizontal: "buf_horizontal-hHkksEW2fcM8kwue585zHCcCS664v1.png"
    }
  },
  {
    companyName: "CAE",
    logos: {
      horizontal: "cae_horizontal-cYkz9xdbdZUZnf5c4PLTPfypTobgyu.png"
    }
  },
  {
    companyName: "Capital One",
    logos: {
      horizontal: "capital_one_horizontal-QEmz1M3Z4wms5oOyvpwT4x3jGIcD5x.png"
    }
  },
  {
    companyName: "Card",
    logos: {
      square: "card_square-hQx5XavIoHKzNLzoA16Gs8ap57m037.png"
    }
  },
  {
    companyName: "Cigna",
    logos: {
      horizontal: "cigna_horizontal-exkF86kC5xbrSXo9ck47pfVZ7pVUIQ.png"
    }
  },
  {
    companyName: "Citi",
    logos: {
      horizontal: "citi_horizontal-hApk5m9lD2D5PWxtGxzmP1MH22lBc5.png"
    }
  },
  {
    companyName: "Clearwater Analytics",
    logos: {
      horizontal: "clearwater_analytics_horizontal-vCY0gGWWPQW1VxOhe82Y6BReEDxESG.png"
    }
  },
  {
    companyName: "Clerk Chat",
    logos: {
      square: "clerk_chat_square-rteMBosxaiwKf6AtRTtMorm9gNYC0p.png"
    }
  },
  {
    companyName: "Coast",
    logos: {
      horizontal: "coast_horizontal-RJEKhcteS1SsOCoyokaskHfzi7yO3u.png"
    }
  },
  {
    companyName: "Coin Metrics",
    logos: {
      square: "coinmetrics_square-JJg2dVsUK4hxcpAZZZp1dGCHV1thms.png"
    }
  },
  {
    companyName: "Comcast",
    logos: {
      horizontal: "comcast_horizontal-xNVzsS7CHfuL9Iv89oef1v7kabUCJk.png"
    }
  },
  {
    companyName: "Cortex",
    logos: {
      square: "cortex_square-oBMssztREpJjGOrouqAuw1NSOh3wk8.png"
    }
  },
  {
    companyName: "Critical Mass",
    logos: {
      horizontal: "critical_mass_horizontal-g9f7e4SCsMLYbzdL02VA01t3lfZtU1.png"
    }
  },
  {
    companyName: "CrowdStrike",
    logos: {
      square: "crowdstrike_square-3PLSZPg4kvGjfxdfGNV1UNEYDWRn1F.png"
    }
  },
  {
    companyName: "Curative",
    logos: {
      square: "curative_square-cj5ysIx900vQARugpd2s5gcFuG7BEQ.png"
    }
  },
  {
    companyName: "CVS Health",
    logos: {
      square: "cvs_health_square-2mBlEdwk3nMRuVDAGtetbYHgXoRxAf.png"
    }
  },
  {
    companyName: "Dash Financial Technologies",
    logos: {
      square: "dash_financial_technologies_square-a80lVozS2cZEhhx5JHOIBpDERMVZxG.png"
    }
  },
  {
    companyName: "Diligent Robotics",
    logos: {
      square: "deligent_robotics_square-4VL76JnRATDvIsYP0N1ivenqRwyJ7v.png"
    }
  },
  {
    companyName: "Delinea",
    logos: {
      square: "delinea_square-Gq1zIfg6hCMerYvRcgkmLEPJSB1E9H.png"
    }
  },
  {
    companyName: "Discord",
    logos: {
      square: "discord_square-ajPApSOdChbqw8xbvi4fdz8wZmfDVY.png"
    }
  }
];

export type { LogoVariant, CompanyLogoMapping }; 