import React, { useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import { JobData } from './JobDetailsModal';
import axiosClient from '../../axios-client';
import { AxiosError } from 'axios';

interface JobFormProps {
  onSubmit: (data: JobData) => void;
}

const JobForm: React.FC<JobFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    summary: '',
    job_url: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      console.log('Form submitted with data:', formData);
      
      const response = await axiosClient.post('/builder/process-description', {
        summary: formData.summary
      });
      console.log('Job processing response:', response.data);
      
      const processedJob = {
        ...response.data,
        job_url: formData.job_url || ''
      };
      
      onSubmit(processedJob);
    } catch (error) {
      if (error instanceof Error) {
        const axiosError = error as AxiosError;
        console.error('Error details:', axiosError.response?.data || axiosError.message);
        console.error('Full error object:', axiosError);
      } else {
        console.error('An unknown error occurred:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="job-form-container">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Job URL (Optional)</label>
          <input
            type="url"
            value={formData.job_url}
            onChange={handleInputChange}
            name="job_url"
            placeholder="Paste the job posting URL"
          />
        </div>
        <div className="form-group">
          <label>Job Description*</label>
          <textarea
            value={formData.summary}
            onChange={handleInputChange}
            name="summary"
            placeholder="Paste the complete job description here"
            rows={8}
            required
          />
        </div>
        <button 
          type="submit" 
          className="submit-btn"
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="loading-spinner" />
          ) : (
            <>
              <FiUpload className="icon" />
              Process Job
            </>
          )}
        </button>
      </form>
    </div>
  );
};

export default JobForm; 